@import "src/@paco/styles";

.departments-input {
    display: block;
    margin-bottom: 0;

    &--has-custom-label-value {
        .select-overwrite__multi-value + .select-overwrite__multi-value {
            display: none;
        }
    }

    &__option,
    &__custom-value-label {
        display: inline;
        align-items: center;
        font-weight: var(--fw-regular);
        font-size: remCalc(.875);
        z-index: 1;

        &--is-deleted,
        &--is-group {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: remCalc(.25);
                background-color: var(--c-green);
            }
        }

        &--is-deleted {
            &:before {
                background-color: var(--c-disabled-grey);
            }
        }

        &--has-full-group {
            display: none;
        }
    }

    &__option-label {
        display: inline;
        position: relative;
    }

    &__option-icon {
        margin-right: remCalc(.5);
        min-width: remCalc(1);
    }

    .select-overwrite__option--is-selected {
        color: var(--c-disabled-grey);
        background: var(--c-white);

        &:hover {
            background: var(--c-lighter-blue);
        }
    }
    .select-overwrite__menu &__option-icon,
    .select-overwrite__multi-value &__option-icon {
        display: none;
    }
}
