@import "src/@paco/styles";
@import "../../../../style/theme";

$component: "concepts-list-view-table";

:root {
    --list-view-offset-top: 520px;

    @include for-size(tablet-portrait-up) {
        --list-view-offset-top: 440px;
    }

    @include for-size(laptop-up) {
        --list-view-offset-top: 376px;
    }

    @include for-size(desktop-up) {
        --list-view-offset-top: 288px;
    }
}

.#{$component} {
    &__table-container {
        max-height: calc(100vh - var(--list-view-offset-top));
    }

    &__table {
        table-layout: fixed;
    }

    tbody {
        background-color: $concept-dark-blue;
    }

    .table tr {
        &:hover {
            background-color: $concept-dark-blue;
        }
    }

    .table-head-th {
        width: 100px;

        &:first-of-type {
            width: 190px;
        }
    }

    &--first-row-sticky {
        .#{$component}__table {
            th {
                position: sticky;
                top: 0;
                z-index: 3;

                // Border bottom
                &::before {
                    content: "";
                    position: absolute;
                    inset-inline: 0;
                    bottom: -1px;
                    height: 1px;
                    background-color: $border-grey;
                }
            }
        }
    }

    &--first-column-sticky {
        .#{$component}__table {
            th:first-child, td:first-child {
                position: sticky;
                left: 0;
                z-index: 3;
                background: $white;

                // Border right
                &::before {
                    content: "";
                    position: absolute;
                    inset-block: 0;
                    right: -1px;
                    width: 1px;
                    background-color: $border-grey;
                }

                // Border bottom
                &::after {
                    content: "";
                    position: absolute;
                    inset-inline: 0;
                    bottom: -1px;
                    height: 1px;
                    background-color: $border-grey;
                }
            }
        }
    }

    &--first-row-sticky,
    &--first-column-sticky {
        .#{$component}__table {
            th:first-child {
                z-index: 4;
                background: $table-bg;
            }
        }
    }
}
