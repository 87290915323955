%reset-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

%reset-list-item {
    list-style-image: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
}
