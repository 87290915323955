@import "src/@paco/styles";

.edit-shift-time-form {
    display: block;
    line-height: 1;
    height: remCalc(1.25);

    &__delete-button {
        display: inline-block;
        position: relative;
        margin: remCalc(.375) 0 0 remCalc(.25);
        padding: 0;
        color: inherit;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: remCalc(-.25);
            left: remCalc(-.25);
            width: remCalc(1.25);
            height: remCalc(1.25);
            opacity: 0;
        }

        &:hover,
        &:focus {
            opacity: .675;
            color: inherit;
        }
    }

    &__delete-button-icon {
        @include icon-size(.675);
    }

    &__input {
        width: fit-content;
        height: 100%;
        padding: remCalc(.125);
        color: inherit;
        background: none;

        &::-webkit-calendar-picker-indicator {
            display: none;
            background: none;
        }
    }
}
