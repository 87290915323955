@import "src/@paco/styles";

.export-shifts-form {
    @extend %form;

    &__row {
        @extend %form-row;
    }

    &__col {
        @extend %form-col;

        &-label {
            font-weight: var(--fw-regular);
        }
    }
}
