@import "src/@paco/styles";

$component: help-button;

.#{$component} {
    @extend %button--reset;

    border-radius: 60px;
    transition: transform .2s ease-out;
    scroll-margin-top: remCalc(7);

    @include for-size(phone-only) {
        scroll-margin-top: remCalc(4);
    }

    &:focus, &:hover, &--is-active {
        outline: 0;
        transform: translateX(remCalc(-3.5));
    }

    &:focus &__inner, &:hover &__inner, &--is-active &__inner {
        width: remCalc(10);
        max-width: remCalc(10);
        padding-right: remCalc(1);
        background: var(--c-primary);

        .#{$component}__text {
            opacity: 1;
        }
    }

    &__inner {
        @extend %button;

        display: flex;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 60px;
        width: auto;
        max-width: remCalc(2.5);
        height: remCalc(2.5);
        background: var(--c-concept-blue);
        overflow: hidden;

        transform: scale(0);
        animation: scaleIn .3s ease-out forwards;
        transition: max-width .2s ease-out, padding-right .2s ease-out;
    }

    &__text-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__text {
        margin-bottom: 0;
        margin-left: remCalc(.3);
        color: var(--c-white);
        overflow: hidden;
        white-space: nowrap;
        opacity: 0;
        transition: opacity .2s ease-out;
    }

    &__icon {
        margin-left: remCalc(.3);
        height: auto;
        min-width: remCalc(1.5);
        max-height: remCalc(1.5);
        color: var(--c-white);
    }
}
