@import "src/@paco/styles";

.incomplete-users-table {
    position: relative;

    &--is-empty &__loader {
        position: relative;
        top: 0;
        height: 20vh;
        margin-top: 1rem;
    }

    &__table-cell:nth-child(3), &__table-cell:nth-child(5) {
        @include column-width(11rem);
    }
}
