@import "src/@paco/styles";

.knowledge-base-sidebar {

    &__loading-spinner,
    &__empty-text {
        border-top: 1px solid var(--c-border-grey);
    }

    &__loading-spinner {
        top: 0;
        height: unset;
    }

    &__empty-text {
        padding: remCalc(1);
        text-align: center;
    }
}
