@import "src/@paco/styles";

$component: change-log-item;

.#{$component} {
    padding: remCalc(.5) 0 0;

    & + & {
        border-top: 1px solid var(--c-border-grey);
        padding-top: remCalc(1.25);
    }

    &--is-open {
        padding-bottom: remCalc(.5);

        .#{$component}__intro,
        .#{$component}__list {
            display: block;
        }

        .#{$component}__extend-button {
            display: flex;
        }
    }

    &__toggle-button {
        @extend %button--reset;

        font-weight: var(--fw-medium);
        color: var(--c-grey);
        opacity: .75;

        &:active,
        &:hover {
            color: var(--c-primary);
        }

        &:focus {
            outline: 0;
            color: var(--c-dark);
        }
    }

    &__toggle-button-icon {
        width: remCalc(.75);
        margin-left: remCalc(.5);
    }

    &__intro {
        margin-top: remCalc(1);
    }

    &__list {
        margin: remCalc(1) 0 0 0;
        padding: 0 0 0 remCalc(1);
    }

    &__list-item + &__list-item {
        margin-top: remCalc(0.5);
    }

    &__extend-button {
        @extend %button--is-primary-outline;

        margin-top: remCalc(1);
    }

    &__title,
    &__intro,
    &__list,
    &__extend-button {
        display: none;
    }
}
