@import "src/@paco/styles";

.export-leave-of-absences-form-error {
    padding: remCalc(.75);
    background: var(--c-lighter-grey);

    &__message {
        margin: 0;
        color: var(--c-red);
    }
}
