@import "src/@paco/styles";

$component: select-overwrite;

.select {
    .#{$component} {
        cursor: pointer;

        &--is-disabled {
            cursor: not-allowed;
            opacity: .8;
        }

        &--has-error {
            border: 1px solid var(--c-warning);
        }

        &--has-primary-styling,
        &--has-secondary-styling {

            .#{$component}__control {
                border-color: var(--c-orange);
                min-height: remCalc(2.5);
                font-weight: var(--fw-medium);
            }

            .#{$component}__indicator,
            .#{$component}__single-value,
            .#{$component}__placeholder {
                color: var(--c-orange);
            }

            .#{$component}__value-container {
                justify-content: center;
            }

            .#{$component}__indicator-separator {
                display: none;
            }
        }

        &--has-secondary-styling {

            .#{$component}__control {
                background: var(--c-orange);
            }

            .#{$component}__indicator,
            .#{$component}__single-value,
            .#{$component}__placeholder {
                color: var(--c-white);
            }
        }

        &--has-primary-styling.#{$component}--is-disabled .#{$component}__control,
        &--has-secondary-styling.#{$component}--is-disabled .#{$component}__control {
            opacity: 0.8;
            background: none;
        }

        &__control {
            border-radius: remCalc(.125);
            border: 1px solid var(--c-border-grey);
            min-height: unset;

            &--is-focused {
                outline: none;
                border-color: var(--c-blue);
                box-shadow: 0 0 0 remCalc(.2) rgba(var(--c-primary-rgb), .25);
            }
        }

        &__input-container {
            color: inherit;
        }

        &__single-value {
            margin: 0;
            color: inherit;
        }

        &__value-container {
            margin: 0 remCalc(.5);
        }

        &__placeholder {
            color: var(--c-grey);
        }

        &__menu-list {
            max-height: remCalc(15.625);
        }

        &__indicator {
            padding: remCalc(.375);
        }

        &__group-heading {
            padding: 0 remCalc(.75);
        }

        &__option {
            color: var(--c-black);

            &--is-selected {
                color: var(--c-white);
                background-color: var(--c-primary);
            }

            &--is-disabled {
                color: var(--c-disabled-grey);
            }
        }
    }
}
