@import "src/@paco/styles";

.edit-dashboard-form {
    padding: remCalc(2) 0;

    &__no-available-widgets {
        padding: 0 remCalc(2);
    }

    &__widgets-list {
        padding: 0 remCalc(2);

        & + & {
            border-top: 1px solid var(--c-border-grey);
            margin-top: remCalc(2);
            padding-top: remCalc(2);
        }
    }
}
