@import 'src/@paco/styles';

.base-schedule-calendar {

    &__top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--page-padding);
        padding-bottom: 0;

        @include for-size(tablet-portrait-up) {
            padding: 0;
        }
    }

    &__title {
        @extend %page-title;

        margin: 0;
        word-break: break-word;
    }

    &__back-button {
        @extend %button--is-primary-outline;

        margin-left: remCalc(1);
        min-width: fit-content;
    }

    &__calendar-header {
        display: none;

        @include for-size(tablet-landscape-up) {
            display: flex;
            margin-top: remCalc(1);
        }
    }

    &__calendar {
        margin-top: remCalc(1);

        @include for-size(tablet-landscape-up) {
            margin-top: -1px;
        }
    }
}
