@import "src/@paco/styles";

$component: template-group-button;

.#{$component} {
    @extend %reset-list-item;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    border-radius: remCalc(.125);
    width: 100%;
    height: remCalc(2.5);
    padding-left: remCalc(1.25);
    font-weight: var(--fw-medium);
    color: var(--c-primary);
    background: var(--c-lighter-grey);
    cursor: move;

    &:hover:not(.#{$component}--user-is-dragging),
    &--is-active {
        background: var(--c-border-grey);

        .#{$component}__drag-icon {
            color: var(--c-orange);
        }
    }

    &--is-disabled {
        pointer-events: none;
        opacity: .5;
    }

    & + & {
        margin-top: remCalc(.625);
    }

    &__group-name,
    &__divider,
    &__drag-icon {
        pointer-events: none;
    }

    &__group-name {
        justify-self: flex-start;
        margin-right: auto;
    }

    &__divider {
        width: 1px;
        height: calc(100% - #{remCalc(.5)});
        background: var(--c-grey);
        opacity: .1;
    }

    &__drag-icon {
        margin: 0 remCalc(.5);
        width: remCalc(1.5);
        height: remCalc(1.5);
        color: var(--c-light-blue);
    }
}
