@import '../../../../style/theme';

$break-mobile: 56.25rem;

$component: shift-detail;

.#{$component} {
    position: relative;
    padding: remCalc(1.5) remCalc(1.875);
    min-height: calc(100vh - #{$navbar-total-height});

    &__container {
        margin: 0 auto;
        width: 100%;
        max-width: remCalc(81.25);
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: remCalc(1.5);
        height: remCalc(2.5);
    }

    &__title {
        display: flex;
        align-items: center;
        border: 0;
        padding: 0;
        height: remCalc(2.5);
    }

    &__title {
        justify-self: flex-start;
        margin-right: auto;
        width: auto;
    }

    &__edit-shift-form, &__shift-plannings-form {
        width: calc(40% - #{$main-padding * 0.5});
        border-radius: 0.625rem;
        background: $white;

        @media only screen and (max-width: $break-mobile) {
            width: 100%;
        }
    }

    &__forms {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        @media only screen and (max-width: $break-mobile) {
            flex-direction: column;
        }
    }

    &__shift-plannings-form {
        width: calc(60% - #{$main-padding * 0.5});

        @media only screen and (max-width: $break-mobile) {
            margin-top: $main-padding;
            width: 100%;
        }
    }

    &__loading-spinner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    &--has-dark-background {
        background: $concept-dark-blue;

        .#{$component}__title .modal-title {
            color: $white;
        }

        .#{$component}__button-cancel {
            color: $white;

            &:hover {
                color: $white;
                text-decoration: underline;
            }
        }
    }

    &__add-temp-worker {
        max-width: remCalc(32.5);
    }
}
