@import "src/@paco/styles";

.change-log-widget {
    position: relative;

    &--is-loading &__list-item {
        margin-top: remCalc(1);
    }

    &__title {
        display: flex;
        align-items: center;
        margin: 0;
        height: remCalc(2.5);
        font-size: remCalc(1);
        color: var(--c-grey);
        text-transform: uppercase;
        opacity: .5;
    }

    &__container {
        position: relative;
        border: 1px solid var(--c-border-grey);
        margin-top: remCalc(1.5);
        width: 100%;
        min-height: remCalc(15);
        padding: remCalc(1.875);
        background-color: var(--c-white);
    }

    &__sub-title {
        margin-bottom: remCalc(0.75);
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__list {
        list-style: none;
        border-top: 1px solid var(--c-border-grey);
        margin-top: remCalc(1.25);
        padding: remCalc(1.25) 0 0;
    }

    &__list-item + &__list-item {
        margin-top: remCalc(1);

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__list-item-skeleton {
        width: remCalc(5);
        height: remCalc(.5);

        &:nth-of-type(2) {
            width: remCalc(12);
        }

        &:nth-of-type(3) {
            width: remCalc(10);
        }

        &:nth-of-type(4) {
            width: remCalc(9);
        }
    }

    &__list-item-skeleton + &__list-item-skeleton {
        margin-top: remCalc(1);
    }
}
