@import "src/@paco/styles";

.absence-badge {
    color: var(--c-red);

    &__icon {
        min-width: remCalc(1.5);
        min-height: remCalc(1.5);
    }

    &__pill {
        @extend %badge-open;
        @extend %text-ellipsis;

        display: block;
        border-color: var(--c-red);
    }
}
