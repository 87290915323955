@import "../../../../style/theme";
@import "src/@paco/styles";

.complete-period-top-bar {
    display: flex;
    flex-direction: column;
    margin-bottom: remCalc(1.25);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: space-between;
    }

    &__title {
        @extend %page-title;

        margin-bottom: remCalc(1);

        @include for-size(tablet-portrait-up) {
            margin-bottom: 0;
        }
    }

    &__status {
        margin-left: 1.25rem;
        line-height: 1.35;
        height: 1.75rem;
        font-size: 1rem;
        font-weight: 600;
    }

    &__select-payroll-period {
        justify-self: flex-end;

        @include for-size(tablet-portrait-up) {
            margin-left: auto;
        }
    }

    &__select-payroll-period-col {

        &:last-child {
            width: remCalc(20);
            margin-left: remCalc(1);
        }
    }
}
