@import "../../../../style/theme.scss";

.period-year-select {
  &__select {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 1.25rem;
    font-size: 1rem;
  }

  &__dropdown {
    width: 100px;
    font-size: 1rem;
    font-weight: 500;
  }
}
