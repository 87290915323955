@import "src/@paco/styles";

.dashboard-small-widget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--c-border-grey);
    min-height: remCalc(7.375);
    padding: remCalc(1.875) remCalc(1.5);
    background: var(--c-white);

    &--is-skeleton {
        pointer-events: none;
    }

    &--is-link {
        &:hover {
            border-color: var(--c-primary);
            text-decoration: none;
        }

        &:hover &__button {
            border-color: var(--c-primary);
            color: var(--c-primary);
        }
    }

    &__content {
        width: 100%;
        min-height: 100%;
    }
}
