@import "src/@paco/styles";

.save-sequence-form {
    @extend %form;

    &__row {
        @extend %form-row;
    }

    &__col {
        @extend %form-col;
    }

    &__shift-info {
        display: flex;
        justify-content: space-between;
        text-align: end;
    }

    &__title {
        margin-block-end: remCalc(.5);
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
    }
}
