@import "src/@paco/styles";

.entity-event-data {
    border-block-start: 0;
    margin-block: remCalc(.25);
    height: fit-content;
    min-height: remCalc(1);

    &__label {
        font-weight: var(--fw-medium);
    }

    &__value {
        margin-inline-start: .625rem;
    }
}
