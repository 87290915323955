@import '../../../../../../style/theme.scss';

.leave-of-absence-saldi {
  position: relative;
  width: 30%;
  min-width: 12.5rem;
  max-width: 24rem;

  &__title {
    margin-bottom: 1.875rem;
    line-height: 1.5;
    font-weight: 700;
  }

  &__pie-chart-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.875rem;
  }

  &__pie-chart {
    width: 50%;
    max-width: 9.75rem;
  }

  &--loket-employee-not-found {
    padding-top: 4rem;
  }
}
