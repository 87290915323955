@import "../../style/theme.scss";

.button-history {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  margin: 0;
  width: 25px;
  height: 25px;
  padding: 0;
  background: none;

  svg {
    fill: $orange;
  }

  &:hover svg {
    fill: $primary;
  }

  &--light {
    &:hover svg {
      fill: $white;
    }
  }

  &--blue {
    svg, &:hover svg {
      fill: $light-blue;
    }
  }
}
