@import 'src/@paco/styles';

.paco-button-loading-spinner {
    --button-loading-spinner-size: 1.5rem;

    width: var(--button-loading-spinner-size);
    height: var(--button-loading-spinner-size);
    border-radius: var(--button-loading-spinner-size);
    border: .25rem solid currentColor;
    border-left-color: transparent;
    animation: spin 1.5s linear infinite;
}
