@import "../../style/theme.scss";

$component: 'user-color';
$blank-color: #498FA7;

.#{$component} {
  display: flex;
  align-items: center;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  background: none;

  &__circle {
    position: relative;
    border: 0;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    overflow: hidden;
  }

  &--tiny .#{$component}__circle {
    border: 1px solid $white;
    width: 9px;
    height: 9px;
  }

  &--small {
    padding: 3px;
  }

  &--large .#{$component}__circle {
    width: 20px;
    height: 20px;
  }

  &:hover .#{$component}__circle:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
  }

  &--blank {
    .#{$component}__circle {
      border: 1px dashed $blank-color;
    }

    &.#{$component}--small:hover .#{$component}__circle {
      border-style: solid;

      &:after {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    &.#{$component}--large .#{$component}__circle {
      border-style: solid;

      &:after {
        content: '';
        transform: rotate(-30deg);
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        background: $blank-color;
      }
    }

    .#{$component}--large:hover .#{$component}__circle {
      background: rgba(0, 0, 0, 0.05);

      &:after {
        display: none;
      }
    }
  }
}
