@import "src/@paco/styles";

.draggable-calendar-item {
    @extend %button--reset;

    width: 100%;

    & + & {
        margin-top: remCalc(.25);
    }
}
