@import "../../style/theme.scss";

.double-date-navigator {
  display: flex;

  &__today-button {
    margin-right: remCalc(1);
    white-space: nowrap;

    @media only screen and (max-width: remCalc(49.3125) ) {
      margin-right: remCalc(.125);
    }
  }

  @media only screen and (max-width: $break-mobile) {
    display: none;
  }
}

.double-date-navigator-mobile {
  display: none;
  align-items: center;
  width: 100%;
  height: 52px;

  .week-navigator {
    height: 100%;
  }

  @media only screen and (max-width: $break-mobile) {
    display: flex;
  }
}
