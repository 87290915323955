@import "../../style/theme.scss";

.items-list-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 250px;
  padding: $main-padding;
  pointer-events: none;

  @media only screen and (max-width: $break-mobile) {
    position: relative;
  }

  img {
    max-height: 65px;
    margin-bottom: 30px;
  }

  p {
    margin: 0 auto;
    max-width: 430px;
    color: $dark;
  }
}
