@import "../../../../style/theme";
@import "src/@paco/styles";

.loket-data-card {
    padding-bottom: 0.5rem;

    strong {
        font-weight: 600;
    }

    &__card-title {
        margin-bottom: 0.5rem;
        font-weight: 600;
    }

    &__paragraph {
        margin-bottom: 1.25rem;
    }

    &__divider {
        margin-bottom: 1.25rem;
        margin-left: -1.875rem;
        width: calc(100% + 3.75rem);
        height: 1px;
        background: $border-grey;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 1.25rem;
    }

    &__button-text {
        width: calc(100% - 2rem);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__button-icon {
        transform: rotate(270deg);
        margin-left: 0.25rem;
    }

    &__loket-coupling-help-tooltip {
        position: absolute;
        transform: translate(remCalc(11), remCalc(1));

        @include for-size(phone-only) {
            transform: none;
            top: remCalc(5.5);
            right: remCalc(6);
        }
    }
}
