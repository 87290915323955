@import 'src/@paco/styles';

.edit-time-button {
    @extend %button--reset;

    line-height: 1;
    width: fit-content;
    min-height: remCalc(1.25);
    padding: remCalc(.125) remCalc(.25) remCalc(.125) 0;
    text-align: left;
    font-size: remCalc(.875);
    font-weight: var(--fw-regular);
    color: inherit;
    cursor: text;

    &:hover,
    &:focus {
        color: inherit;
    }

    &--is-disabled {
        pointer-events: none;
    }

    &__icon {
        @include icon-size(.625);

        margin-right: remCalc(.25);
    }

    @container (max-width: 100px) {
        font-size: remCalc(.75);
        word-spacing: -2px;

        &__icon {
            @include icon-size(.5);

            margin-right: remCalc(.2);
        }
    }
}
