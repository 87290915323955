@import "../../../style/theme.scss";

.form-shift {
  margin: 0 auto;
  max-width: 1300px;
  padding: $main-padding;

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: $main-padding;
    padding-top: 15px;
    position: relative;

    @media only screen and (max-width: $break-mobile) {
      flex-direction: column;
    }

    > div {
      border: 0;
      width: 100%;
      padding: 0;
    }
  }

  &-title h5 {
    line-height: 1.25;
    font-size: 20px;
    font-weight: 600;
    color: $dark;

    @media only screen and (max-width: $break-mobile) {
      margin-bottom: 15px;
    }
  }

  &-modals {
    display: flex;
    position: relative;
    z-index: inherit;
    overflow: visible;

    @media only screen and (max-width: $break-medium) {
      flex-direction: column;
    }
  }

  &-information, &-employees {
    border-color: $border-grey;
  }

  &-information {
    width: 70%;
    margin-right: $main-padding;

    @media only screen and (max-width: $break-medium) {
      width: 100%;
    }
  }

  &-information, &-employees {
    .modal-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
    }
  }

  &-row-button-delete {
    margin-top: auto;
    margin-bottom: 0;
    justify-self: flex-end;
  }

  &-button-delete {
    @include clear-button();
    line-height: 1.1875;
    font-size: 15px;
    color: $red;

    &:hover {
      color: darken($red, $button-hover-darken);
    }
  }

  &-employees-header h5 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-form-employees {
    max-width: 400px;
  }

  &-employees-loader {
    position: absolute;
    top: 0;
  }

  &-employees {
    min-height: 400px;

    @media only screen and (max-width: $break-medium) {
      margin-top: $main-padding;
    }
  }

  &-employees-row {
    margin-bottom: 10px;
  }

  &-planned-users {
    position: relative;

    &--loading {
      min-height: 75px;
    }
  }

  &-employees-available {
    line-height: 3.5;
    font-size: 15px;
    font-weight: 600;
    color: $dark;
  }

  .modal-body {
    padding-bottom: 2rem;
  }

  &__color-help-tooltip {
    position: absolute;
    left: remCalc(2.25);
    top: remCalc(10);
  }

  &__save-sequence-help-tooltip {
    position: absolute;
    right: remCalc(2.25);
    top: remCalc(-2);

    @media only screen and (max-width: $break-mobile) {
      top: remCalc(0);
    }

  }
}

.form-shift--add {
  width: 100%;
  padding: 0;

  .form-shift-modals {
    min-height: 450px;
  }

  .form-shift {
    &-top {
      display: none;
    }

    &-information {
      margin-right: 0;
      width: 100%;
    }
  }

  .modal-body {
    display: block;
    padding-bottom: 0;
  }
}

.form-delete-shift {
  width: 100%;
}

.form-control.is-invalid {
  background-position-x: calc(100% - 10px);
}

.form-save-sequence {
  &-title {
    margin-bottom: 10px;
    font-weight: 600;
  }

  &-top {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $break-mini) {
      flex-direction: column;
    }
  }

  &-date {
    margin-left: 10px;
    text-align: right;

    @media only screen and (max-width: $break-mini) {
      margin: 10px 0;
      text-align: left;
    }
  }
}

.form-notify {
  max-width: 561px;

  &-tabs, &-content {
    margin-bottom: $main-padding;
  }

  &-button {
    @include clear-button();
    border: 1px solid $grey-disabled;
    border-radius: 2px;
    padding: 10px 20px;
    color: $black;
    overflow: hidden;

    &:nth-child(1) {
      border-color: $black;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:nth-child(2) {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--active, &:hover {
      border-color: $black;
      color: $white;
      background: $black;
    }
  }
}

.form-assign-shift-to-user {
  width: 30rem;
}

.form-assign-concept-shift-to-user {
  width: 30rem;

  .modal-content {
    color: $white;
    background-color: $concept-dark-blue;
  }

  .modal-title {
    color: $white;
  }

  .modal-footer button {
    color: $white;
  }
}
