@import "theme";

.form-group {
    label {
        font-weight: 600;

        &.required {
            position: relative;

            &:after {
                content: '*';
                display: block;
                position: absolute;
                top: -3px;
                right: -9px;
                color: $red;
            }
        }
    }

    input {
        transition: padding 0.2s ease-out, background-color 0.2s ease-out;

        &[readonly], &[disabled] {
            background-color: $white;
            border: 0;
            padding-left: 0;
        }
    }

    input[type='checkbox'] + label {
        font-weight: 400;
    }

    .form-fake-field {
        padding: $input-padding-y 0;
        margin-bottom: 0;
    }

    .custom-control-label {
        &:before, &:after {
            left: -1rem;
        }

        div {
            margin-left: 0.5rem;
        }
    }

    .invalid-feedback.active {
        display: block;
    }

    .warning-feedback {
        display:block;
        margin: 0.5rem 0 1rem;
        color: $orange;
    }
}

.form-control:disabled {
    border: 1px solid var(--c-border-grey);
    opacity: .5;
    background: var(--c-light-grey);
    cursor: not-allowed;
}
