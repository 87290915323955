@import "src/@paco/styles";

.add-district-division-template-form {
    @extend %form;

    &__row {
        @extend %form-row;
    }

    &__col {
        @extend %form-col;

        width: 100%;
    }
}
