@import "../../../../../../style/theme";

$component: "concepts-list-view-user";

$cell-height: 3.25rem;

.#{$component} {

    &--is-company-medic &__name {
        color: var(--c-red);
    }

    td.#{$component}__td {
        height: $cell-height !important;
        padding: 0.3125rem;
        vertical-align: top;
        border-top: 1px solid $concept-border-grey;
        border-right: 1px solid $concept-border-grey;
    }

    td {
        &:first-of-type {
            background: $white;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        height: 100%;
        container-type: inline-size;
    }

    &__name {
        font-size: remCalc(.9375);
        font-weight: var(--fw-regular);
    }

    &__name-td {
        height: $cell-height !important;
    }

    &__tooltip {
        display: inline-flex;
    }

    .#{$component}__employment-type {
        display: inline-flex;
        color: $white;
        font-weight: 800;
        background: $primary;
        border-radius: remCalc(.125);
        cursor: pointer;
    }

    &__name {
        margin: remCalc(.375);
        font-size: remCalc(.875);
    }

    &__hours {
        display: inline-block;
        margin-left: remCalc(.3125);
        font-weight: 600;
        word-break: keep-all;
    }

    .calendar-button-add {
        min-height: remCalc(1);
    }
}
