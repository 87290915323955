@import 'src/@paco/styles';

.shifts-calendar-action-bar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: remCalc(1);
  position: relative;
  width: 100%;
  padding-bottom: remCalc(1);
  background-color: var(--c-background);
  z-index: 80;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    margin-bottom: 0;
    padding-top: remCalc(1);
  }

  &__navigation {

    @include for-size(tablet-landscape-up) {
      flex-grow: 1;
    }
  }

  &__user-search.search-input {
    display: none;

    @include for-size(tablet-landscape-up) {
      display: block;
      width: remCalc(11.875);
    }
  }

  &__notify-employees-button {
    display: none;

    @include for-size(tablet-landscape-up) {
      display: flex;
      position: absolute;
      top: remCalc(-2.5);
      right: 0;
      color: var(--c-orange);
    }
  }

  &__export-button {
    @extend %button--is-primary;

    display: none;

    @include for-size(tablet-landscape-up) {
      display: flex;
      height: remCalc(2.5);
    }
  }

  &__export-button-icon {

    @include icon-size(1.25);
  }

  &__mobile-buttons {
    display: flex;
    justify-content: stretch;

    @include for-size(tablet-landscape-up) {
      display: none;
    }
  }

  &__notify-employees-button-mobile,
  &__export-button-mobile {
    margin-right: remCalc(.5);
    width: remCalc(3.375);
    height: remCalc(2.5);
    background: var(--c-orange);
    color: var(--c-white);
  }

  &__notify-employees-button-mobile-icon,
  &__export-button-mobile-icon {

    @include icon-size(1.5);
  }
}
