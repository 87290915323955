@import "../../../style/theme.scss";

$component: form-add-track;

.#{$component} {

  &__track-type-radio-buttons {
    margin: remCalc(1) 0 remCalc(1.5);
  }

  &__comment {
    margin-top: remCalc(1);
  }
}
