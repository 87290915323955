@import "src/@paco/styles";

.district-division-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &--scrolled-past-element &__calendar-controls {

        @include for-size(tablet-portrait-up) {
            position: fixed;
            top: var(--navbar-height);
            width: calc(50% - #{remCalc(1)});
        }

        @include for-size(tablet-landscape-up) {
            width: calc(40% - #{remCalc(1)});
        }
    }

    &--scrolled-past-element &__buttons {
        justify-content: flex-end;
        width: 100%;
        margin-left: auto;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            width: calc(50% - #{remCalc(1)});
        }

        @include for-size(tablet-landscape-up) {
            width: calc(60% - #{remCalc(1)});
        }
    }

    &__today-button {
        margin-left: remCalc(1);
        order: 1;
    }

    &__buttons {
        display: flex;
    }

    &__export-button {
        @extend %button--is-primary;
    }

    &__export-button,
    &__reset-button {
        margin-left: remCalc(1);
    }

    &__save-button,
    &__back-button,
    &__reset-button {
        @extend %button--is-primary-outline;

        &:disabled {
            color: var(--c-orange);
            background: var(--c-white);
            opacity: .5;
        }
    }

    &__save-button-icon,
    &__export-button-icon,
    &__reset-button-icon {
        width: remCalc(1);
        height: remCalc(1);
        min-width: remCalc(1);
    }

    &__export-button-help-tooltip {
        position: absolute;
        top: remCalc(9.5);
        right: remCalc(5);

        @include for-size(tablet-portrait-up) {
            top: remCalc(10);
            right: remCalc(5);
        }

        @include for-size(tablet-landscape-up) {
            right: remCalc(7);
        }
    }
}
