@import "src/@paco/styles";

.employment-status-filter-container {

    &__check {
        align-items: flex-start;
        margin-bottom: 0;
    }

    &__check-label {
        white-space: normal;
    }

    &__check-box {
        margin-top: remCalc(.25);
    }
}
