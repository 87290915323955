@import "src/@paco/styles";

.leave-of-absences-overview-table-body-cell {
    position: relative;
    border-top: 1px solid var(--c-lighter-grey);
    height: remCalc(2.125);
    padding: remCalc(.375) 0;
    background: var(--c-white);

    &:nth-child(2n) {
        background: var(--c-light-grey);
    }

    &__active-layer {
        display: none;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: calc(100% + 1px);
        border-top: 1px solid var(--c-border-grey);
        background: var(--c-white);

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--c-orange);
            opacity: .22;
        }
    }
}
