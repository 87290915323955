@import "../../../../style/theme.scss";

$component: exc-request-button;

.#{$component} {
  @include clear-button();

  width: 2.5rem;
  height: 2.5rem;
  color: $green-success;
  border: 1px solid $green-success;
  border-radius: 0.125rem;

  & + & {
    margin-left: 0.625rem;
  }

  &:hover {
    color: darken($green-success, $button-hover-darken);
    border: 1px solid darken($green-success, $button-hover-darken);
  }

  &--type-decline {
    color: $red;
    border: 1px solid $red;

    &:hover {
      color: darken($red, $button-hover-darken);
      border: 1px solid darken($red, $button-hover-darken);
    }
  }

  &:disabled {
    border-color: $grey-disabled;
    color: $grey-disabled;
    opacity: 0.75;
    pointer-events: none;
  }
}
