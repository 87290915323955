@import "src/@paco/styles";

.printable-district-division-title-block-item {
    font-size: remCalc(1.25);
    color: var(--c-black);
    font-weight: var(--fw-medium);

    &--is-hidden {
        visibility: hidden;
    }
}
