@import "src/@paco/styles";
@import "../../../../style/theme";

$component: "availability-button";

.#{$component} {
    @include calendar-item();

    position: relative;
    padding: remCalc(.3125) remCalc(.625) remCalc(.5);
    color: var(--c-white);
    min-width: 100%;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: min-width .3s, box-shadow .3s;

    &:has(.input:focus) {
        min-width: 164px;
        z-index: 10;
        box-shadow: 0 0 6px rgba(0, 0, 0, .33);
    }

    &--is-company-medic &__name {
        color: var(--c-red);
    }

    &__time-and-color {
        margin-bottom: remCalc(.125);
        white-space: nowrap;
    }

    &__time-wrapper {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__time-button,
    &__name,
    &__description {
        line-height: 1;
    }

    &__time-button,
    &__edit-time-form {
        position: relative;
        z-index: 2;
    }

    &__time-and-color, &__description {
        display: flex;
    }

    &__description {
        justify-content: space-between;
    }

    &__button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    &__time {
        margin-bottom: 0.25rem;
        font-weight: 600;
    }

    &__color {
        margin-left: remCalc(.25);
    }

    &--is-leave-of-absence {
        &-approved {
            background: $primary;
            border: 1px solid $white;

            &:hover {
                background-color: darken($primary, $button-hover-darken);
            }
        }

        &-open {
            color: $primary;
            background: $lighter-grey;
            border: 1px solid $primary;

            &:hover {
                background-color: darken($lighter-grey, $button-hover-darken);
            }
        }

        &-denied {
            border: 1px solid $placeholder;
            color: $placeholder;
            background: $white;

            &:hover {
                background-color: darken($white, 5%);
            }
        }
    }

    &--is-absence {
        background: $scarlet;

        &:hover {
            background-color: darken($scarlet, $button-hover-darken);
        }
    }

    &--is-planning-preference {
        &-unavailable {
            background: $orange-light;

            &:hover {
                background-color: darken($orange-light, $button-hover-darken);
            }
        }

        &-available {
            color: $green;
            background: $green-available;
            border: 1px solid $green;
            pointer-events: none;

            &:hover {
                background-color: darken($green-available, $button-hover-darken);
            }
        }
    }

    &--is-shift-planning {
        background: $green;

        &:hover {
            background-color: darken($green, $button-hover-darken);
        }
    }

    &--is-registered-shift-planning {
        color: $green;
        background: $white;
        border: 1px solid $green;

        &:hover {
            background-color: darken($white, $button-hover-darken);
        }
    }

    &--is-shift-concept-planning {
        background: $concept-blue;

        &:hover {
            background-color: darken($concept-blue, $button-hover-darken);
        }
    }

    &--is-shift-planning,
    &--is-shift-concept-planning,
    &--is-registered-shift-planning {

        .#{$component}__time-button {
            pointer-events: visible;
        }
    }

    @container (max-width: 100px) {
        padding: remCalc(.25) remCalc(.375) remCalc(.375);

        &__color {
            margin-left: remCalc(.125);
        }
    }
}
