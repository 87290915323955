@import "src/@paco/styles";

$component: calendar-day;

.#{$component} {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__button-add {

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &:nth-child(7n) {

        .#{$component}__header, .#{$component}__content {
            border-right: 1px solid var(--c-border-grey);
        }
    }

    &:nth-child(n+8) .#{$component}__header {
        display: none;
    }

    &--is-out-of-range &__header,
    &--is-out-of-range &__content {
        opacity: 0.5;
    }
}
