@import "theme";

.modal {
    z-index: 1000;
}

.modal-backdrop {
    z-index: 990;
}

.modal-title {
    font-size: 1.43rem;
    font-weight: 600;
}

.modal-subtitle {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
}

.modal-body {
    padding-bottom: 0;
}

.modal-footer {
    border-top: 0;
}

.modal .user-page-link  {

}