@import "../../../../style/theme.scss";

$component: week-input;

.#{$component} {
  margin-bottom: 1rem;

  &__number {
    display: flex;
    align-items: center;
    margin-bottom: 0.3125rem;
    font-weight: 600;
  }

  &__tooltip {
    margin: 0 0.625rem;
  }

  &--has-error .#{$component}__input {
    border: 1px solid $red;
  }
}
