@import "src/@paco/styles";

.district-division-empty-state {
    justify-content: center;
    min-height: 10rem;

    &__select {
        margin-top: remCalc(2);
        min-width: remCalc(14);
    }

    &__loader {
        top: 0;
        height: 100%;
    }

    &__empty-state + &__empty-state {
        margin-top: remCalc(2);
    }

    &__empty-state--is-inconspicuous {
        border: 0;
        background: none;
    }
}
