@import "../../style/theme.scss";

.table-filter {
  min-width: 90px;
  padding: 0 10px;

  @media only screen and (max-width: 900px) {
    padding: 0;
  }

  .react-select__control {
    border-color: $border-grey;
    border-radius: 3px;
  }

  .react-select__value-container {
    padding: 3px 6px 3px 12px;
  }

  .react-select__placeholder {
    font-weight: 400;
    color: $dark;
  }

  .react-select__indicators {
    padding-right: 5px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator svg {
    fill: $dark;
  }
}