@import '../../../../../../style/theme';

$component: 'availability';

.#{$component} {

  &__tooltip-container {
    margin-bottom: 4px;
    font-weight: 600;
  }
}
