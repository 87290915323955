@import 'src/@paco/styles';

.form-action-buttons {
    @extend %form-col;

    display: flex;
    justify-content: flex-end;
    margin-top: remCalc(1);

    &__cancel {

        &:hover {
            text-decoration: underline;
        }
    }

    &__confirm {
        @extend %button--is-primary;

        margin-left: remCalc(1);
        padding: remCalc(.75) remCalc(1);
    }
}
