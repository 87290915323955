@import "theme";

.table-container-responsive {
    position: relative;

    @media only screen and (max-width: $break-mobile) {

        &:after {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: calc(100vw - #{$main-padding});
            width: $main-padding;
            height: 100%;
            background: linear-gradient(to right, rgba(241,241,241,0) 0%,rgba(241,241,241,1) 100%);
        }
    }
}

.table-container {
    display: block;
    border: 1px solid $border-grey;
    border-radius: 10px;
    min-width: 100%;
    overflow-x: auto;
    background: $white;

    &--has-filter-select {
        min-height: 192px;
    }
}

.table {
    margin-bottom: 0;
    background-color: $white;
    overflow: hidden;
    overflow-y: visible;

    thead {
        border: none;

        th {
            border-right: 1px solid $gray-300;
            margin-top: 0;
            border-bottom: 0;
            font-weight: 600;
            background-color: $table-bg;

            &.has-sort-button {
                padding: 0;

                button {
                    @include clear-button();
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    padding: 2px 1rem;

                    &:hover {
                        color: $white;
                        background: $primary;
                    }

                    &.sort-button-active {
                        font-weight: 600;
                    }
                }

                span {
                    margin-left: 3px;
                    font-size: 9px;
                }
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    tbody {
        border-top: 1px solid $gray-300;
    }

    tr:first-child {
        th, td {
            border-top: 0;
        }
    }

    tr:first-child:last-child {
        border-bottom: 1px solid $gray-300;
    }

    th, td {
        height: 60px;
        vertical-align: middle;
        padding: $spacer;
        border-right: 1px solid $gray-300;

        &:last-child {
            border-right: none;
        }
    }

    .td {
        &-delete, &-edit {
            position: relative;
            width: 60px;
            min-width: 60px;
            padding: 0;

            button {
                width: calc(100% - 4px);
                height: 100%;
            }
        }

        &-edit-delete {
            display: flex;
            width: 90px;

            button:first-child {
                margin-right: 10px;
            }
        }

        &-three-icon-buttons {
            width: 9rem;

            button {
                margin-right: 0.8rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-four-icon-buttons {
            width: 160px;

            button {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-info-circles {
            padding-right: calc(#{$spacer} - 5px);
            padding-bottom: calc(#{$spacer} - 10px);
            padding-left: calc(#{$spacer} - 5px);

            > div {
                margin-right: 5px;
                margin-bottom: 10px;
                margin-left: 5px;
            }
        }

        &-timer {
            font-weight: 600;
            font-size: 18px;
        }
    }
}
