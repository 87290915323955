@import "src/@paco/styles";

$component: shift-planning-selector;

.shift-planning-selector {
    border: 1px solid var(--c-border-grey);
    border-radius: var(--table-border-radius);
    background: var(--c-white);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: remCalc(.25) remCalc(1) remCalc(.75);
    }

    &__title {
        margin: remCalc(.5) remCalc(1) 0 0;
        font-size: remCalc(1.25);
        font-weight: var(--fw-medium);
        color: var(--c-dark-blue);
    }

    &__select-departments {
        margin-top: remCalc(.5);
        min-width: remCalc(20);
        font-size: remCalc(.875);

        .select-overwrite {
            &__option {
                color: var(--c-primary);

                &--is-selected {
                    color: var(--c-disabled-grey);
                }
            }

            &__multi-value__label {
                color: var(--c-primary);
            }

            &__group-heading {
                padding-top: remCalc(.25);
            }

            &__multi-value {
                background-color: unset;
            }

            &__multi-value__remove {
                display: none;
            }
        }
    }

    &__shift-plannings {
        border-top: 1px solid var(--c-border-grey);
        margin: 0;
        width: 100%;
        list-style: none;
        padding: remCalc(.75);
    }

    &__shift-plannings-container {
        display: flex;
        position: relative;
        min-height: remCalc(18);
    }

    .#{$component}__loader {
        top: 0;
        height: 100%;
    }

    &__list-item + &__list-item {
        margin-top: remCalc(.5);
    }

    &__shift-planning-divider {
        margin: remCalc(1) 0;
        height: 1px;
        width: 100%;
        background: var(--c-border-grey);
    }

    &__no-shift-plannings {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
}
