.full-height-page {
    position: fixed;
    top: $navbar-height;
    width: 100%;
    height: calc(100vh - #{$navbar-height});
    z-index: 100;

    &--no-nav {
        top: 0;
        height: 100vh;
    }
}
