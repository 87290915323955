@import "../../../../style/theme.scss";

.exc-shift-info {
  display: flex;
  justify-content: space-between;

  &__date, &__time {
    font-weight: 600;
    text-align: right;
  }
}
