@import "src/@paco/styles";

.entity-event {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: remCalc(1);
    position: relative;
    padding-block: remCalc(1.5);
    padding-inline-start: remCalc(4);

    &--is-expanded &__expand-icon {
        transform: scale(1, -1);
    }

    &__changes {
        position: relative;
        max-width: 70%;
    }

    &__table {
        margin-block-start: .5rem;
        width: 100%;
    }

    &__title {
        margin: 0;
        font-size: remCalc(1);
        font-weight: var(--fw-regular);
    }

    &__expand-icon {
        @include icon-size(1);
    }

    &__meta-and-button-wrapper {
        display: flex;
        justify-self: flex-end;
        margin-inline-start: auto;
        gap: remCalc(.5);
        text-align: right;
    }

    &__circle {
        position: absolute;
        left: remCalc(1);
        top: calc(50% - #{remCalc(.5)});
        box-sizing: border-box;
        height: remCalc(1.125);
        width: remCalc(1.125);
        border: 1px solid var(--c-cyan);
        border-radius: 50%;
        background: var(--c-white);

        &::after {
            content: "";
            position: absolute;
            top: remCalc(.125);
            left: remCalc(.125);
            width: remCalc(.75);
            height: remCalc(.75);
            background: var(--c-cyan);
            border-radius: 50%;
        }
    }
}
