@import "src/@paco/styles";

$component: 'calendar-navigation';

.calendar-navigation {
    display: flex;
    flex-direction: column-reverse;
    padding: remCalc(1) remCalc(1) 0;

    @include for-size(tablet-portrait-up) {
        padding: 0;
    }

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
    }

    &--show-mobile-controls {
        padding: 0;

        .#{$component}__controls-mobile {
            display: flex;

            @include for-size(tablet-landscape-up) {
                display: none;
            }
        }

        .#{$component}__controls {
            display: none;

            @include for-size(tablet-landscape-up) {
                display: flex;
            }
        }
    }

    &__nav-radio-list-wrapper {
        display: flex;
        align-items: center;
        margin-top: remCalc(1);

        @include for-size(tablet-landscape-up) {
            margin: 0 remCalc(1) 0 0;
        }
    }

    &__nav-radio-list {
        margin: 0;
    }

    &__mobile-today-button {
        @extend %text-ellipsis;

        margin-left: remCalc(1);

        @include for-size(tablet-landscape-up) {
            display: none;
        }
    }

    &__controls-mobile {
        --negative-margin: calc(#{var(--page-padding)} * -1);

        display: none;
        margin: 0 var(--negative-margin);

        @include for-size(tablet-portrait-up) {
            border-top: 1px solid var(--c-border-grey);
        }
    }

    &__help-tooltip {
        position: absolute;
        right: remCalc(5);
        top: remCalc(-1);
    }
}
