@import "../../style/theme.scss";
@import "src/@paco/styles";

.groups {
    &__page-help-tooltip {
        position: absolute;
        top: remCalc(3);
        left: remCalc(13);

        @include for-size(tablet-portrait-up) {
            top: unset;
            left: remCalc(22);
        }
    }

    &__add-department-help-tooltip {
        position: absolute;
        top: remCalc(3);
        left: remCalc(-8);
    }

    &__manage-groups-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(-8);
    }
}

.groups-departments-wrapper {
    padding: remCalc(1.25);

    @include for-size(tablet-portrait-up) {
        padding: 0;
    }
}

.groups-departments-table {

    &__buttons-cell {
        z-index: 5;

        &:nth-of-type(3) {
            @include column-width(6rem);

            margin-left: auto;
            margin-right: remCalc(.75);
        }
    }

    &__button-edit, &__button-delete {
        color: var(--c-light-blue);

        &:hover {
            color: var(--c-light-blue);
        }
    }

    &__button-delete {
        margin-left: remCalc(.5);
    }
}
