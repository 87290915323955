@import "../../../../style/theme";

$log-block-item: 'log-block-item';

.#{$log-block-item} {
  &__bold {
    font-weight: 600;
  }

  &__prop-name, &__value {
    padding-right: 10px;

    &:last-of-type {
      padding-right: 0;
    }
  }

  &__prop-name {
    font-weight: 600;
  }

  &__values {
    display: flex;
  }

  &__value:first-child {
    margin-right: 16px;
  }
}
