@import "src/@paco/styles";

.inactive-user {
    max-width: remCalc(30);

    &__title {
        font-size: remCalc(1.25);
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__intro,
    &__contact,
    &__logout-button {
        margin-top: remCalc(2);
    }

    &__contact {
        margin-top: remCalc(1.5);
    }

    &__logout-button {
        @extend %button--is-primary;
    }
}
