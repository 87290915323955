@import "src/@paco/styles";

.navigation-link {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 0 remCalc(1);
    font-weight: var(--fw-medium);
    white-space: nowrap;
    color: var(--c-primary);

    &__label {
        @extend %text-ellipsis;
    }

    &__counter {
        position: relative;
        margin-left: remCalc(.625);

        @include for-size(tablet-portrait-up) {
            position: absolute;
            top: remCalc(.25);
            right: remCalc(.25);
            margin-left: 0;
        }
    }

    &:hover, &:focus {
        text-decoration: none;
    }

    &--has-counter {
        padding-right: remCalc(1.75);
    }
}
