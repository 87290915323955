@import "src/@paco/styles";

$component: 'calendar-action-button';

.#{$component} {
    @extend %button--reset;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: remCalc(.75) 0;
    opacity: 0;
    color: var(--c-white);

    &:hover {
        opacity: 1;

        .#{$component}__circle {
            background: var(--c-medium-grey);
        }
    }

    &:focus {
        outline: 0;

        .#{$component}__circle {
            outline: solid 2px var(--c-border-grey);
        }
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        width: remCalc(1.625);
        aspect-ratio: 1;
        background: var(--c-border-grey);
        border-radius: 50%;
        pointer-events: none;
    }

    &__icon {
        width: remCalc(.875);
        aspect-ratio: 1;
    }
}
