@import '../../../../../../style/theme.scss';

$component: leave-of-absence-saldo;

.#{$component} {

  & + & {
    margin-top: 0.5rem;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.5;
  }

  &__title {
    margin-right: 0.625rem;
  }

  &__hours-and-button-toggle-container {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    margin-left: auto;
  }

  &__button-toggle-container {
    width: 1.25rem;
  }

  &__button-toggle {
    @include clear-button();
    display: none;

    &:hover {
      opacity: 0.7;
    }
  }

  &__hours {
    margin-right: 0.25rem;
    white-space: nowrap;
  }

  &__color {
    border-radius: 1px;
    margin-right: 0.625rem;
    width: 0.625rem;
    height: 0.625rem;
  }

  &__children {
    display: none;
    margin-top: 0.5rem;
    padding: 0.625rem 1.25rem;
    background: #F3F3F3;

    .#{$component}__button-toggle-container {
      display: none;
    }
  }

  &--is-title {
    font-weight: 700;
  }

  &--has-children .#{$component}__button-toggle {
    display: block;
  }

  &--show-children {
    .#{$component}__children {
      display: block;
    }

    .#{$component}__button-toggle {
      transform-origin: 50% 50%;
      transform: scaleY(-1);
    }
  }

  &--has-no-children:not(.#{$component}--is-title) {
    .#{$component}__children {
      display: none;
    }
  }

  &--show-children + .#{$component} {
    margin-top: 1rem;
  }
}
