@import "../../style/theme.scss";

.button-with-tooltip {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;

  &--disabled {
    background: $grey-disabled !important;
    cursor: not-allowed !important;
  }
}
