@import "src/@paco/styles";

$component: shifts-widget;

.shifts-widget {

    &--is-skeleton {
        .#{$component}__shifts-item + .#{$component}__shifts-item {
            margin-top: remCalc(1.5);
        }
    }

    &__shifts {
        border: 1px solid var(--c-border-grey);
        margin-top: remCalc(1.5);
        padding: remCalc(1.25);
        list-style: none;
        background-color: var(--c-white);

        @include for-size(tablet-landscape-up) {
            padding: remCalc(1.875);
        }
    }

    &__shifts-item {
        width: 100%;

        & + & {
            margin-top: remCalc(1);
        }

        & + .#{$component}__shifts-item-divider {
            margin-top: remCalc(1.25);
        }
    }

    &__shifts-divider {
        width: 100%;
        height: 1px;
        background-color: var(--c-border-grey);
    }
}
