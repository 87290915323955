@import "src/@paco/styles";

.knowledge-base-table {
    position: relative;
    border: 0;
    border-radius: 0;
    min-height: 12rem;
    background: none;

    &__loading-spinner {
        top: 0;
        height: 100%;
        pointer-events: none;
    }

    &__table {
        overflow-y: hidden;
    }

    &__valk-table {
        position: relative;
        border: 0;
        border-radius: 0;
        background: none;
    }

    &__table-head {
        font-weight: var(--fw-regular);
        background: none;
    }

    &__table-head-row:hover {
        background: none;
    }

    &__row {
        position: relative;

        &:hover {
            background: var(--c-light-grey);
        }
    }

    &__cell,
    &__buttons-cell {
        border: 0;
        color: var(--c-medium-grey);
    }

    &__cell:last-child,
    &__buttons-cell {
        width: remCalc(6);
        min-width: inherit;
    }

    &__buttons-cell {
        justify-content: flex-end;
    }

    &__cell:nth-child(1),
    &__cell:nth-child(2),
    &__cell:nth-child(3) {
        flex-grow: 5;
        max-width: inherit;
    }

    &__cell:nth-child(1) &__cell-content {
        padding-left: 0;
        color: var(--c-black);
    }

    &__cell:nth-child(3) {
        flex-grow: 1;
    }

    &__cell:last-child &__cell-content {
        padding-right: 0;
    }

    &__row-button {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - #{remCalc(6)});
        height: 100%;
        cursor: pointer;
    }

    &__button {
        color: var(--c-medium-grey);

        & + & {
            margin-left: remCalc(.5);
        }

        &:hover,
        &:focus {
            color: var(--c-primary);
        }
    }

    &__file-icon,
    &__button-icon {
        width: remCalc(1.5);
        height: remCalc(1.5);
        min-width: remCalc(1.5);
        min-height: remCalc(1.5);
    }

    &__file-icon {
        margin-right: remCalc(.5);
        color: var(--c-medium-grey);
    }
}
