@import "src/@paco/styles";

.select-input {
    display: block;

    &__label {
        margin: 0;
        width: 100%;
    }

    &__error-label {
        margin-top: remCalc(.25);
    }
}
