@import "theme";

.custom-switch {
  .custom-control-label:hover:before {
    border: 2px solid $light-blue;
  }

  .custom-control-input:checked ~ .custom-control-label:before {
    background-color: $light-blue;
    border-color: $light-blue;
  }
}