@import 'src/@paco/styles';

$component: paco-calendar-shift;

.#{$component} {
    @extend %calendar-item;

    position: relative;
    border-color: var(--c-green);
    color: var(--c-green);
    background: var(--c-green-available);

    &[draggable="true"] {
        padding-bottom: remCalc(.25);
    }

    &--show-registered-shift-plannings[draggable="true"] {
        padding-bottom: remCalc(.625);
    }

    &:hover[draggable="true"] &__draggable-icon {
        display: block;
        position: absolute;
        bottom: 0;
        left: calc(50% - #{remCalc(.375)});
        width: remCalc(.75);
        height: remCalc(.75);
    }

    &:hover, &:focus {
        color: var(--c-white);
        background-color: var(--c-green-dark);

        .#{$component}__employment-type {
            color: var(--c-green);
        }
    }

    &--is-past {
        border-color: var(--c-disabled-grey);
        color: var(--c-white);
        background-color: var(--c-disabled-grey);

        .#{$component}__employment-type {
            color: var(--c-disabled-grey);
        }

        &:hover, &:focus {
            background-color: var(--c-medium-grey);

            .#{$component}__employment-type {
                color: var(--c-medium-grey);
            }
        }
    }

    &--is-full {
        color: var(--c-white);
        background-color: var(--c-green);

        .#{$component}__employment-type {
            color: var(--c-green);
        }

        .#{$component}__registered-employees-info {
            color: var(--c-green-dark);
        }
    }

    &--is-preview {
        color: var(--c-orange);
        background: var(--c-concept-light-orange);
        border-color: var(--c-orange);
        pointer-events: none;

        .#{$component}__employment-type {
            color: var(--c-orange);
        }

        .#{$component}__registered-employees-info {
            border-color: var(--c-orange);
            color: var(--c-orange);
        }
    }

    &__main-info {
        @extend %calendar-item-main-info;
    }

    &__edit-time-button,
    &__edit-time-form {
        min-height: remCalc(1);
        padding: 0;
        font-weight: var(--fw-medium);
    }

    &__edit-time-button,
    &__edit-time-form,
    &__employee-icon {
        position: relative;
        font-size: inherit;
        z-index: 10;
    }

    &__time-and-people-count {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: var(--fw-medium);
    }

    &__department {
        margin-top: remCalc(.25);
        text-align: left;

        &--is-bold {
            font-weight: var(--fw-medium);
        }
    }

    &__department-group-name {
        margin-right: remCalc(.25);
        font-weight: var(--fw-medium);
    }

    &__planned-employees {
        @extend %reset-list;

        margin-top: remCalc(.25);
    }

    &__registered-employees-info {
        border: 1px solid var(--c-green);
        border-radius: 0 0 2px 2px;
        margin: remCalc(.25) auto 1px;
        width: calc(100% - 2px);
        padding: remCalc(.25) remCalc(.375);
        font-size: remCalc(.6875);
        text-align: center;
        background: var(--c-white);
        color: var(--c-green);
    }

    &__draggable-icon {
        display: none;
    }

    &__button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
