@import "../../../../style/theme.scss";

.exc-card-reply {
  display: flex;
  align-items: center;
  border-radius: 0.125rem;
  border: 1px solid $border-grey;
  padding: 0.625rem 1.3125rem 0.625rem 1.1875rem;

  @media only screen and (max-width: $break-mini) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625rem 0.9375rem;
  }

  &:not(:first-child) {
    margin-top: -1px;
  }

  &:last-of-type {
    margin-bottom: 1rem;
  }

  &__name {
    display: flex;
    align-items: center;
    margin-right: 0.3125rem;
    line-height: 1.285;

    &-link {
      margin-right: 0.3125rem;
      font-weight: 600;
    }
  }

  &__hours {
    margin-left: 0.625rem;
    font-weight: 600;
  }

  &__button-container {
    display: inline-flex;
    margin-left: auto;
  }

  &__button-accept, &__button-decline {
    @include clear-button();

    width: 2.4375rem;
    height: 2.4375rem;
    color: $green-success;
    border: 1px solid $green-success;
    border-radius: 2px;

    &:hover {
      color: darken($green-success, $button-hover-darken);
      border: 1px solid darken($green-success, $button-hover-darken);
    }

    &--disabled {
      background: $grey-disabled !important;
      opacity: 0.75;
      pointer-events: none;
    }
  }

  &__button-decline {
    margin-right: 0.625rem;
    color: $red;
    border: 1px solid $red;

    &:hover {
      color: darken($red, $button-hover-darken);
      border: 1px solid darken($red, $button-hover-darken);
    }
  }
}