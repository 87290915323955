@import "src/@paco/styles";

.list-view {

    &__calendar-overview-top {
        display: flex;
        flex-direction: column;
        gap: remCalc(1);
        margin-bottom: remCalc(1);
        width: 100%;

        @include for-size(desktop-up) {
            flex-direction: row;
        }
    }

    &__navigation {
        flex-grow: 10;
        position: relative;
        z-index: 20;
    }

    &__nav-buttons {
        margin: remCalc(1);
        order: 2;

        @include for-size(tablet-portrait-up) {
            order: inherit;
            margin: 0;
        }
    }

    &__calendar-toolbar {

        @include for-size(tablet-portrait-up) {
            margin: 0 remCalc(1);
        }
    }

    &__search-bar {
        justify-self: flex-end;
        margin-left: auto;
        order: 3;
        margin-right: remCalc(1);

        @include for-size(tablet-portrait-up) {
            order: inherit;
            margin-right: 0;
        }
    }

    &__calendar-legend {
        margin-bottom: remCalc(1);
    }

    &__filters-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(-8);
    }

    &__search-help-tooltip {
        position: absolute;
        top: remCalc(6);
        right: remCalc(1);

        @include for-size(tablet-portrait-up) {
            top: remCalc(-.5);
        }
    }

    &__legend-help-tooltip {
        position: absolute;
        top: remCalc(12);
        right: remCalc(10);

        @include for-size(tablet-portrait-up) {
            top: remCalc(4.5);
            right: remCalc(2);
        }
    }
}

