@import "badge";
@import "button";
@import "calendar";
@import "flex";
@import "form";
@import "header";
@import "input";
@import "list";
@import "modal";
@import "printable-page";
@import "skeleton";
@import "table";

%text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

%horizontal-scrollbar {

    &::-webkit-scrollbar {
        height: remCalc(.5);
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--c-border-grey);
        border: 0 none var(--c-white);
        border-radius: remCalc(3.125);

        &:hover &:active {
            background: var(--c-white);
        }
    }

    &::-webkit-scrollbar-track {
        background: var(--c-primary);
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}
