@import "src/@paco/styles";

.entity-event-change {
    border-block-start: 0;
    margin-block: remCalc(.25);
    height: fit-content;
    min-height: remCalc(1);

    &__label {
        font-weight: var(--fw-medium);
    }

    &__from,
    &__to {
        display: flex;
        gap: .625rem;
    }

    &__from,
    &__to {
        margin-inline-start: .625rem;
        word-break: break-word;
    }
}
