@import "src/@paco/styles";

.shift-detail-form {
    position: relative;
    margin: 0 auto;
    max-width: 81.25rem;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @include for-size(phone-only) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__title {
        @extend %page-title;

        margin: 0;
    }

    &__buttons-wrapper {
        display: flex;
        gap: 1rem;

        @include for-size(phone-only) {
            margin-block-start: 1rem;
        }
    }

    &__cancel-button {
        @extend %button;
    }

    &__submit-button,
    &__sequence-button {
        @extend %button--is-primary;
    }

    &__save-sequence-help-tooltip {
        position: absolute;
        inset-inline-end: remCalc(2.5);
        inset-block-start: remCalc(-2);
    }

    &__forms {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin-block-start: 1.5rem;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: 3fr 4fr;
        }
    }
}
