@page printable-a4 {
    size: A4;
    margin: 0;
    print-color-adjust: economy;
}

@page printable-3d-landscape {
    size: A3 landscape;
    margin: 0;
}

@media print {
    html {
        overflow: hidden;
    }

    body.has-printable-page {

        #app-root > * {
            display: none;
            background: none;
        }

        #app-root > *[data-printable="true"] {
            display: block;
        }
    }
}
