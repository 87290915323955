@import "src/@paco/styles";

$component: select-payroll-period;

.#{$component} {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;

    &--hide-label {
        height: remCalc(2.25);
        width: auto;
    }

    &--hide-label &__select {
        font-weight: var(--fw-medium);
    }

    &__col:first-child {
        min-width: 8rem;
        max-width: 30%;
    }

    .#{$component}__select-year,
    .#{$component}__select-period {
        width: 100%;
    }

    .#{$component}__select-period .select-overwrite__control {
        min-height: 2.675rem;
        border-color: var(--c-primary);
    }

    &__label {
        font-weight: var(--fw-medium);
    }
}
