@import "src/@paco/styles";

.periods-page {
    padding: var(--page-padding);

    &__top-bar {
        display: flex;
        justify-content: flex-end;
        margin-bottom: remCalc(1);
    }

    &__title {
        justify-self: flex-start;
        margin-right: auto;
        font-size: remCalc(1.25);
        font-weight: 600;
        color: var(--c-primary);
    }

    &__add-button {
        @extend %button--is-primary-outline;

        display: none;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__mobile-add-button {
        position: absolute;
        top: remCalc(1);
        right: remCalc(5.25);
        color: var(--c-primary);
        z-index: 1010;

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__help-tooltip {
        position: absolute;
        top: remCalc(6);
        left: remCalc(20);

        @include for-size(tablet-portrait-up) {
            top: unset;
            left: remCalc(27);
        }
    }
}
