@import "../../../../style/theme.scss";

$component: 'calendar-button-add';

.#{$component} {
  @include clear-button();
  display: flex;
  flex-grow: 99;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: remCalc(.875);
  opacity: 0;
  color: $primary;
  background-color: $border-grey;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }

  &__icon {
    width: remCalc(.5);
    height: remCalc(.5);
  }

  @media only screen and (max-width: $break-mobile) {
    display: none;
  }
}
