@import "src/@paco/styles";

.availability-action-bar {
    background: var(--c-background);

    @include for-size(tablet-portrait-up) {
        margin-top: remCalc(-1);
        padding-top: remCalc(1);
    }

    &--is-sticky {

        @include for-size(tablet-portrait-up) {
            position: sticky;
            top: var(--navbar-height);
            z-index: 10;
        }
    }

    &__navigation {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: remCalc(1);
        z-index: 140;

        @include for-size(tablet-landscape-up) {
            justify-content: space-between;
            flex-direction: row;
            padding-left: remCalc(1);
        }
    }

    &__calendar-toolbar {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            margin-right: remCalc(1);
        }
    }

    &__search-bar {
        margin-top: remCalc(1);
        margin-left: remCalc(1);

        @include for-size(tablet-portrait-up) {
            margin: 0;
        }
    }

    &__calendar-header-desktop {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__calendar-header-mobile {
        margin: 0 remCalc(1);
        width: calc(100% - #{remCalc(2)});

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }
}
