@import "src/@paco/styles";

.printable-leave-of-absence-page {
    @extend %printable-page;

    --leave-of-absences-overview-table-cell-width: #{remCalc(1.5625)};

    position: relative;
    display: flex;
    break-after: column;

    &__users-list {
        margin-top: calc(var(--leave-of-absences-overview-table-head-height) - 1px);
    }

    &__user-link {
        font-style: normal;
        text-decoration: none;
    }
}
