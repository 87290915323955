.decline-leave-of-absence-form {

    &__form-label {
        display: flex;
        justify-content: space-between;
    }

    &__reason-length {
        font-weight: var(--fw-medium);
        color: var(--c-cyan);

        &--has-warning {
            color: var(--c-red);
        }
    }
}
