@import "src/@paco/styles";

.printable-district-division-district {

    &__title {
        margin: 0;
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
    }

    &__plannings-list {
        @extend %reset-list;

        margin-top: remCalc(0.25);
    }
}
