@import "src/@paco/styles";

.knowledge-base-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: remCalc(3);
    background: var(--c-primary);

    &__title {
        text-align: center;
        font-size: remCalc(1.5);
        color: var(--c-white);
    }

    &__input-button-wrapper {
        display: flex;
        margin-top: remCalc(1);
        width: 100%;
        max-width: remCalc(32);
    }

    &__search-input {
        margin: 0;
        width: 100%;
    }

    &__search-input-input,
    &__search-button {
        border: 0;
        height: remCalc(3);
    }

    &__search-input-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: var(--c-primary);

        &::placeholder {
            color: var(--c-grey);
        }

        &:focus {
            box-shadow: none;
        }
    }

    &__search-button {
        @extend %button--is-primary;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
