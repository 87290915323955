@import "theme";

.navbar {
    display: flex;
    font-size: 1.2rem;
    padding: 0 0 0 30px;
    height: $navbar-height;
    justify-content: flex-start;
    overflow: visible;

    @media only screen and (max-width: $break-mobile) {
        padding-left: 65px;
    }

    &-button-expand {
        display: none;
        border: 0;
        position: absolute;
        padding: 0;
        color: $white;
        left: 22px;
        background: none;

        @media only screen and (max-width: $break-mobile) {
            display: block;
        }
    }

    &-title-mobile {
        display: none;
        margin-bottom: 0;
        color: $white;
        text-transform: capitalize;
        font-size: $h5-font-size;

        @media only screen and (max-width: $break-mobile) {
            display: block;
        }
    }

    &-brand {
        display: flex;
        align-items: center;
        margin-right: 40px;

        @media only screen and (max-width: $break-mobile) {
            display: none;
        }
    }

    &-nav-item-list {
        list-style: none;
        padding: 0;
    }

    &-brand {
        height: $navbar-height;
    }

    &-dev {
        margin-right: 20px;
        color: $white;
        opacity: 1 !important;
    }

    .nav-link {
        padding: 0.7rem 0;
    }

    .nav-item {
        position: relative;
    }

    .nav-item .nav-link {
        font-weight: 600;
        opacity: 0.65;

        &.active, &:hover {
            opacity: 1;
        }
    }

    .dropdown-menu-right {
        position: fixed;
        top: 63px;
        right: $main-padding;
    }

    &-scrollable {
        width: calc(100% - 105px);
    }

    &-mainmenu .navbar-nav:first-child {
        .nav-item {
            &:last-child {
                margin-right: 75px;
            }

            .nav-link {
                display: flex;
                line-height: 2;
                height: $navbar-height;
                padding-left: 22px;
                padding-right: 22px;

                &.active, &:hover {
                    background: #12556C;
                }
            }

            .indicator-bubble {
                position: relative;
                top: 0;
                right: 0;
                margin-left: 3px;
            }
        }
    }

    &-submenu {
        z-index: 101;
        height: $sub-navbar-height;
        padding-left: 0;

        @media only screen and (max-width: $break-mobile) {
            display: none;
        }

        .navbar-scrollable {
            width: 100%;
        }

        .navbar-scrollable-inner {
            padding-left: 30px;
        }

        .nav-item {
            margin-right: 40px;
            height: $sub-navbar-height;

            &:last-of-type {
                margin-right: $main-padding !important;
            }
        }

        .nav-item .nav-link {
            height: 100%;
            line-height: 2.5;
            padding: 0.45rem 0;
            font-size: 15px;
            color: $placeholder;

            &.active, &:hover {
                color: $primary;
            }
        }
    }

    &-account-circle {
        display: flex;
        align-items: center;
        margin-top: -2px;
    }

    &-username {
        margin-left: 8px;
    }

    &-version-number {
        margin-bottom: 4px;
        font-size: 12px;
        text-align: right;
        color: $grey;
    }

    .dropdown-item {
        line-height: 1.6;
    }
}

.no-touch .navbar-submenu .navbar-scrollable--overflow {
    .nav-link {
        line-height: 2.7;
    }

    .indicator-bubble {
        top: 8px;
    }

}