@import "src/@paco/styles";

.valk-table {
    @extend %table;

    &__header-row {
        border-top-color: transparent;
    }

    &__hidden-cell {
        @include column-width(0);

        border: 0;
    }

    &__hidden-cell-content {
        padding: 0;
    }

    &__redirect-button {
        @extend %click-extend;

        z-index: 10;
        background: none;
    }
}
