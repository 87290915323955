@import "../../../../style/theme";
@import "src/@paco/styles";

$component: complete-periods;

.#{$component} {
    padding: $main-padding;

    &__cards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        width: 100%;

        @media only screen and (max-width: $break-medium) {
            flex-direction: column;
        }
    }

    &__cards-left,
    &__cards-right {
        width: calc(50% - 0.9375rem);

        @media only screen and (max-width: $break-medium) {
            width: 100%;
        }
    }

    .#{$component}__card {
        margin-bottom: 1.875rem;
    }

    &__loader {
        position: absolute;
    }

    &__complete-periods-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(16);

        @media only screen and (max-width: $break-mobile) {
            top: remCalc(4.5);
            left: remCalc(14);
        }
    }

    &__choose-period-help-tooltip {
        position: absolute;
        top: remCalc(10);
        right: remCalc(10);

        @media only screen and (max-width: $break-mobile) {
            top: remCalc(7.5);
            right: unset;
            left: remCalc(10);
        }
    }

    &__check-open-tasks-help-tooltip {
        position: absolute;
        top: 20%;
        left: 45%;

        @include for-size(tablet-portrait-up) {
            top: unset;
            left: unset;
            transform: translate(remCalc(19), remCalc(-5));
        }
    }

    &__export-tracks-help-tooltip {
        position: absolute;
        top: 48%;
        left: 45%;

        @include for-size(tablet-portrait-up) {
            top: unset;
            left: unset;
            transform: translate(remCalc(18), remCalc(-5));
        }
    }

    &__complete-period-help-tooltip {
        position: absolute;
        top: 72%;
        left: 45%;

        @include for-size(tablet-portrait-up) {
            top: unset;
            left: unset;
            transform: translate(remCalc(18), remCalc(-5.5));
        }
    }

    &__synchronize-help-tooltip {
        position: absolute;
        transform: translate(remCalc(19), remCalc(-3));

        @include for-size(phone-only) {
            transform: none;
            bottom: remCalc(1);
            left: 45%;
        }
    }
}
