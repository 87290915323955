@import "src/@paco/styles";

.login-layout {
    --navbar-height: 0px;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    min-height: 100svh;
    background-color: var(--c-white);

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
    }

    &__branding,
    &__content {
        @extend %flex-align-center;

        min-height: 50vh;
        padding: remCalc(1.25);

        @include for-size(tablet-landscape-up) {
            width: 50%;
            min-height: unset;
        }
    }

    &__branding {
        background: var(--c-primary) url('../../../images/pattern.svg') no-repeat fixed left bottom;
        background-size: 50%;

        @include for-size(tablet-landscape-up) {
            min-height: remCalc(15.625);
        }
    }

    &__content {
        padding-block: remCalc(2);
    }
}
