@import "src/@paco/styles";

.printable-district-division-page {
    @extend %printable-page;

    position: relative;
    background: var(--c-white);

    &--is-sizer {
        position: absolute;
        visibility: hidden;
    }

    &__columns-container {
        display: flex;
        justify-content: space-between;
    }

    &__column {
        width: calc(calc(100% / 3) - calc(#{remCalc(4)} / 2));
    }
}
