@import "../../style/theme.scss";

.wiki {
    &-navigation {
        padding: $main-padding;
        padding-right: 11px;

        &-title {
            margin-bottom: 5px;
            font-weight: 600;
            color: $dark;
        }

        &-group {
            margin-bottom: 10px;
            padding-left: 10px;
        }
    }

    &-title {
        margin: 35px 0 20px;
        line-height: 1.25;
        font-size: 20px;
        font-weight: 600;
        color: $dark;

        &:first-child {
            margin-top: 0;
        }
    }
}