@import "src/@paco/styles";

.skeleton-table {
    border: 1px solid var(--c-border-grey);
    border-radius: var(--table-border-radius);
    background-color: var(--c-white);
    overflow: hidden;

    td:first-child {
        border-left: 0;
    }

    &__header-row {
        border-top-color: transparent;
    }
}
