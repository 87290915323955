@import 'src/@paco/styles';

$component: departments-list;

.#{$component} {

    .#{$component}__department-badge {
        padding-left: remCalc(.5);
        padding-right: remCalc(.5);
        margin-right: remCalc(.1875);
        margin-bottom: remCalc(.3125);
    }
}
