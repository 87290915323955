@import "src/@paco/styles";

.incomplete-user-row {
    position: relative;

    &__cell,
    &__contract-hours-cell {

        &--is-strong {
            font-weight: var(--fw-medium);
        }
    }

    &__contract-hours-cell {
        @include column-width(11rem);
    }

    &__button-cell {
        @include column-width(4rem);

        position: relative;
        margin-left: auto;

        &:last-of-type {
            margin-left: 0;
        }
    }

    &__button-cell-content {
        @extend %cell-content-button;
    }

    &__edit-button, &__check-button {
        @extend %cell-button;
    }

    &__check-button {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: remCalc(.125);
        background: var(--c-white);
        opacity: .75;

        &--is-checked {
            opacity: 1;
            background: var(--c-green-success);
        }
    }

    &__check-icon {
        width: remCalc(1.25);
        height: remCalc(1.25);
        min-width: remCalc(1.25);
        min-height: remCalc(1.25);
        color: var(--c-green-success);

        &--is-checked {
            color: var(--c-white);
        }

        &--is-disabled {
            color: var(--c-disabled-grey);
        }
    }
}
