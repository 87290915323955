@import "src/@paco/styles";

.district-division-template-detail-container-skeleton {

    &__top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: remCalc(2) remCalc(1) remCalc(1);
    }

    &__item {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        padding: remCalc(1);

        @include for-size(tablet-landscape-up) {
            width: 50%;
        }

        &-header {
            display: flex;
            border-top: 1px solid var(--c-disabled-grey);
            padding: remCalc(1) 0;
        }
    }

    &__group {
        margin: 0;
        font-weight: var(--fw-medium);
        font-size: remCalc(1.25);
        color: var(--c-primary);
    }

    &__action-buttons {
        display: flex;
        margin-left: auto;
    }

    &__title {
        width: remCalc(12);
        height: remCalc(2.25);
    }

    &__plus-button {
        width: remCalc(9);
        height: remCalc(2.25);
    }

    &__groupname {
        width: remCalc(14);
        height: remCalc(1.5);
    }

    &__icon-button {
        width: remCalc(1.5);
    }
}
