@import "../../style/theme.scss";

.comments-modal {

  &__reviewed-by-user {
    font-weight: 400;
    font-size: 1rem;
  }

  &__comment-user {
    font-weight: 600;
  }
}
