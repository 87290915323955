@import 'src/@paco/styles';

.concepts-calendar-overview {

  @include for-size(tablet-portrait-up) {
    padding: 0 var(--page-padding) var(--page-padding);
  }

  &__content {
    position: relative;
  }

  &__action-bar-wrapper {

    @include for-size(tablet-portrait-up) {
      margin-top: calc(#{var(--page-padding)} * -1);
    }

    &--is-sticky {

      @include for-size(tablet-portrait-up) {
        position: sticky;
        top: var(--navbar-height);
        z-index: 20;
      }
    }
  }

  &__add-shift-form {
    max-width: 40.625rem;
  }
}
