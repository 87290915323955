@import "src/@paco/styles";

.user-detail-basic-card {
    position: relative;
    color: var(--c-white);

    &__inner-wrapper,
    &__loket-wrapper {
        position: relative;
        z-index: 20;
        border: 1px solid transparent;
        border-radius: remCalc(.625);
        padding: remCalc(2.625) 11% remCalc(2) 10%;
        background: var(--c-primary);
        overflow: hidden;

        @include for-size(phone-only) {
            padding-right: 6%;
            padding-left: 9%;
        }
    }

    &__employee-number-and-history-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &__employee-number {
        justify-self: flex-start;
        margin-right: auto;
    }

    &__experience {
        @extend %flex-align-center;

        width: remCalc(1.875);
        height: remCalc(1.875);
        font-size: remCalc(1.125);
        background: var(--c-border-grey);
        color: var(--c-black);
    }

    &__experience:not(:last-child) {
        margin-right: remCalc(.75);
    }

    &__history-button {
        color: var(--c-orange);

        &:hover,
        &:focus {
            color: var(--c-white);
        }
        &-icon {
            @include icon-size(1.25);
        }
    }

    &__user-full-name {
        margin: remCalc(.5) 0 remCalc(.875);
        word-break: break-word;
        line-height: 1;
        font-size: remCalc(2.5);
        font-weight: var(--fw-medium);
        color: var(--c-white);
    }

    &__user-status {
        display: flex;
        align-items: center;
    }

    &__user-status-badge {
        display: inline;
        position: relative;
        border: 1px solid transparent;
        margin-left: remCalc(.5);
        padding: remCalc(.375);
        color: var(--c-white);
        font-weight: var(--fw-medium);
        line-height: 1.2;
        border-radius: remCalc(.125);

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--c-white);
            opacity: .1;
        }

        &--is-active {
            border-color: var(--c-green-success);
        }

        &--is-inactive {
            border-color: var(--c-orange);
        }

        &--is-deleted {
            border-color: var(--c-red);
        }

        &--is-invited {
            border-color: var(--c-secondary);
        }
    }

    &__action-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: remCalc(1);
        width: 100%;
    }

    &__action-button {
        @extend %button--reset;

        font-weight: var(--fw-regular);
        color: var(--c-white);

        &:hover,
        &:focus {
            text-decoration: underline;
            color: var(--c-white);
        }

        &-action-button-icon {
            min-width: remCalc(1.25);
            color: var(--c-white);
        }
    }

    &__loket-wrapper {
        position: relative;
        top: -.75rem;
        z-index: 10;
        padding-block: 5%;
        padding-block-start: calc(.75rem + 4%);
        border-color: var(--c-border-grey);
        color: var(--c-black);
        background-color: var(--c-light-grey);
    }

    &__loket-title {
        margin-bottom: 1rem;
        font-size: 1rem;
    }

    &__loket-skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__loket-description {
        margin-bottom: 0;
        color: var(--c-primary);

        & + & {
            margin-top: .5rem;
        }
    }
}
