@import "../../../../style/theme";

.loket-logs-card {

    &__logs {
        padding: 0;
    }

    &__null-state {
        position: static;
        padding-top: 2rem;
    }

    &__recent-synchronizations-help-tooltip {
        position: absolute;
        top: remCalc(9.5);
        right: remCalc(20);

        @media only screen and (max-width: $break-medium) {
            top: remCalc(19);
        }

        @media only screen and (max-width: $break-mobile) {
            top: remCalc(19);
            right: unset;
            left: remCalc(8);
        }
    }
}
