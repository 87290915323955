@import "src/@paco/styles";

.table-row {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: remCalc(3.75);
    border-top: 1px solid var(--c-border-grey);

    &--has-hover-state:hover {
        background-color: var(--c-lighter-blue);
    }
}
