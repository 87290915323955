@import "src/@paco/styles";

.add-district-division-group-template-form {
    @extend %form;

    &__row {
        @extend %form-row;
    }

    &__col,
    &__group-name-input,
    &__label {
        @extend %form-col;
    }

    &__group-name-input {
        flex: inherit;
        width: calc(60% - #{remCalc(2)});
    }

    &__input-labels {
        display: flex;
    }

    &__label {
        flex: inherit;

        &:nth-child(1) {
            width: calc(60% - #{remCalc(2)});
        }

        &:nth-child(2) {
            width: calc(40% - #{remCalc(2)});
        }
    }

    &__text-input {
        margin-bottom: 0;
        height: remCalc(2);
    }

    &__add-district-button {
        display: flex;

        &-label {
            margin-bottom: 0;
            margin-left: remCalc(.5);
        }

        &-icon {
            color: var(--c-green-success);
            min-width: remCalc(1);
        }
    }
}
