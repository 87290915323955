@import "src/@paco/styles";

.district-division-templates-filter-container {

    &__add-district-division-button {
        @extend %button--is-primary;

        width: 100%;
        line-height: 1.25;
        font-size: remCalc(.9375);
    }

    &__add-district-division-template-modal {
        max-width: remCalc(30);
    }
}
