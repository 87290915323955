@import "src/@paco/styles";

.multi-select {
    .select-overwrite {
        cursor: pointer;

        &--is-disabled {
            cursor: not-allowed;
            color: var(--c-disabled-grey);
            opacity: .8;
        }

        &__input {
            pointer-events: none;
        }

        &--has-error {
            border: 1px solid var(--c-warning);
        }

        &__control {
            min-height: unset;
            border: 1px solid var(--c-border-grey);
            border-radius: remCalc(.125);
            padding: remCalc(.125);

            &--is-focused {
                outline: none;
                border-color: var(--c-blue);
                box-shadow: 0 0 0 remCalc(.2) rgba(var(--c-primary-rgb), .25);
            }
        }

        &__value-container {
            padding: 0 remCalc(.5);
        }

        &__placeholder {
            color: var(--c-grey);
        }

        &__menu .departments-input__option:before {
            display: none;
        }

        &__menu-list {
            padding-top: 0;
            max-height: remCalc(15.625);
        }

        &__indicator {
            padding: remCalc(.375);
        }

        &__indicator-separator {
            display: none;
        }

        &__dropdown-indicator svg {
            width: remCalc(1);
            margin-right: remCalc(.25);
            fill: var(--c-primary);
        }

        &__clear-indicator svg {
            fill: var(--c-primary);
        }

        &__group {
            padding: 0;
        }

        &__group-heading {
            margin: 0;
            padding: 0;
        }

        &__option {
            color: var(--c-black);

            &--is-selected {
                color: var(--c-white);
                background-color: var(--c-primary);
            }

            &--is-disabled {
                color: var(--c-disabled-grey);
            }
        }

        &__multi-value {
            position: relative;
            flex-direction: row-reverse;
            margin: remCalc(.25);
            border-radius: remCalc(.25);
            background-color: var(--c-secondary);

            &__label {
                max-width: remCalc(11);
                padding: remCalc(.125) remCalc(.375) remCalc(.125) 0;
                font-size: remCalc(.75);
                font-weight: var(--fw-medium);
                color: var(--c-white);
            }

            &__remove {
                background: none;
                color: var(--c-white);
                z-index: 1;

                &:hover {
                    background: none;
                    color: var(--c-white);
                    cursor: pointer;
                }
            }
        }
    }
}
