@import "../../style/theme.scss";
@import "src/@paco/styles";

$component: users;

.#{$component} {
    &__add-user-help-tooltip {
        position: fixed;
        top: remCalc(2.5);
        right: remCalc(4.5);
        z-index: 1100; // Navigation is 1000
    }

    &__filters-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(-8);
    }

    &__user-overview-help-tooltip {
        position: absolute;
        top: remCalc(10);
        left: remCalc(6);
    }

    &__user-details-help-tooltip {
        position: absolute;
        top: remCalc(20);
        left: remCalc(8);
    }

    &__export-users-help-tooltip {
        position: absolute;
        right: remCalc(9);
        top: remCalc(2.5);
    }

    &__search-users-help-tooltip {
        position: absolute;
        right: remCalc(6);
        top: remCalc(6);
    }

    .#{$component}__row-button {
        width: calc(100% - 4rem);
    }

    @include for-size(tablet-portrait-up) {
        &__add-user-help-tooltip {
            position: absolute;
            top: remCalc(3);
            left: remCalc(-8);
            z-index: 980;
        }

        &__export-users-help-tooltip {
            right: remCalc(17);
            top: remCalc(3);
        }

        &__search-users-help-tooltip {
            right: remCalc(6);
            top: remCalc(3);
        }
    }

    @include for-size(tablet-landscape-up) {
        &__user-overview-help-tooltip {
            top: unset;
            left: remCalc(5);
        }

        &__user-details-help-tooltip {
            top: remCalc(13);
        }
    }

    &__add-user-button {
        @extend %button--is-primary;

        width: 100%;
    }
}

.users-list {
    &__td-employee-number {
        width: 5%;
    }

    &__td-first-name,
    &__td-last-name {
        width: 11%;
    }

    &__td-email {
        width: 15%;
    }

    &__td-phone {
        width: 10%;
    }

    &__td-experience {
        width: 5%;
    }

    &__td-departments {
        position: relative;
        z-index: 20;
        width: 40%;
    }
}

.users-list-top {
    display: flex;
    align-self: flex-end;
    margin-bottom: $main-padding;

    button {
        margin-right: 10px;
    }

    &-buttons {
        display: flex;
    }

    @media only screen and (max-width: $break-mobile) {
        margin-right: $main-padding;
        flex-direction: column;
        align-items: flex-end;

        .users-list-top-buttons {
            margin-bottom: 10px;
        }

        .calendar-button-export {
            margin-right: 0;
        }
    }
}

.management-table {

    &__firstname--is-company-medic,
    &__lastname--is-company-medic {
        color: var(--c-red);
    }
}
