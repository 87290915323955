@import "src/@paco/styles";

$component: grouped-entity-events;

.#{$component} {

    &--is-expanded {

        .#{$component}__list {
            display: block;
        }

        .#{$component}__toggle-button-icon {
            transform: rotate(180deg);
        }
    }

    &__toggle-button {
        margin-inline-start: remCalc(.875);
    }

    &__list {
        @extend %reset-list;

        display: none;
        margin-block-start: remCalc(.5);
    }

    &__list-item {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: remCalc(1.5);
            width: 1px;
            height: 100%;
            background: var(--c-cyan);
        }

        &:first-of-type:before {
            top: 50%;
            height: 50%;
        }

        &:last-of-type:before {
            height: 50%;
        }

        &:first-of-type:last-of-type:before {
            display: none;
        }
    }

    &__list-item + &__list-item {
        position: relative;

        &::after {
            @extend %click-extend;

            content: "";
            display: block;
            width: calc(100% - #{remCalc(4)});
            height: 1px;
            left: remCalc(4);
            top: 0;
            background-color: var(--c-border-grey);
        }
    }
}
