@import "src/@paco/styles";

:root {
    --leave-of-absences-overview-table-cell-width: #{remCalc(1.75)};
    --leave-of-absences-overview-table-head-height: #{remCalc(3.375)};
}

.leave-of-absences-overview-table {
    position: relative;
    width: fit-content;

    &--is-loading {
        min-height: calc(100svh - var(--navbar-height) - var(--leave-of-absences-overview-top-bar-height));
        overflow: hidden;

        @include for-size(tablet-portrait-up) {
            min-height: calc(100svh - var(--navbar-total-height) - var(--leave-of-absences-overview-top-bar-height));
        }
    }

    &__table-head--is-sticky {
        position: fixed;
        top: var(--navbar-height);
        left: remCalc(13.75);
        background: var(--c-background);
        z-index: 2;
    }

    &__table-head--is-hidden {
        visibility: hidden;
        pointer-events: none;
    }

    &__loading-spinner {
        position: absolute;
        top: #{var(--leave-of-absences-overview-table-head-height)};
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}
