@import '../helpers/functions';
@import './flex';

%calendar-item {
    @extend %button--reset;

    border: 1px solid var(--c-black);
    border-radius: var(--calendar-item-border-radius);
    width: 100%;
    min-height: remCalc(3);
    padding: 1px;
    line-height: 1;
    font-size: remCalc(.875);
    backface-visibility: hidden;
    overflow: hidden;

    & + & {
        margin-top: remCalc(.25);
    }
}

%calendar-item-main-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: remCalc(.25);
}
