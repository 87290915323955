@import "../../../../style/theme.scss";

.calendar-toolbar {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
    height: 2.5rem;
    overflow: visible;
    z-index: 101;
    flex-grow: 99;

    @media only screen and (max-width: $break-mobile) {
        align-items: inherit;
        border-bottom: 1px solid #E1E1E1;
        margin-bottom: 0;
        height: 3.25rem;
    }

    &__today-button {
        display: block;
        border: 0;
        height: auto;
        line-height: 1;
        padding: 0;
        font-weight: 600;
        color: $primary;
        background: none;

        &:disabled {
            opacity: 0.5;
        }

        &:hover {
            color: darken($primary, $button-hover-darken);
        }

        @media only screen and (max-width: $break-mobile) {
           display: none;
        }
    }

    &__dropdown {
        margin-left: 15px;
        width: 130px;
        height: 39px;
    }

    &__nav-buttons {
        margin-right: 1rem;
    }
}



.calendar-mode-buttons {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    margin-left: auto;
    padding-top: 1px;

    &__title {
        font-weight: 600;
    }

    @media only screen and (max-width: $break-mobile) {
        display: none;
    }
}

.calendar-mode-button {
    @include clear-button();
    font-size: 15px;
    color: $primary;

    &--active, &:hover {
        font-weight: 600;
    }
}

.calendar-mode-button-divider {
    margin: 0 5px;
}
