.material-icons.icon {
    display: block;

    &--inline {
        display: inline;
    }
}

.icon-img {
    height: auto;
}

.icon-svg {
    width: 1rem;

    circle,
    path,
    polygon,
    rect {
        fill: currentColor;
    }

    .stroke {
        fill: none;
        stroke: currentColor;
    }
}
