@import "src/@paco/styles";

.sidebar-paco {
    position: relative;
    display: block;
    width: 0;
    min-height: calc(100vh - #{var(--navbar-total-height)});
    transition: width .25s ease-out;
    background-color: var(--c-white);
    z-index: 100;

    @include for-size(tablet-portrait-up) {
        position: relative;
        width: remCalc(1.25);
        border-right: 1px solid var(--c-border-grey);
        transition: width .25s ease-out;

        &--is-opened {
            width: var(--sidebar-width);
        }

        &--is-opened + .page {
            width: calc(100% - var(--sidebar-width));
        }
    }

    &__toggle-button {
        position: absolute;
        display: none;
        right: remCalc(-.625);
        z-index: 210;

        @include for-size(tablet-portrait-up) {
            display: flex;
            top: remCalc(4);
        }

        @include for-size(tablet-landscape-up) {
            top: remCalc(2);
        }
    }

    &__mobile-toggle-button {
        position: fixed;
        bottom: remCalc(1.25);
        left: 50%;
        padding: remCalc(.75);
        color: var(--c-primary);
        border: 1px solid var(--c-primary);
        width: calc(100% - #{remCalc(1.25) * 2});
        background-color: var(--c-white);
        transform: translateX(-50%);
        z-index: 200;
        box-shadow: var(--box-shadow);
        transition: background-color .1s ease-out, color .1s ease-out;

        @include for-size(tablet-portrait-up) {
            display: none;
        }

        &:focus {
            color: var(--c-primary);
            border: 1px solid var(--c-primary);
        }

        &:active {
            color: var(--c-white);
            background-color: var(--c-primary);
            transition: background-color 0s ease-out, color 0s ease-out;
        }
    }

    &__content {
        position: fixed;
        top: var(--navbar-height);
        left: 0;
        min-height: 100%;
        height: 100%;
        width: 100%;
        background-color: var(--c-white);
        transform: translateX(-100%);
        transition: transform 0.15s ease-out;
        z-index: 200;
        padding-bottom: remCalc(3);

        &--is-opened {
            transform: translateX(0%);
            padding-bottom: remCalc(7);
            overflow-y: auto;
        }

        &--has-shifts-legend {
            padding-bottom: remCalc(7);
        }

        @include for-size(tablet-portrait-up) {
            position: relative;
            top: auto;
            left: auto;
            min-height: 0;
            width: remCalc(14); // can't get css vars to work with this mixin
            height: auto;
            float: right;
            background-color: transparent;
            transform: none;
            overflow-y: unset;

            &--is-opened {
                padding-bottom: remCalc(3);
            }

            &--has-shifts-legend {
                padding-bottom: remCalc(3);
            }
        }
    }

    &__shifts-legend {
        display: none;

        @include for-size(tablet-portrait-up) {
            transition: transform .25s ease-out;
            display: block;
            position: fixed;
            bottom: 0;
            left: 0;
            width: remCalc(13.5);
            height: remCalc(3.75);
            z-index: 1;
        }

        @media (max-height: 320px) {
            display: none;
        }
    }
}
