@import "../../../../style/theme";

$component: calendar-absence;

.#{$component} {
  @include clear-button();
  margin-bottom: 4px;
  text-align: left;
  width: 100%;
  min-height: 39px;
  padding: 1px;
  font-size: 14px;
  will-change: background;

  @media only screen and (max-width: $break-mobile) {
    margin-top: 0 !important;
    font-size: 16px;
  }

  &:focus {
    outline: 0;
  }

  &-inner {
    border: 2px solid transparent;
    border-radius: 3px;
    white-space: normal;
    padding: 6px 10px 4px;
    will-change: background;

    @media only screen and (max-width: 1150px) {
      padding: 6px;
    }

    @media only screen and (max-width: $break-mobile) {
      padding: 6px 10px;
    }

    > div {
      width: 39.5vw;
    }

    &--range-3 {
      > div {
        width: 27vw;
      }
    }

    &--range-1, &--range-2 {
      > div {
        width: 17vw;
        max-height: 36px;
        -webkit-line-clamp: 2;
      }
    }

    @media only screen and (max-width: $break-mobile) {
      white-space: nowrap;
    }

    > div {
      line-height: 1.25;
      margin-bottom: 4px;
      opacity: 0.01;

      @media only screen and (max-width: $break-mobile) {
        width: auto;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--visible {
      > div {
        opacity: 1;
      }
    }

    &--single {
      white-space: normal;
      overflow: hidden;

      > div {
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .#{$component}-date-desktop {
        display: block;

        @media only screen and (max-width: 1000px) {
          display: none;
        }

        @media only screen and (max-width: $break-mobile) {
          display: block;
        }
      }

      .#{$component}-date-mobile {
        display: none;

        @media only screen and (max-width: 1000px) {
          display: block;
        }

        @media only screen and (max-width: $break-mobile) {
          display: none;
        }
      }

    }

    &--right-fill {
      border-right-color: rgba(0, 0, 0, 0) !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: calc(100% + 10px);
    }

    &--full-fill {
      border-right-color: rgba(0, 0, 0, 0) !important;
      border-left-color: rgba(0, 0, 0, 0) !important;
      border-radius: 0;
      margin-left: -6px;
      width: calc(100% + 12px);
    }

    &--left-fill {
      border-left-color: rgba(0, 0, 0, 0) !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -6px;
      width: calc(100% + 6px);
    }

    &--right-fill, &--full-fill {
      position: relative;

      &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        right: -4px;
        width: 4px;
        height: 2px;
      }

      &:after {
        top: -2px;
      }

      &:before {
        bottom: -2px;
      }
    }
  }

  &-name {
    font-weight: 600;
  }

  &-icon {
    display: inline;
    margin: -2px 7px 0 0;
  }

  &-comment, &-date-mobile, &-date-desktop {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    max-height: 18px;
    overflow: hidden;
  }

  &-date-mobile {
    display: none;
  }

  .#{$component}-inner {
    overflow: hidden;

    &--visible {
      overflow: visible;
    }
  }

  &--open .#{$component}-inner {
    position: relative;
    border-color: $blue;
    color: $white;
    background: $blue;

    &:after, &:before {
      background: $blue;
    }
  }

  &--approved .#{$component}-inner {
    background: $primary;
    color: $white;
  }

  &--past .#{$component}-inner {
    border-color: $medium-grey;
    color: $white;
    background: $medium-grey;

    .#{$component}-icon {
      opacity: 1;
    }
  }

  &--denied .#{$component}-inner {
    border-color: $grey-disabled;
    color: $grey-disabled;
    background: $white;

    .#{$component}-comment {
      font-weight: 600;
    }

    .#{$component}-icon {
      opacity: 0.25;
    }

    &:after, &:before {
      background: $grey-disabled;
    }
  }
}

.#{$component}--active.#{$component}--approved .#{$component}-inner {
  background: darken($primary, $button-hover-darken);
}

.#{$component}--active.#{$component}--open .#{$component}-inner {
  border-color: darken($blue, $button-hover-darken);
  background: darken($blue, $button-hover-darken);

  &:after, &:before {
    background: darken($blue, $button-hover-darken);
  }
}

.#{$component}--active.#{$component}--past .#{$component}-inner {
  border-color: darken($medium-grey, $button-hover-darken);
  background: darken($medium-grey, $button-hover-darken);

  &:after, &:before {
    background: darken($medium-grey, $button-hover-darken);
  }
}

.#{$component}--active.#{$component}--denied .#{$component}-inner {
  border-color: darken($grey-disabled, $button-hover-darken);
  color: darken($grey-disabled, $button-hover-darken);
  background: darken($white, $button-hover-darken);

  .#{$component}-icon {
    opacity: 0.5;
  }

  &:after, &:before {
    background: darken($grey-disabled, $button-hover-darken);
  }
}

.#{$component}--focus.#{$component}--open .#{$component}-inner {
  animation: focusOpen 3s;
}

.#{$component}--focus.#{$component}--approved .#{$component}-inner {
  animation: focusApproved 3s;
}

.#{$component}--focus.#{$component}--denied .#{$component}-inner {
  animation: focusDenied 3s;
}

.#{$component}s-list {
  position: relative;
  padding: 4px 4px 10px;

  @media only screen and (max-width: $break-mobile) {
    padding: 0;
  }
}

@keyframes focusOpen {
  30% {
    background-color: lighten($primary, $button-hover-darken);
  }
  100% {
    background-color: $blue;
  }
}

@keyframes focusApproved {
  30% {
    background-color: darken($primary, $button-hover-darken);
  }
  100% {
    background-color: $primary;
  }
}

@keyframes focusDenied {
  30% {
    background-color: darken($white, $button-hover-darken);
    color: lighten($black, $button-hover-darken);
  }
  100% {
    background-color: $white;
  }
}
