@import 'theme';

.toast {
  display: flex;
  position: relative;
  flex-basis: auto;
  width: 355px;
  min-height: 50px;
  color: $dark;

  &-header {
    min-width: 74px;
    background: $secondary;

    &--fail {
      background: $red;
    }

    &--pass {
      background: $success;
    }

    &--warning {
      background: $orange;
    }

    strong {
      width: 100%;
      text-align: center;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &-body {
    padding: 12px 44px 15px 12px;
    line-height: 1.25;
    font-family: $font-family;
    font-size: 16px;
    color: $dark;
  }

  &-button-close {
    @include clear-button();
    position: absolute;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    right: $main-padding;
    background: $white;

    img {
      width: 10px;
      height: 10px;
    }
  }
}