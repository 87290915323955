@import "../../../../../../style/theme";

$component: calendar-user-shift;

.#{$component} {
  display: block;
  text-decoration: none !important;

  &.#{$component}--unplanned {
    color: $green;
    text-decoration: none;
    background: $white;
    border: 1px solid $green;

    .calendar-shift-inner {
      padding: 2px 4px 3px;
    }

    &:hover, &:focus {
      color: $white;
      background: darken($green, 10%) !important;
      border-color: darken($green, 10%) !important;
      outline: 0;
    }
  }

  &.#{$component}--planned {
    color: $white;
    background: $green;
    border: 0;

    &:hover, &:focus {
      background: darken($green, 10%) !important;
      outline: 0;
    }
  }

  .calendar-shift-department {
    display: flex;
    line-height: 1.25;
  }

  &__shift-track-time {
    margin-bottom: remCalc(.125);
  }
}
