@import "../../../style/theme.scss";

.cao-badge {
  align-items: center;
  margin: 0 0.25rem;
  padding: 0.25rem !important;
  cursor: pointer;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    color: $white;
    font-weight: 600;
    background: $orange-light;
    border-radius: 50%;
  }
}