.complete-period-detail-incomplete-leave-of-absences-container {

    &__table {
        margin-top: 1rem;
    }

    &__loader {
        position: relative;
        top: 0;
        height: 20vh;
        margin-top: 1rem;
    }
}
