@import "../../style/theme.scss";
@import "src/@paco/styles";

$custom-break-mobile: 500px;

$component: 'planned-user-card';

.#{$component} {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid $border-grey;
  border-top: 0;
  border-radius: 0.125rem;
  padding: 1rem;
  min-height: 3.75rem;

  @media only screen and (max-width: $custom-break-mobile) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  &:first-of-type {
    border-top: 1px solid $border-grey;
  }

  &__info, &__info-row {
    display: flex;
  }

  &__info {
    flex-direction: column;
  }

  &__user-name {
    margin-right: remCalc(.5);
    font-size: remCalc(.875);
    font-weight: 600;
  }

  &__time {
    margin-top: 0.25rem;
    line-height: 1;
    font-size: 0.9375rem;
  }

  &__comment {
    margin-top: 0.5rem;
    font-size: 0.9375rem;
  }

  &__color-picker, &__tooltip {
    margin-right: 0.5rem;
  }

  &__tooltip, &__level {
    margin-right: 0.5rem;
  }

  &__buttons-and-labels {
    display: flex;
    justify-self: flex-end;
    margin-left: auto;
  }

  &__button-edit, &__button-delete {
    @include clear-button();

    padding: 0.25rem;
  }

  &__button-add {
    @extend %button--is-primary;

    margin-inline-end: remCalc(.5);
    padding: remCalc(.25) remCalc(.75);
    font-size: remCalc(.875);
  }

  &__button-add-icon {
    @include icon-size(.675);
  }
}
