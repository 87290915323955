@import "src/@paco/styles";

.paco-modal__card {
    max-width: remCalc(50);
}

.edit-user-form {
    @extend %form;

    &__row {
        @extend %form-row;
    }

    &__small-col, &__col {
        @extend %form-col;
    }

    &__col {
        flex: 2;
    }

    &__department-input {
        position: relative;
        z-index: 10;
    }

    &__department-input-select--is-deleted {
        color: var(--c-disabled-grey);
    }

    &__role-description {
        display: flex;
        align-items: center;
        margin-top: remCalc(.625);
    }

    &__info-circle-icon {
        margin-right: remCalc(.5);
        min-width: remCalc(1.25);
        height: remCalc(1.25);
        fill: var(--c-cyan);
    }
}
