@import "../../../style/theme.scss";

$component: form-edit-track;

.#{$component} {
  &__modal-header {
    overflow: hidden;

    .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__warning {
    margin-top: 1rem;
  }

  &__modal-footer {

    @media only screen and (max-width: $break-mobile) {
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
    }
  }

  .#{$component}__button-cancel, .#{$component}__button-submit {

    @media only screen and (max-width: $break-mobile) {
      margin-right: 0;
    }
  }

  .#{$component}__button-submit {

    @media only screen and (max-width: 25rem) {
      width: 9.375rem;
      height: auto;
    }
  }
}
