@import "../../style/theme";
@import "src/@paco/styles";

.modal-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  overflow: hidden;

  &--is-loading {
    padding-right: 5rem;
  }

  &__loader {
    position: absolute;
    right: -2.75rem;
    left: inherit;
    top: -3rem;
    width: 10rem;
    height: 10rem;
    background: none;
  }

  &__history-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    color: var(--c-orange);

    &:hover,
    &:focus {
      color: var(--c-primary);
    }
  }

  &__history-button-icon {
    @include icon-size(1.25);
  }
}
