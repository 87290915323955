@import "src/@paco/styles";

.entity-event-logs-table {
    position: relative;

    &--is-empty &__loader {
        position: relative;
        height: 20vh;
        margin-top: 1rem;
    }

    &__loader {
        top: 0;
        height: 100%;
    }

    &__table-cell:nth-child(1) {

        @include for-size(tablet-portrait-up) {
            min-width: remCalc(20);
        }

        @include for-size(desktop-up) {
            min-width: remCalc(35);
        }
    }

    &__table-cell:nth-child(2) {
        justify-self: flex-end;
        margin-left: auto;
    }

    &__table-cell:nth-child(3) {
        position: relative;
        justify-self: flex-end;
        margin-left: auto;
        min-width: inherit;
        max-width: remCalc(3.75);
    }
}
