@import "src/@paco/styles";

.mobile-nav-section {
    width: 100%;
    background: var(--c-white);

    &--is-active &__sub-routes {
        display: block;
    }

    &__stand-alone-nav-link {
        @extend %text-ellipsis;

        height: remCalc(3.5);
        line-height: 1;
        padding: remCalc(1);
        font-size: remCalc(1);
        background: var(--c-lighter-grey);
    }

    &__stand-alone-nav-link-label {
        color: var(--c-primary);
    }

    &__sub-routes {
        display: none;
    }
}
