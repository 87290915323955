@import 'src/@paco/styles';

.calendar-header {
  display: flex;
  box-sizing: content-box;
  width: 100%;
  background: var(--c-background);

  &--is-mobile &__day {
    width: 100%;
  }

  &__day {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--c-border-grey);
    border-right: 0;
    width: calc(100% / 7);

    &:last-child {
      border-right: 1px solid var(--c-border-grey);
    }

    &--is-out-of-range {
      opacity: .5;
    }
  }

  &__day-header {
    @extend %flex-align-center;

    width: 100%;
    height: remCalc(2.5);
    font-weight: var(--fw-medium);
    text-align: center;
    background-color: var(--c-table-bg);
  }
}
