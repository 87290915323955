@import "../../../../style/theme.scss";

.user-navigation-container {
  position: relative;
}

.user-navigation-inner {
  position: relative;
  z-index: 2;

  @media only screen and (max-width: $break-mobile) {
    position: relative;
    margin-left: -$main-padding;
    width: calc(100% + #{$main-padding * 2});
    padding-top: $main-padding;
    padding-left: $main-padding;
    padding-bottom: 5px;
    overflow-x: scroll;
  }
}

.user-navigation {
  display: flex;
  width: max-content;
  padding-right: $main-padding;

  @media only screen and (max-width: 1000px) {
    min-width: 150px;
    padding-right: 10px;
  }

  &-link {
    @include clear-button();
    display: block;
    position: relative;
    border: 1px solid transparent;
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 20px;
    line-height: 2;
    color: $medium-grey;

    @media only screen and (max-width: 1050px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 850px) {
      font-size: 13px;
      padding: 10px 12px;
    }

    @media only screen and (max-width: $break-mobile) {
      margin-right: 10px;
      padding: 5px 10px;
      font-size: 15px;
    }

    &--active {
      border-color: $border-grey;
      font-weight: 600;
      color: $dark;
      background: $white;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        left: -1px;
        height: 3px;
        width: calc(100% + 2px);
        background: $white;

        @media only screen and (max-width: $break-mobile) {
          display: none;
        }
      }

      &:first-child:after {
        left: 0;
        width: calc(100% + 1px);
      }

      @media only screen and (max-width: $break-mobile) {
        border-color: transparent;
        background: none;
      }
    }

    &:hover {
      color: $dark;
    }
  }

  &-arrow {
    display: none;

    @media only screen and (max-width: $break-mobile) {
      transition: opacity 0.25s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 25px;
      right: #{-$main-padding};
      width: 50px;
      height: 30px;
      background: linear-gradient(to right, rgba(241,241,241,0) 1%,rgba(241,241,241,0.49) 14%,rgba(241,241,241,0.9) 25%,rgba(241,241,241,0.9) 50%,rgba(241,241,241,1) 100%);
      pointer-events: none;
      z-index: 5;

      img {
        width: 13px;
      }

      &--hidden {
        opacity: 0;
      }
    }
  }
}
