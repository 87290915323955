@import "src/@paco/styles";

.district-division-district-planning {
    display: flex;
    height: remCalc(2.5);
    overflow: hidden;

    &--disable-add-button &__add-employee {
        pointer-events: none;
    }

    &--is-dragging-planning &__add-employee {
        justify-content: center;
        border: 2px dashed var(--c-green);
        background: var(--c-green-light);
        opacity: .8;
    }

    &--is-dragging-planning &__plus-icon {
        margin-right: remCalc(0);
        color: var(--c-green);;
    }

    &--is-active &__add-employee {
        color: var(--c-light-grey);
        background: var(--c-white);
        opacity: 1;
    }

    &__index {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: remCalc(.5);
        border: 1px solid var(--c-cyan);
        border-radius: 2px;
        min-width: remCalc(2.5);
        color: var(--c-primary);
    }

    &__planning,
    &__add-employee {
        height: 100%;
        flex-grow: 1;
    }

    &__add-employee {
        display: flex;
        align-items: center;
        border: 2px solid transparent;
        padding: 0 remCalc(1);
        font-weight: var(--fw-medium);
        background: var(--c-light-grey);
        color: var(--c-cyan);
        opacity: .6;
    }

    &__plus-icon {
        margin-right: remCalc(.5);
        pointer-events: none;
    }
}
