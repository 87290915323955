@import "src/@paco/styles";

.district-division-select {
    justify-content: center;

    &__select {
        margin-top: remCalc(2);
        min-width: remCalc(14);
    }
}
