@import "src/@paco/styles";

.user-menu-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__link {
        line-height: 1.5;
        padding: 0;
        font-size: remCalc(1);
        font-weight: var(--fw-regular);
        color: var(--c-primary);

        &:hover {
            text-decoration: underline;
            color: var(--c-black);
        }
    }
}
