@import "src/@paco/styles";

.personal-info-input {
    &__row {
        display: inline;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__col,
    &__col-small {
        @extend %form-col;

        flex: 2;
    }

    &__col-small {
        flex: 1;
    }

    &__required-label {
        color: var(--c-cyan);
    }
}
