@import "src/@paco/styles";

.add-user-button {
    @extend %button--reset;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid var(--c-border-grey);
    width: 100%;
    height: remCalc(2.5);
    padding: 0 remCalc(.25) 0 remCalc(1.25);
    color: var(--c-primary);
    cursor: pointer;
    background: var(--c-white);

    &:hover,
    &--is-active {
        border-color: var(--c-primary);
    }

    &__label {
        display: flex;
        justify-self: flex-start;
        font-size: remCalc(1);
        margin: 0;
        margin-right: auto;
        cursor: pointer;
    }

    &__divider {
        width: 1px;
        height: remCalc(2);
        background: var(--c-border-grey);
    }

    &__icon-wrapper {
        @extend %flex-align-center;

        margin-left: remCalc(.25);
        padding: remCalc(.25);
    }

    &__icon {
        min-width: remCalc(1.25);
        min-height: remCalc(1.25);
        color: var(--c-green-success);
    }
}
