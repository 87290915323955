@import "src/@paco/styles";

.widget-button {
    @extend %widget-button;

    &__label {
        justify-self: flex-start;
        margin-right: auto;
        width: calc(100% - #{remCalc(2)});
    }

    &__divider {
        width: 1px;
        height: remCalc(2);
        background: var(--c-border-grey);
    }

    &__icon-wrapper {
        margin-left: remCalc(.25);
        padding: remCalc(.25);
    }

    &__icon {
        min-width: 1.25rem;
        min-height: 1.25rem;
        color: var(--c-green-success);
    }
}
