@import "theme";

.dropdown {

  > .nav-link {
    display: flex;
    align-items: center;
    opacity: 1 !important;
  }

  .material-icons.icon {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: -5px;
    font-size: 25px;
  }

}

.dropdown-menu {
  right: 2px;
  margin-top: -8px;
  padding: 10px 20px;
  box-shadow: 0 4px 6px 0 rgba(172,172,172,0.11);

  .dropdown-item {
    padding: 0;
    text-align: right;
    font-size: 15px;
    color: $primary;
    background: none;

    &:hover {
      color: darken($primary, $button-hover-darken);
    }
  }
}