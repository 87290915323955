@import 'src/@paco/styles';

$component: date-range-input;

.#{$component} {
    position: relative;
    width: remCalc(9.5);
    height: remCalc(2);

    &__input, &__label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
    }

    &__input {
        padding: 0 remCalc(.5);
        cursor: pointer;
        border: none !important;
        box-shadow: none !important;
        // Input needs to be hidden but still clickable. If opacity = 0 then some browsers won't make it clickable.
        opacity: 0.01;

        &::-webkit-calendar-picker-indicator {
            width: 100%;
            cursor: pointer;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: var(--fw-medium);
        color: var(--c-primary);
        pointer-events: none;
        white-space: nowrap;
    }

    &__label-caret {
        margin-left: remCalc(.5);
        width: remCalc(.875);
    }

    &__input:hover + .#{$component}__label {
        color: var(--c-dark);
    }
}
