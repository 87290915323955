@import '../helpers/functions';

%input {
    width: 100%;
    height: remCalc(2);
    margin: 0;
    padding: remCalc(.25) remCalc(.5);
    font: inherit;
    font-weight: var(--fw-regular);
    color: var(--c-black);
    border-radius: var(--input-border-radius);
    border: 1px solid var(--c-border-grey);
    background-color: var(--c-white);

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
        background: var(--c-light-grey);
    }

    &:focus {
        outline: none;
        border-color: var(--c-blue);
        box-shadow: 0 0 0 remCalc(.2) rgba(var(--c-primary-rgb), .25);
    }
}
