@import "./material.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "./theme.scss";
@import "~react-day-picker/lib/style.css";
@import "~react-datepicker/dist/react-datepicker.css";

@import "modal";
@import "buttons";
@import "forms";
@import "tooltip";
@import "calendar";

html {
    font-size: 15px;
}

body {
    font-weight: 400;
    font-family: $font-family;
    background-color: $background;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    @import "nav-bar";
    @import "form-check";
    @import "buttons";
    @import "dropdown";
    @import "table";
    @import "datepicker";
    @import "date-controls";
    @import "full-height-page";
    @import "badge";
    @import "toast";
    @import "card";
    @import "switch";
    @import "day";
    @import "react-select";
    @import "react-datepicker";
    @import "animations";

    position: relative;
    min-width: $site-min-width;
    overflow-x: hidden;

    .vh-100 {
        height: 100vh;
    }

    .mvh-100 {
        min-height: 100vh;
    }

    .pagination .page-item .page-link {
        border-radius: 0;
    }

    .dark-background {
        background: $dark repeat;
        -webkit-background-size: cover;
        background-size: cover;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .font-weight {

        &-semi-bold {
            font-weight: 600;
        }
    }
}

.hidden {
    display: none !important;
}

.bold {
    font-weight: 600;
}
