@import '../../../../style/theme';

$component: edit-shift-planning-form;

.#{$component} {

  &__row-comment {
    display: none;
  }

  &--show-comment .#{$component}__row-comment {
    display: block;
  }
}
