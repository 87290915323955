@import 'src/@paco/styles';

.paco-calendar-shift-employee {
    @extend %reset-list-item;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: remCalc(1);
    font-size: remCalc(.8125);
    overflow: hidden;

    &--is-previously-planned-user {
        text-decoration: line-through;
    }

    &--is-company-medic &__name {
        color: var(--c-red);
    }

    &__employment-type {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: remCalc(1);
        height: remCalc(1);
        margin: 1px remCalc(.25) 0 0;
        padding: remCalc(.25);
        font-weight: var(--fw-bold);
        line-height: 1;
        background: var(--c-white);
        border-radius: 1px;
    }

    &__color {
        border: 1px solid var(--c-white);
        border-radius: 50%;
        margin-right: remCalc(.25);
        min-width: remCalc(.5);
        height: remCalc(.5);
        aspect-ratio: 1;
        background-color: var(--c-calender-shift-employee);
    }

    &__name {
        @extend %text-ellipsis;

        justify-self: flex-start;
        margin-right: auto;
        line-height: 1.2;
    }

    &__time {
        margin-left: remCalc(.375);
        white-space: nowrap;
        text-align: right;
        text-overflow: ellipsis;
        overflow: hidden;

        &:not(:last-child) {
            margin-right: remCalc(.125);
        }
    }
}
