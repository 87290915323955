.settings-shift-time {
    display: flex;
    position: relative;

    &__input {
        width: 50%;

        &:nth-child(2) {
            margin-left: 7.5%;
        }
    }
}
