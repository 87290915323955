@import "src/@paco/styles";

.modal-content {
    border: none;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: remCalc(1) remCalc(2);
        border-bottom: 1px solid var(--c-border-grey);
    }

    &__title,
    &__sub-text {
        @extend %modal-title;

        margin-bottom: 0;
    }

    &__close-button,
    &__history-button {
        color: var(--c-orange);

        &:hover,
        &:focus {
            color: var(--c-black);
            text-decoration: underline;
        }
    }

    &__history-button-icon {
        @include icon-size(1.25);
    }
}
