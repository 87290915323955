@import "src/@paco/styles";

.shifts-widget-header {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: remCalc(2.5);
    }

    &__title,
    &__scroll-indicator-and-departments-wrapper {
        display: flex;
        flex-direction: column;
        font-size: remCalc(1);
        color: var(--c-grey);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__title {
        margin: 0;
        text-transform: uppercase;
        opacity: .5;
    }

    &__scroll-indicator-and-departments-wrapper {
        position: relative;
        min-width: 100%;

        @include for-size(tablet-portrait-up) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            min-width: inherit;
            width: 65%;
            padding-left: remCalc(1);
        }
    }

    &__scroll-indicator {
        display: flex;
        position: relative;
        order: 2;
        margin-top: remCalc(.5);

        @include for-size(tablet-portrait-up) {
            justify-content: flex-end;
            margin-top: 0;
            order: inherit;
            overflow: hidden;
        }
    }

    &__scroll-indicator-text {
        @extend %text-ellipsis;

        margin: 0;
    }

    &__scroll-indicator-icon {
        margin-left: remCalc(.5);
        min-width: remCalc(1.5);
        min-height: remCalc(1.5);
        opacity: .75;
    }

    &__select-departments-wrapper {
        position: relative;
        order: 1;
        margin-top: remCalc(.5);

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: remCalc(1);
            order: inherit;
        }
    }

    &__select-departments {
        min-width: remCalc(20);
        font-size: remCalc(.875);

        .select-overwrite {
            &__option {
                color: var(--c-primary);

                &--is-selected {
                    color: var(--c-disabled-grey);
                }
            }

            &__multi-value__label {
                color: var(--c-primary);
            }

            &__group-heading {
                padding-top: remCalc(.25);
            }

            &__multi-value {
                background-color: unset;
            }

            &__multi-value__remove {
                display: none;
            }
        }
    }
}
