@import "../../style/theme.scss";

.login {

    &-title {
        font-size: 20px;
    }

    &-row {
        margin: 0;
    }

    &-submit {
        margin-top: 40px;
    }

    &-form {
        margin-top: 45px;

        .form-check-label {
            line-height: 1.5;
        }

        .form-check-input {
            margin-top: 6px;
        }
    }

    &-container-form {
        width: remCalc(25);
    }

    &-checkbox-remember {
        margin-right: 10px;
        line-height: 1.35;
    }

    &-button-forgot {
        @include clear-button();
        color: $light-blue;

        &:hover {
            color: darken($light-blue, $button-hover-darken);
        }
    }

    &-button-back {
        margin-left: 20px;
    }

    &-paragraph-success, &-paragraph-fail {
        margin-bottom: 0;
        line-height: 2.4;
        color: $green-success;
    }

    &-paragraph-fail {
        margin-right: 10px;
        color: $red;
    }

    &-toast-error {
        position: absolute;
        bottom: $main-padding;
        right: $main-padding;
        z-index: 2;
    }

    &-toast-error-header {
        color: $white;
    }

    &--invalid-token {
        .login-cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            background: $white;
            z-index: 1;
        }
    }

    .reset-password-form-requirements {
        display: block !important;
        color: $black;
    }

    .is-invalid + .reset-password-form-requirements {
        color: $red;
    }

}


.login-spinner {
    display: inline-block;
    width: 64px;
    height: 64px;

    &:after {
        content: " ";
        display: block;
        width: 46px;
        height: 46px;
        margin: 1px;
        border-radius: 50%;
        border: 5px solid $primary;
        border-color: $primary transparent $primary transparent;
        animation: login-spinner-animation 1.2s linear infinite;
    }
    @keyframes login-spinner-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
