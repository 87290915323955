@import "src/@paco/styles";

.delete-shift-planning-form {
    @extend %form;

    &__row {
        @extend %form-row;

        flex-direction: column;
    }

    &__intro {
        @extend %form-col;
    }
}
