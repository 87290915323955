@import "src/@paco/styles";

.select-user-from-loket-form {
    @extend %form;
    font-size: remCalc(1);

    &__row {
        @extend %form-row;
    }

    &__col {
        @extend %form-col;

        width: 100%;
    }

    &__form-button-wrapper {
        @extend %form-col;

        display: flex;
        justify-content: flex-end;
        margin-top: remCalc(1);
    }

    &__cancel-button {
        &:hover {
            text-decoration: underline;
        }
    }

    &__input-label {
        margin-top: remCalc(1);
    }

    &__list {
        @extend %reset-list;
    }

    &__list-item {

        & + & {
            margin-top: remCalc(.625);
        }
    }

    &__loading-spinner-wrapper {
        @extend %flex-align-center;

        width: 100%;
        height: remCalc(5);
    }

}
