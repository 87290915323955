@import "../../../../style/theme";

$component: synchronizations;

.#{$component} {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  min-height: 18rem;
  padding: $main-padding;

  @media only screen and (max-width: $break-medium) {
    flex-direction: column;
  }

  .#{$component}__card {
    width: calc(100% / 3);
    margin-right: 1.25rem;

    @media only screen and (max-width: $break-medium) {
      width: 100%;

      &-logs {
        margin-top: 1.25rem;
      }
    }
  }
}
