@import "../../style/theme.scss";

.track-cards {

  &__title {
    border-bottom: 1px solid $border-grey;
    margin-bottom: $main-padding;
    padding-bottom: .5rem;
  }

  &__container {
    margin: 0 auto;
    width: 100%;
  }

  &__col {
    display: flex;
    align-items: stretch;

    @media (min-width: 1200px) {
      &:nth-child(even) {
        padding-left: #{$main-padding * .5};
      }

      &:nth-child(odd) {
        padding-right: #{$main-padding * .5};
      }
    }
  }
}
