@import "src/@paco/styles";

.dashboard-welcome-card {
    position: relative;
    padding: remCalc(1.875) remCalc(1.5);
    background: var(--c-primary);

    &__title {
        @extend %page-title;

        color: var(--c-white);
        white-space: pre-line;
    }

    &__skeleton {
        width: remCalc(15);
        height: remCalc(1.5);
        background: var(--c-white);
    }

    &__image {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: remCalc(2.625);
    }
}
