@import "src/@paco/styles";

.dashboard-small-widget-skeleton {

    &__content,
    &__titles-wrapper {
        width: 100%;
    }

    &__title-skeleton + &__sub-title-skeleton {
        margin-top: remCalc(.5);
    }

    &__sub-title-skeleton {
        width: 50%;

        &--variation-2 {
            width: 85%;
        }
    }

    &__button {
        opacity: 0;
    }
}
