@import "../../style/theme.scss";

$custom-break-mobile: 500px;

$component: 'planned-user-card';

.#{$component} {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid $border-grey;
  border-top: 0;
  border-radius: 2px;
  padding: 15px;
  min-height: 59px;

  @media only screen and (max-width: $custom-break-mobile) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  &:first-child {
    border-top: 1px solid $border-grey;
  }

  &-first-row {
    display: flex;
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--unplanned {
    opacity: 0.5;

    .planned-user-card-name {
      text-decoration: line-through;
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--is-company-medic &__name {
    color: var(--c-red);
  }

  &-button-name {
    @include clear-button();
    margin-right: 5px;

    &:hover {
      text-decoration: underline;
    }
  }

  &-button-absence {
    @include clear-button();
    height: 28px;
  }

  &-remarks {
    line-height: 1.28;
    font-size: 14px;
  }

  &-time {
    margin-top:-7px;
    margin-bottom: 5px;
    font-size: 14px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .#{$component}__employment-type {
    color: $white;
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 1;
    background: $primary;
    border-radius: 0.125rem;
    cursor: pointer;
  }

  &-hours {
    font-size: 14px;
    font-weight: 600;
    color: $dark;
  }

  &-buttons-and-label {
    display: flex;
    justify-content: flex-end;
    align-self: flex-start;
    min-height: 28px;

    @media only screen and (max-width: $custom-break-mobile) {
      margin-bottom: 10px;
      align-self: flex-end;
    }

    > * {
      margin-left: 12px;
    }
  }

  &-button {
    @include clear-button();

    &:focus {
      outline: none;
    }
  }

  &-button-add {
    @include clear-button();
    padding: 7px 10px;
    line-height: 1;
    font-size: 14px;
    background: $orange;
    color: $white;

    &:hover {
      background: darken($orange, $button-hover-darken);
    }
  }

  .badge-open {
    opacity: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    animation: fadeIn 0.25s ease-out;
    animation-fill-mode: forwards;
  }
}
