@import "../../../../../../style/theme.scss";

$component: 'shift-buttons-list';

.#{$component} {
  display: flex;
  flex-direction: column;

  &__loading-spinner {
    top: 3rem;
  }

  &--loading {
    min-height: 4.5rem;
  }
}
