@import "src/@paco/styles";

.printable-district-division-comment-block-item {
    margin-top: remCalc(1);
    border-top: 2px solid var(--c-primary);
    padding-top: remCalc(1);

    &__wrapper {
        border: 1px solid var(--c-border-grey);
        border-radius: 5px;
        padding: remCalc(1);
    }

    &__title {
        margin: 0;
        font-size: remCalc(1.25);
        font-weight: var(--fw-medium);
    }

    &__body {
        margin: remCalc(.5) 0 0;
        color: var(--c-primary);
        white-space: pre-wrap;
    }
}
