@import "src/@paco/styles";

.knowledge-base-sidebar-item {
    border-top: 1px solid var(--c-border-grey);

    &__wrapper {
        justify-content: left;
        width: 100%;
        padding: remCalc(.5);
        padding-left: calc(remCalc(1.25) * var(--sidebar-item-index));

        &--is-active {
            background-color: var(--c-primary);
        }
    }

    &__title {
        margin-left: remCalc(.75);
        margin-bottom: 0;
        font-size: remCalc(1);
        font-weight: var(--fw-bold);

        &--is-active {
            color: var(--c-white);
        }
    }

    &__icon {
        transition: transform .1s ease-out;
        width: remCalc(.875);
        height: remCalc(.875);

        &--is-opened {
            transform: rotate(.25turn);
        }

        &--is-active {
            color: var(--c-white);
        }
    }

    &__child-item {
        border: none;
    }

    &__child-title {
        margin-left: remCalc(.25);
        font-size: remCalc(.925);
        font-weight: var(--fw-medium);
    }
}
