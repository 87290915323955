@import "../../../../../../style/theme";

.shared-documents-row {

    &__column {
        display: flex;
        justify-content: space-evenly;

        button {
            margin-left: remCalc(.5);
        }
    }

    &-delete {
        color: var(--c-light-blue);
    }
}
