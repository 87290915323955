@import "src/@paco/styles";

.district-division-planner {
    position: relative;

    &__group-buttons {
        display: flex;
        overflow-x: auto;
    }

    &__group-button {
        display: block;
        border-bottom: 2px solid transparent;
        padding-bottom: remCalc(.5);
        color: var(--c-primary);

        & + & {
            margin-left: remCalc(1);
        }

        &--is-active {
            border-bottom-color: var(--c-light-blue);
        }
    }

    &__groups-empty-state {
        border: 1px solid var(--c-border-grey);
        border-radius: var(--table-border-radius);
        background: var(--c-white);
    }

    &__plannings {
        margin: remCalc(1) 0 0;
        padding: 0;
        list-style: none;
    }

    &__plannings-list-item + &__plannings-list-item,
    &__comment {
        margin-top: remCalc(1.25);
    }

    &__loader {
        top: remCalc(5);
    }
}
