@import "src/@paco/styles";

.mobile-navigation {
    width: 100%;
    padding: remCalc(1.5);
    background: var(--c-white);

    &__section {

        & + & {
            margin-top: remCalc(1);
        }
    }

    &__nav-link {
        justify-content: flex-start;
        width: 100%;
        padding: remCalc(.675) remCalc(1);
        line-height: 1.25;
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
        color: var(--c-grey);
        background: var(--c-white);

        &--is-active {
            color: var(--c-primary);
        }
    }

    &__logout-button {
        justify-content: flex-start;
        margin-top: remCalc(1);
        width: 100%;
        height: remCalc(3.5);
        line-height: 1;
        padding: remCalc(1);
        font-size: remCalc(1);
        background: var(--c-lighter-grey);
    }

    &__app-version {
        width: 100%;
        height: remCalc(3.5);
        line-height: 1.5;
        margin-top: remCalc(1);
        padding: remCalc(1);
        font-size: remCalc(1);
        background: var(--c-lighter-grey);
    }
}
