@import "src/@paco/styles";

.mobile-add-button {
    display: flex;
    padding: remCalc(.5);
    font-size: remCalc(1.25);

    @include for-size(tablet-portrait-up) {
        display: none;
    }
}
