@import "../../../../style/theme.scss";

.users-with-loket-balances-table {
  position: inherit !important;

  &__fixed-table-header {

    @media only screen and (max-width: $break-medium) {
      display: none;
    }
  }

  td:nth-child(n+7) {
    background: $super-light-blue;
  }
}
