.info-circle-list {
    display: flex;

    .info-circle-wrapper {
        margin-right: 10px;
    }
}

.info-circle-wrapper {
    display: inline-block;
    vertical-align: top;
    margin-top: -5px;
    width:32px;
    padding-top: 5px;

    &:last-of-type {
        margin-right: 0;
    }
}

.info-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    width:32px;
    height:32px;

    &--tooltip {
        cursor: help;
    }

    &-text {
        pointer-events: none;
    }

    &-icon {
        display: block;
        width: 18px;
        height: 18px;

        &--smiley, &--balloon {
            width: 20px;
            height: 20px;
        }
    }

    &-tooltip {
        margin-top: -5px;
    }
}

