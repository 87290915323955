@import "../../../../../../style/theme.scss";

$component: users-with-loket-balances-head;

.#{$component} {
  width: 100%;

  th {
    width: 10%;
  }

  .#{$component}__name {
    width: 30%;
  }
}
