@import "src/@paco/styles";

$component: log-date-block;

.#{$component} {
  position: relative;
  flex-direction: column;
  border: var(--c-border-grey) 1px solid;
  border-radius: remCalc(.625);
  margin: 0 auto remCalc(1.25);
  width: 100%;
  padding: 0;
  background: var(--c-white);
  overflow: hidden;

  &__date-button {
    display: flex;
    align-items: center;
    margin-bottom: remCalc(.625);
    line-height: 1.25;
    height: remCalc(1.5);
    font-size: remCalc(1);
    color: var(--c-grey);

    &--is-active + .#{$component}__logs {
      display: block;
    }

    &--is-active .#{$component}__date-button-icon {
      transform: rotate(0);
    }
  }

  &__logs {
    display: none;
  }

  &__date-button-icon {
    margin-top: remCalc(.1875);
    transform: rotate(180deg);
  }
}
