@import "src/@paco/styles";

.shift-detail-main-form {

    &__body {
        @extend %modal-body;

        padding-block-end: 2rem;
    }

    &__inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .5rem 2.5rem;
    }

    &__delete-button {
        margin-block-start: 5rem;
        color: var(--c-red);
    }
}