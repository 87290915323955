@import "theme.scss";
@import "src/@paco/styles";

.calendar {

    @media only screen and (max-width: $break-mobile) {
        padding: 0;
    }

    &-overview-top {
        display: flex;
        margin-bottom: remCalc(1);

        @media only screen and (max-width: $break-mobile) {
            flex-direction: column;
        }

        .calendar-toolbar {
            flex-grow: 99;
            margin-right: remCalc(1.25);
            margin-left: remCalc(1.25);

            @media only screen and (max-width: $break-mobile) {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    &-overview-top-right {

        @media only screen and (max-width: $break-mobile) {
            margin-top: remCalc(1.25);
            margin-left: remCalc(1.25);
        }
    }

    &-days {

        &-mobile {
            display: none;
            width: 100%;
            min-height: 100vh;

            .day-content {
                border-right: 0 !important;

                &:after {
                    display: none !important;
                }
            }
        }

        &-desktop {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        @media only screen and (max-width: $break-mobile) {

            &-desktop {
                display: none !important;
            }

            &-mobile {
                display: flex;
                padding: remCalc(1.25) remCalc(1.25) remCalc(4.5);
            }
        }
    }

    &-legend {
        margin-bottom: remCalc(1);
    }

    &-form-add-shift, &-form-save-sequence {
        max-width: remCalc(43);
    }

    &-form-add-temp-worker {
        max-width: remCalc(35);
    }

    &-form-set-shift-planning {
        border-radius: remCalc(1.25);
        margin: remCalc(6.5) auto 0;
        max-width: remCalc(37);
        background: $white;
    }

    &__filters-help-tooltip,
    &__add-base-schedules-help-tooltip {
        position: absolute;
        top: remCalc(3);
        left: remCalc(-8);
    }

    &__legend-help-tooltip {
        position: absolute;
        right: remCalc(20);

        @include for-size(phone-only) {
            top: remCalc(12);
            right: remCalc(10);
        }
    }

    &__search-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(13);

        @include for-size(tablet-portrait-up) {
            top: unset;
            left: unset;
            transform: translate(remCalc(2), remCalc(2));
        }
    }

    &__view-options-help-tooltip {
        position: absolute;
        top: remCalc(3);
        left: remCalc(-8);

        @include for-size(tablet-portrait-up) {
            top: remCalc(3);
            left: unset;
            right: remCalc(17);
        }
    }

    &__hours-overview-help-tooltip {
        position: absolute;
        top: remCalc(1);
        left: remCalc(5);

        @include for-size(tablet-portrait-up) {
            top:  remCalc(4);
            left: remCalc(10);
        }
    }

    &__base-schedules-filters-help-tooltip {
        position: absolute;
        top: remCalc(7);
        left: remCalc(-8);
    }

    &__base-schedules-overview-help-tooltip {
        position: absolute;
        top: remCalc(1);
        left: remCalc(6);

        @include for-size(tablet-portrait-up) {
            top:  remCalc(2);
            left: remCalc(10);
        }
    }

    &__edit-base-schedule-help-tooltip {
        position: absolute;
        top: remCalc(1);
        right: remCalc(6);
    }
}
