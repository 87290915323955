@import "src/@paco/styles";

$component: contract-hours-and-date-input;

.contract-hours-and-date-input {
    @extend %form-row;

    &--hide-delete-buttons {

        .#{$component}__date-input {
            width: 100%;
        }

        .#{$component}__delete-button {
            display: none;
        }
    }

    &__hours-col,
    &__date-col {
        @extend %form-col;
    }

    &__date-col {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__date-input {
        width: calc(100% - 2.5rem);
    }

    &__delete-button {
        margin-bottom: .5rem;
        width: 2rem;
        height: 2rem;

        &:hover,
        &:focus {
            color: var(--c-black);
        }
    }
}
