@import "src/@paco/styles";

.dashboard {

    &__title {
        @extend %page-title;

        margin: 0;
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        min-height: remCalc(2.375);
    }

    &__cards,
    &__small-widgets,
    &__big-widgets {
        margin-top: remCalc(1.25);
    }

    &__big-widgets {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;

        @include for-size(medium-up) {
            flex-direction: row;
        }
    }

    &__app-updates-widget,
    &__shifts-widget {
        width: 100%;
    }

    &__app-updates-widget {

        @include for-size(medium-up) {
            margin-right: remCalc(1.25);
            width: 30%;
            max-width: remCalc(27.5);
        }
    }

    &__shifts-widget {

        @include for-size(medium-up) {
            flex-grow: 2;
            width: 70%;
        }
    }

    &__cards {
        display: grid;
        grid-template-rows: remCalc(7.125) auto;
        grid-template-columns: 100%;
        grid-row-gap: remCalc(1);

        @include for-size(tablet-portrait-up) {
            grid-template-rows: none;
            grid-template-columns: minmax(remCalc(20), 25%) auto;
            grid-column-gap: remCalc(1.875);
        }
    }

    &__welcome-card, &__empty-state, &__small-widgets {
        width: 100%;
    }

    &__welcome-card {
        height: remCalc(7.125);
    }

    &__edit-button-wrapper {
        position: relative;
        margin-left: remCalc(1);
    }

    &__edit-button {
        @extend %button--is-primary-outline;
    }

    &__edit-help-tooltip {
        position: absolute;
        right: calc(50% - #{remCalc(1.25)});
        bottom: remCalc(-1.875);
    }

    &__profile-icons-help-tooltip {
        position: absolute;
        left: remCalc(-40);
        z-index: 980;

        @include for-size(tablet-portrait-up) {
            top: remCalc(0);
            left: unset;
            right: remCalc(15);
        }

        @include for-size(tablet-landscape-up) {
            position: fixed;
            top: remCalc(2.5);
            right: remCalc(3);
            z-index: 1100; // Navigation is 1000
        }
    }
}
