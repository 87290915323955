@import "src/@paco/styles";

.district-division-templates-table-skeleton {

    &__cell {
        z-index: 5;

        &:nth-of-type(4) {
            @include column-width(6rem);

            margin-left: auto;
            margin-right: remCalc(.75);
        }
    }
}
