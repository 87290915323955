@import "../../style/theme.scss";

.exc-shift-card {
  height: fit-content;

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }
}
