@import "../../../../style/theme.scss";

.availability {
  position: relative;

  @media only screen and (max-width: $break-mobile) {
    margin-left: -$main-padding;
    width: calc(100% + #{$main-padding * 2});
  }

  &-top {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: $main-padding;
    padding-right: $main-padding;

    @media only screen and (max-width: $break-mobile) {
      padding-right: #{$main-padding * 2};
    }
  }

  &__action-buttons {
    display: flex;
  }
}
