@import "../../../../../../style/theme.scss";

.shift-employee-icon {
  display: flex;
  align-items: center;
  width: 1rem;
  height: 1rem;

  & + & {
    margin-left: 0.0625rem;
  }
}
