@import "../../style/theme";

.logs-modal {
  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__button-close {
    color: $orange;
    min-width: inherit !important;
  }

  &__loader {
    position: relative;
    min-height: 200px;
  }
}
