@import "../../style/theme.scss";

.toasts {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 $main-padding $main-padding 0;
  word-break: break-word;
  z-index: 999999;
}