@import "../../../../../../style/theme";

$component: "list-view-user";

$cell-height: 52px;

.#{$component} {

    &--is-company-medic &__name:not(:hover) {
        color: var(--c-red);
    }

    td.#{$component}__td {
        vertical-align: top;
        height: $cell-height !important;
        padding: 3px;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        container-type: inline-size;
    }

    &__name {
        font-size: remCalc(.9375);
        font-weight: var(--fw-regular);
    }

    &__name-td {
        height: $cell-height !important;
    }

    &__tooltip {
        display: inline-flex;
    }

    .#{$component}__employment-type {
        display: inline-flex;
        color: $white;
        font-weight: 800;
        background: $primary;
        border-radius: 0.125rem;
        cursor: pointer;
    }

    &__name {
        margin: remCalc(.375);
        font-size: remCalc(.875);
    }

    &__hours {
        display: inline-block;
        margin-left: remCalc(.375);
        font-weight: 600;
        word-break: keep-all;
    }

    &__add-button {
        min-height: remCalc(1);
    }
}
