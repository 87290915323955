@import "src/@paco/styles";

.leave-of-absences-overview-users {
    border: 1px solid var(--c-lighter-grey);
    border-left: 0;
    width: remCalc(13.75);
    min-height: calc(100svh - var(--navbar-height));
    background: var(--c-white);
    z-index: 5;

    @include for-size(tablet-portrait-up) {
        min-height: calc(100svh - var(--navbar-total-height));
    }

    &--is-loading &__users-list-section {
        opacity: .5;
    }

    &__section {
        padding: remCalc(.75) remCalc(1);
    }

    &__section + &__section {
        border-top: 1px solid var(--c-lighter-grey);
    }

    &__title {
        margin: 0;
        line-height: 1;
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__users-amount-section,
    &__user-search-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__users-amount-section {
        height: calc(var(--leave-of-absences-overview-top-bar-height) - 1px);
    }

    &__users-amount {
        margin: remCalc(.125) 0 0;
        font-size: remCalc(.875);
        color: var(--c-grey);
    }

    &__user-search-section {
        height: var(--leave-of-absences-overview-table-head-height);
        padding-top: remCalc(.625);
        padding-bottom: remCalc(.625);
    }

    &__user-search {
        margin: 0;
        min-width: inherit;
    }

    &__user-search-input {
        border: 0;
        height: remCalc(2.375);
        padding-left: remCalc(1.5);
    }

    &__user-search-icon-wrapper {
        margin-left: 0;
        width: remCalc(1);
    }

    &__users-list-section {
        padding: 0;
    }
}
