@import "src/@paco/styles";

.pz-log-detail {

    &__property-list {
        @extend %reset-list;

        border-bottom: 1px solid var(--c-border-grey);
        padding: remCalc(1) remCalc(2);
    }

    &__content {
        padding: remCalc(2);
    }

    &__changes-table,
    &__buttons-wrapper {
        margin-top: remCalc(2);
    }

    &__buttons-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__cancel-button {
        justify-self: flex-end;
        margin-left: auto;
    }

    &__view-button {
        @extend %button--is-primary;

        font-weight: var(--fw-medium);

        &-icon {
            margin-left: remCalc(.5);
        }
    }
}
