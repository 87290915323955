@import "../../../style/theme.scss";

.form-footer {
  display: flex;
  justify-content: flex-end;

  &__tooltip {
    justify-self: flex-start;
    margin-right: auto;
  }

  &__button-delete {
    @include clear-button();

    color: $red;
    font-size: 15px;
    line-height: 1.1875;

    &:hover, &:focus {
      color: darken($red, $button-hover-darken);
    }

    &:disabled {
      color: $grey-disabled;
      cursor: not-allowed;
    }
  }
}
