@import "../../../../../../style/theme.scss";

$component: concept-day-content;

.app .#{$component} {
  padding-top: 0.25rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.25rem;
    height: 1.6875rem;
    background: none;
  }

  &__header-label {
    justify-self: flex-start;
    margin-right: auto;
  }

  &--show-day-in-month {
    padding-top: 0;
  }

  &--is-today {
    background: $light-grey;
  }

  &--is-today .#{$component}__header-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.25rem;
    padding: 0.125rem 0.5rem;
    width: fit-content;
    height: 1.25rem;
    background: $primary;
    color: $white;
  }
}
