@import "src/@paco/styles";

.district-division-templates-container {

    &__overview-help-tooltip {
        position: absolute;
        top: remCalc(0);
        right: remCalc(20);

        @include for-size(phone-only) {
            right: remCalc(12);
        }
    }

    &__add-district-division-template-help-tooltip {
        position: fixed;
        top: remCalc(2.5);
        right: remCalc(5);
        z-index: 1100; // Navigation is 1000

        @include for-size(tablet-portrait-up) {
            position: absolute;
            top: remCalc(5);
            left: remCalc(6);
            right: unset;
            z-index: 980;
        }
    }

    &__edit-district-division-template-help-tooltip {
        position: absolute;
        top: remCalc(8);
        right: remCalc(7);
    }

    &__empty-state-help-tooltip {
        position: absolute;
        top: remCalc(3);
        right: remCalc(16);
    }
}
