@import "src/@paco/styles";

$component: custom-date-controls;

.custom-date-controls {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  height: remCalc(2.5);
  width: 100%;

  @media (max-width: 36rem) {
      flex-direction: column;
      gap: remCalc(1);
      height: auto;
  }

  @media (min-width: 36rem) and (max-width: remCalc(71.25)) {

    .#{$component}__date-range-inputs {
      font-size: remCalc(.8125);
    }

    .#{$component}__date-today-button {
      margin-right: 0;
    }
  }

  @media (max-width: remCalc(71.25)) {
    .#{$component}__date-range-label {
      display: none;
    }
  }

  @include for-size(laptop-up) {

    .#{$component}__date-range-input {
      width: remCalc(8);
    }
  }

  &__date-range-inputs {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__date-today-button {
    margin-right: remCalc(1);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__date-range-input {
    width: remCalc(6.6);
  }

  &__date-range-label {
    width: remCalc(3.125);
    font-weight: var(--fw-medium);
    background: var(--c-background);
    color: var(--c-primary);
    text-overflow: ellipsis;
    overflow: hidden;

    @include for-size(laptop-up) {
      width: auto;
    }
  }

  &__date-range-input-label {
    font-weight: var(--fw-medium);
    background: var(--c-background);
    color: var(--c-primary);
  }
}
