@import "src/@paco/styles";

.calendar-type-radio-list {
    position: relative;
    z-index: 1;

    &__option-wrapper {
        display: flex;
    }

    &__radio {
        display: flex;
        align-items: center;
        border: 1px solid var(--c-primary);
        margin: 0;
        width: fit-content;
        height: remCalc(2.5);
        padding: 0 remCalc(1.25);
        font-size: remCalc(0.875);
        color: var(--c-primary);

        &--is-selected, &:hover {
            color: var(--c-white);
            background: var(--c-primary);
        }

        &:first-of-type {
            border-left-color: var(--c-primary);
        }
    }

    &__radio-label {
        margin-left: 0;
    }

    &__radio-box {
        display: none;
    }
}
