@import "../../style/theme.scss";

.search-bar {
  position: relative;
  margin: 0;
  z-index: 10;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-input {
    border: 1px solid $primary;
    width: 190px;
    max-width: 100%;
    height: 39px;
    padding-left: 40px;
    font-weight: 600;

    &::placeholder {
      color: $primary;
    }
  }

  &-label {
    position: absolute;
    top: 10px;
    left: 15px;
  }

  .form-group {
    margin: 0;
  }
}