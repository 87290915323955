@import "../../../style/theme.scss";

.color-picker {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 50px;
  left: -79px;
  border: 1px solid $border-grey;
  box-sizing: content-box;
  padding: 13px;
  width: 180px;
  background: $white;
  z-index: 10;

  &:after, &:before {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 8px);
    top: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    pointer-events: none;
  }

  &:after {
    border-color: transparent transparent $white transparent ;
    border-width: 7px;
  }

  &:before {
    top: -16px;
    left: calc(50% - 9px);
    border-color: transparent transparent $border-grey transparent ;
    border-width: 8px;
  }

  @media only screen and (max-width: $break-medium) {
    left: -15px;

    &:after, &:before {
      left: 31px;
    }

    &:before {
      left: 30px;
    }
  }

  &__button {
    margin: 5px;
  }
}
