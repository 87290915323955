@import "src/@paco/styles";

$component: 'sidebar-toggle-button';

.#{$component} {
    height: remCalc(1.375);
    width: remCalc(1.375);
    border-radius: 50%;
    border: 1px solid var(--c-orange);
    background-color: var(--c-orange);
    transform-origin: center;

    &:hover {
        transform: scale(1.5);
        background-color: var(--c-primary);

        .#{$component}__icon {
            color: var(--c-white);
        }
    }

    &--is-opened {
        border: 1px solid var(--c-border-grey);
        background-color: var(--c-white);
    }

    &__icon {
        color: var(--c-white);

        &--is-opened {
            color: var(--c-primary);
        }
    }
}
