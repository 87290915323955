@import "src/@paco/styles";

.input-label {
    display: flex;
    align-items: center;
    font-weight: var(--fw-medium);
    margin-bottom: remCalc(.5);

    &__required-star {
        color: var(--c-red);
        margin-left: remCalc(.2);

        &:before {
            content: '*';
        }
    }

    &__required-text {
        display: inline-block;
        font-size: remCalc(.8125);
        font-style: italic;

        opacity: 0;
        transform: translateX(remCalc(-.25));
        transition: opacity .15s ease-out, transform .15s ease-out;

        &--is-open,
        &:hover {
            opacity: 1;
            transform: translateX(0);
        }
    }

    &__element-with-tooltip {
        margin-left: remCalc(.5);
    }

    &__tooltip-button {
        @extend %button--is-tooltip;
    }
}
