@import "src/@paco/styles";

.paco-legend {
    display: none;

    @include for-size(tablet-portrait-up) {
        display: block;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid var(--c-border-grey);
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
        width: 100%;
        height: remCalc(3.75);
        background: var(--c-white);
        color: var(--c-orange);

        &:hover {
            color: var(--c-orange-dark);
        }
    }

    &__icon {
        margin-right: remCalc(.5);
        width: remCalc(1.25);
    }

    &__legend {
        position: absolute;
        left: remCalc(.5);
        border: 1px solid var(--c-border-grey);
        width: calc(100% - #{remCalc(1)});
        bottom: calc(100% + #{remCalc(.5)});
        padding: remCalc(1.25) remCalc(1.875) remCalc(1.25) remCalc(1.25);
        background: var(--c-white);
    }

    &__legend-color {

        & + & {
            margin-top: remCalc(.375);
        }
    }

    &__title {
        margin-bottom: remCalc(.5);
        font-weight: var(--fw-medium);
    }

    &__close-button {
        @extend %button--reset;

        position: absolute;
        top: remCalc(1);
        right: remCalc(1);
        width: remCalc(1);
        color: var(--c-primary);

        &:hover {
            color: var(--c-dark-blue);
        }
    }

    &__close-icon {
        width: remCalc(.75);
    }
}
