@import "../../../../style/theme.scss";

.settings-card-title {
  border-bottom: 1px solid $border-grey;
  padding: $main-padding 30px;
  line-height: 1.25;
  font-size: 20px;
  font-weight: 600;
  color: $dark;

  &--has-children {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
