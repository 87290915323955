// Colours
$c-primary:             17, 77, 97;
$c-secondary:           0, 172, 245;
$c-blue:                102, 178, 255;
$c-dark-blue:           11, 39, 48;
$c-light-blue:          134, 200, 223;
$c-lighter-blue:        245, 252, 255;
$c-cyan:                73, 143, 167;
$c-white:               255, 255, 255;
$c-black:               0, 0, 0;
$c-light:               173, 190, 207;
$c-red:                 255, 28, 37;
$c-dark-red:            185, 25, 31;
$c-bordeaux:            222, 24, 32;
$c-scarlet:             225, 72, 78;
$c-success:             105, 195, 0;
$c-background:          241, 241, 241;
$c-orange:              255, 129, 0;
$c-dark-orange:         229, 116, 0;
$c-light-orange:        255, 159, 25;
$c-green:               38, 174, 96;
$c-available-green:     233, 247, 239;
$c-success-green:       75, 181, 67;
$c-dark-green:          0, 128, 0;
$c-light-green:         233, 247, 239;
$c-grey:                102, 102, 102;
$c-disabled-grey:       202, 202, 202;
$c-light-grey:          249, 249, 249;
$c-lighter-grey:        243, 243, 243;
$c-border-grey:         225, 225, 225;
$c-medium-grey:         151, 151, 151;
$c-placeholder:         172, 172, 172;

// Calendar concept colours
$concept-dark-blue:     61, 78, 95;
$concept-darker-blue:   51, 66, 82;
$concept-blue:          52, 152, 219;
$concept-light-blue:    231, 240, 245;
$concept-light-orange:  255, 247, 240;
$concept-border-grey:   106, 117, 129;

$navbar-height:         56px;
$sub-navbar-height:     56px;
$sidebar-width:         216px;
$navbar-total-height:   #{$navbar-height + $sub-navbar-height};

:root {
    --c-primary:                    rgb(#{$c-primary});
    --c-primary-rgb:                #{$c-primary};
    --c-secondary:                  rgb(#{$c-secondary});
    --c-blue:                       rgb(#{$c-blue});
    --c-dark-blue:                  rgb(#{$c-dark-blue});
    --c-light-blue:                 rgb(#{$c-light-blue});
    --c-lighter-blue:               rgb(#{$c-lighter-blue});
    --c-cyan:                       rgb(#{$c-cyan});
    --c-white:                      rgb(#{$c-white});
    --c-black:                      rgb(#{$c-black});
    --c-light:                      rgb(#{$c-light});
    --c-red:                        rgb(#{$c-red});
    --c-warning:                    rgb(#{$c-red});
    --c-dark-red:                   rgb(#{$c-dark-red});
    --c-bordeaux:                   rgb(#{$c-bordeaux});
    --c-scarlet:                    rgb(#{$c-scarlet});
    --c-success:                    rgb(#{$c-success});
    --c-background:                 rgb(#{$c-background});
    --c-table-bg:                   rgb(#{$c-light-grey});
    --c-orange:                     rgb(#{$c-orange});
    --c-orange-dark:                rgb(#{$c-dark-orange});
    --c-orange-light:               rgb(#{$c-light-orange});
    --c-green:                      rgb(#{$c-green});
    --c-green-available:            rgb(#{$c-available-green});
    --c-green-success:              rgb(#{$c-success-green});
    --c-green-dark:                 rgb(#{$c-dark-green});
    --c-green-light:                rgb(#{$c-light-green});
    --c-grey:                       rgb(#{$c-grey});
    --c-disabled-grey:              rgb(#{$c-disabled-grey});
    --c-light-grey:                 rgb(#{$c-light-grey});
    --c-lighter-grey:               rgb(#{$c-lighter-grey});
    --c-border-grey:                rgb(#{$c-border-grey});
    --c-medium-grey:                rgb(#{$c-medium-grey});
    --c-placeholder:                rgb(#{$c-placeholder});
    --c-concept-dark-blue:          rgb(#{$concept-dark-blue});
    --c-concept-darker-blue:        rgb(#{$concept-darker-blue});
    --c-concept-blue:               rgb(#{$concept-blue});
    --c-concept-light-blue:         rgb(#{$concept-light-blue});
    --c-concept-light-orange:       rgb(#{$concept-light-orange});
    --c-concept-border-grey:        rgb(#{$concept-border-grey});

    --f-primary:                    'Source Sans Pro', sans-serif;

    --fw-regular:                   400;
    --fw-medium:                    600;
    --fw-bold:                      800;

    --input-border-radius:          2px;
    --calendar-item-border-radius:  3px;
    --table-border-radius:          10px;

    --navbar-height:                #{$navbar-height};
    --sub-navbar-height:            #{$sub-navbar-height};
    --navbar-total-height:          #{$navbar-total-height};
    --sidebar-width:                #{$sidebar-width};
    --modal-border-radius:          5px;
    --page-padding:                 20px;

    --focus-ring:                   0 0 0 .2rem rgba(var(--c-primary-rgb), 0.2);
    --box-shadow:                   0 2px 12px 0 rgb(0 0 0 / 10%);
}
