@import "src/@paco/styles";

.complete-period-top-bar-skeleton {
    display: flex;
    flex-direction: column;
    margin-bottom: remCalc(1.25);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: space-between;
    }

    &__period-skeleton-wrapper,
    &__select-payroll-period-skeleton-wrapper {
        display: flex;
    }

    &__period-skeleton-wrapper {
        margin-bottom: remCalc(1);

        @include for-size(tablet-portrait-up) {
            margin-bottom: 0;
        }
    }

    &__period-skeleton {
        width: remCalc(6);
        height: remCalc(1.65);
    }

    &__badge-skeleton {
        width: remCalc(3);
        height: remCalc(1.65);
        margin-left: remCalc(.5);
    }

    &__year-skeleton {
        width: remCalc(5.5);
        height: remCalc(2.25);
    }

    &__payroll-period-skeleton {
        width: remCalc(20);
        height: remCalc(2.25);
        margin-left: remCalc(.5);
    }
}
