@import 'theme';

.react-tooltip-lite {
  padding: 5px;
  color: $white;
  font-weight: 500;
  font-size: 14px;
  font-family: $font-family;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 5px;
  pointer-events: none;
  z-index: 2000 !important;
}

.react-tooltip-lite-arrow {
  border-color: $black;
  z-index: 2001 !important;
}
