@import "src/@paco/styles";

$component: leave-of-absences-overview-table-head;

.#{$component} {
    height: var(--leave-of-absences-overview-table-head-height);

    &__weeks-row,
    &__days-row {
        font-size: remCalc(.875);
        font-weight: var(--fw-medium);
    }

    &__weeks-row {
        color: var(--c-medium-grey);
    }

    &__days-row {
        color: var(--c-primary);
    }

    &__weeks-cell,
    &__days-cell {
        max-width: var(--leave-of-absences-overview-table-cell-width);
        min-width: var(--leave-of-absences-overview-table-cell-width);
    }

    &__days-cell,
    &__weeks-cell {
        text-align: center;
    }

    .#{$component}__days-cell--is-active {
        border-bottom-color: var(--c-orange);
        color: var(--c-orange);
    }

    &__weeks-cell-day-week-wrapper {
        display: inline-block;
        position: relative;
    }

    &__days-cell {
        border-bottom: 2px solid transparent;
        height: remCalc(.75);
        padding-bottom: remCalc(.375);
    }

    &__weeks-cell-day {
        visibility: hidden;
    }

    &__weeks-cell-week {
        position: absolute;
        left: 0;
    }
}
