@import "../../style/theme.scss";
@import "src/@paco/styles";

$component: tracks;

.#{$component} {

    &-list {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $break-mobile) {
            padding-top: $main-padding;
            min-height: calc(100vh - #{$navbar-height});
        }

        .tracks-search {
            align-self: flex-end;
            margin-bottom: $main-padding;

            @media only screen and (max-width: $break-mobile) {
                margin-right: $main-padding;
            }
        }

        &-item-title {
            border-bottom: 1px solid $border-grey;
            margin-bottom: $main-padding;
            padding-bottom: 8px;
        }
    }

    &-finished,
    &-review {
        .tracks-overview-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            width: 100%;
        }

        .calendar-toolbar {
            margin-right: 20px;
            flex-grow: 99;
        }

        .tracks-overview-top-right {
            display: flex;

            .search-bar,
            .button-export,
            .button-with-tooltip-container {
                margin-left: 10px;
            }

            .button-export:first-child {
                margin-left: 0;
            }
        }

        @media only screen and (max-width: $break-mobile) {
            padding-top: 0;

            .tracks-overview-top {
                flex-direction: column;
                margin-bottom: 10px;
            }

            .tracks-overview-top-right {
                padding: 20px 20px 10px 10px;
                justify-content: flex-end;
                flex-wrap: wrap;

                .button-export {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &-finished {
        @media only screen and (max-width: 1400px) {
            .tracks-overview-top {
                flex-direction: column;
            }

            .calendar-toolbar {
                margin-right: 0;
            }

            .tracks-overview-top-right {
                justify-content: flex-end;
            }
        }
    }

    &-review {
        @media only screen and (max-width: 1100px) {
            .tracks-overview-top {
                flex-direction: column;
            }

            .calendar-toolbar {
                margin-right: 0;
            }

            .tracks-overview-top-right {
                justify-content: flex-end;
            }
        }

        .trackcards-container {
            max-width: 93.75rem;
        }

        @media only screen and (max-width: $break-mobile) {
            .trackcards-container {
                padding: 0 20px;
            }
        }
    }

    &__table-tooltip {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__table-button {
        @include clear-button();

        &:disabled {
            .#{$component}__table-button-icon {
                color: $grey-disabled;
            }
        }
    }

    &__table-button-icon {
        color: $light-blue;
    }

    &__worked-total-hours {
        display: flex;
        align-items: center;
    }

    &__worked-total-hours__icon {
        display: inline-block;
        margin: 0 remCalc(.25);
        height: remCalc(1.125);
        color: var(--c-orange);
        cursor: default;
    }

    &__worked-total-hours__tooltip {
        display: flex;
    }

    &__add-track-help-tooltip {
        position: fixed;
        top: remCalc(2.5);
        right: remCalc(4.5);
        z-index: 1100; // Navigation is 1000
    }

    &__filters-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(-8);
    }

    &__track-data-help-tooltip {
        position: absolute;
        top: remCalc(3);
        left: remCalc(5)
    }

    &__view-options-help-tooltip,
    &__finished-track-icons-help-tooltip,
    &__edit-track-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(-8);
        text-align: left;
    }

    &__stop-track-help-tooltip {
        position: absolute;
        top: remCalc(3);
        left: remCalc(-8);
        text-align: left;
    }

    &__review-tracks-help-tooltip {
        position: absolute;
        transform: translate(remCalc(7), remCalc(4));
    }

    &__review-track-icons-help-tooltip {
        position: absolute;
        top: remCalc(15);
        right: remCalc(5);
    }

    &__track-info-help-tooltip {
        position: absolute;
        transform: translate(remCalc(5), remCalc(16));
    }

    &__finished-tracks-help-tooltip {
        position: absolute;
        top: remCalc(14);
        left: remCalc(8);
    }

    &__export-tracks-help-tooltip {
        position: absolute;
        transform: translate(remCalc(4), remCalc(2));
    }

    @include for-size(phone-only) {
        &__review-tracks-help-tooltip,
        &__track-info-help-tooltip,
        &__export-tracks-help-tooltip {
            transform: none;
        }

        &__review-tracks-help-tooltip {
            top: remCalc(3);
            left: remCalc(4);
        }

        &__track-info-help-tooltip {
            top: remCalc(22);
            left: remCalc(8);
        }

        &__export-tracks-help-tooltip {
            top: remCalc(6.5);
            right: remCalc(10);
        }
    }

    @include for-size(tablet-portrait-up) {
        &__add-track-help-tooltip {
            position: absolute;
            top: remCalc(3);
            left: remCalc(-8);
            z-index: 980;
        }

        &__view-options-help-tooltip {
            top: unset;
            left: unset;
            transform: translate(remCalc(-5), remCalc(3));
        }

        &__stop-track-help-tooltip {
            top: remCalc(2);
            left: unset;
        }

        &__review-track-icons-help-tooltip {
            top: remCalc(14);
            right: remCalc(6);
        }

        &__track-info-help-tooltip {
            transform: translate(remCalc(5), remCalc(13));
        }

        &__review-tracks-help-tooltip {
            transform: translateX(remCalc(18));
        }

        &__finished-tracks-help-tooltip {
            top: remCalc(5);
            left: remCalc(8);
        }
    }

    @include for-size(medium-up) {
        &__finished-track-icons-help-tooltip {
            top: remCalc(12);
            left: unset;
            right: remCalc(12);
        }

        &__edit-track-help-tooltip {
            top: remCalc(18);
            left: unset;
            right: remCalc(4);
        }
    }

    @include for-size(laptop-up) {
        &__review-track-icons-help-tooltip {
            top: remCalc(12);
            right: 50%;
        }
    }
}

.tracks-td-edit-delete {
    display: flex;
    justify-content: flex-start;
    width: auto !important;
}

.finished-tracks {

    &__row {

        &--is-active, &--is-active:hover {
            color: var(--c-white);
            background: var(--c-primary) !important;
        }

        &--is-active a {
            color: var(--c-white);
        }
    }
}
