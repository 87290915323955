@import "./theme.scss";

.date-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-left: 1rem;
  height: 30px;
  width: 200px;

  @media only screen and (max-width: 800px) {
    margin-left: 0.5rem;
    width: 190px;
  }

  @media only screen and (max-width: $break-mobile) {
    margin-left: 0;
    width: 100%;
    padding: 0 20px;
  }

  &-month {
    margin-left: 0rem;
    z-index: 10;

    @media only screen and (max-width: $break-mobile) {
      padding-left: 0;
    }
  }

  &__button {
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    color: $primary;
    background-color: transparent;

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      color: darken($primary, $button-hover-darken);
    }

    &:focus {
      outline: none;
    }
  }

  &__button-picker {
    border: 0;
    margin: 0;
    min-width: 100px;
    padding: 0;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    color: $primary;
    overflow: hidden;
    white-space: nowrap;
    background: none;

    &:hover {
      color: darken($primary, $button-hover-darken);
    }

    @media only screen and (max-width: $break-mobile) {
      font-size: 1.125rem;
    }
  }
}
