@import "src/@paco/styles";

$component: submit-button;

.#{$component} {
    display: flex;
    align-items: center;

    &--align-right {
        flex-direction: row-reverse;

        .#{$component}__icon {
            margin-right: 0;
            margin-left: remCalc(.625);
        }
    }

    &__icon {
        display: block;
        min-width: 1em;
        max-width: 1em;
        min-height: 1em;
        max-height: 1em;
        margin-right: remCalc(.625);
    }
}
