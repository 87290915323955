@import "../../../style/theme";

.complete-period-detail-page {
    position: relative;
    padding-bottom: 1rem;

    &__block {
        margin: $main-padding $main-padding 0;
    }
}
