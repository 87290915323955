@import "src/@paco/styles";

.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: 1px solid var(--c-border-grey);
    border-radius: remCalc(.5);
    width: 100%;
    padding: remCalc(1.875);
    background: var(--c-white);

    &__title {
        @extend %page-title;
    }

    &__intro {
        margin: remCalc(1) 0 0;
        width: 100%;
        max-width: remCalc(26);
        font-size: remCalc(1);
        text-align: center;
        color: var(--c-grey);
        opacity: .75;
    }

    &__add-button {
        @extend %button--is-primary-outline;

        display: flex;
        align-items: center;
        margin-top: remCalc(1.75);
        text-align: center;

        &:hover, &:focus {
            text-decoration: none;
        }

        &-icon {
            margin-right: remCalc(.5);
        }
    }

    &__loader {
        top: 0;
        height: 100%;
    }
}
