@import "../../../../style/theme";

.button-collapse {
  @include clear-button();
  display: flex;
  align-items: center;
  color: $cyan-blue;

  &:hover {
    color: $black;
  }

  &--collapsed i {
    transform: scaleY(-1);
    transform-origin: 50% 50%;
  }
}
