@import "../../style/theme";
@import "src/@paco/styles";

$component: sidebar;

.#{$component} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: relative;
    width: 100%;

    &-side-container-container {
        width: $sidebar-width-desktop;
        transition: width $sidebar-transition-time ease-out;

        @media only screen and (max-width: $break-medium) {
            width: $sidebar-width-tablet;
        }

        @media only screen and (max-width: $break-mobile) {
            width: 0;
        }
    }

    &-side-container {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 21;

        &--sticky {
            position: relative;

            .#{$component}__sticky-content {
                position: sticky;
                top: $navbar-height;
            }
        }

        @media only screen and (max-width: $break-mobile) {
            position: fixed;
            top: $navbar-height;
            left: 0;
            height: calc(100% - #{$navbar-height});
            transition: transform .2s ease-out;
            transform: translateX(-100%);
            pointer-events: none;

            &--active {
                display: block;
                transform: translateX(0%);
                pointer-events: visible;
                z-index: 999;
            }
        }

        &--has-legend .sidebar-page-sidebar {
            padding-bottom: 3.75rem;
        }
    }

    &-page-sidebar {
        display: block;
        float: right;
        width: $sidebar-width-desktop;
        height: 100%;
        min-height: calc(100vh - #{$navbar-total-height});
        background-color: white;
        border-top: solid #E1E1E1 1px;
        border-right: solid #E1E1E1 1px;

        .btn {
            width: 100%;
        }

        @media only screen and (max-width: $break-medium) {
            width: $sidebar-width-tablet;
        }

        @media only screen and (max-width: $break-mobile) {
            position: relative;
            width: 100%;
            height: calc(100vh - #{$navbar-height});
            float: none;
            padding-bottom: 4.5rem;
            overflow-y: scroll;

            .form-check, .filter-block__title {
                font-size: 1.125rem;
            }

            .filter-block:last-child {
                border-bottom: 0;
            }

            .filter-block__title {
                margin-bottom: .5rem;
            }

            .custom-control-label {
                padding-top: .375rem;
            }
        }
    }

    &-page-container {
        position: relative;
        width: calc(100% - #{$sidebar-width-desktop});
        transition: width $sidebar-transition-time ease-out;

        @media only screen and (max-width: $break-medium) {
            width: calc(100% - #{$sidebar-width-tablet});
        }

        @media only screen and (max-width: $break-mobile) {
            width: 100%;
            padding-left: 0;
        }

        &:first-child {
            padding: $main-padding;

            @media only screen and (max-width: $break-mobile) {
                margin-bottom: 3.25rem;
                padding: 0;
            }
        }
    }

    &-button-toggle {
        position: fixed;
        bottom: $main-padding;
        left: $main-padding;
        display: none;
        align-items: center;
        justify-content: center;
        width: calc(100% - #{$main-padding * 2});
        min-width: $site-min-width;

        @media only screen and (max-width: $break-mobile) {
            display: flex;
            z-index: 1000;
        }

        &--hide-mobile {
            @media only screen and (max-width: $break-mobile) {
                display: none;
            }
        }
    }

    &:has(.list-view-table) &__inner,
    &:has(.concepts-list-view-table) &__inner {
        position: relative;

        @media only screen and (min-width: $break-mobile) {
            height: calc(100vh - #{var(--navbar-total-height)} - 1px);
            overflow-y: auto;
        }
    }

    &__toggle-button {
        position: absolute;
        display: none;
        right: remCalc(-.625);
        z-index: 210;

        @include for-size(tablet-portrait-up) {
            display: flex;
            top: remCalc(4);
        }

        @include for-size(tablet-landscape-up) {
            top: remCalc(2);
        }
    }

    &__button-toggle-close-icon {
        margin-right: remCalc(.4375);
    }

    &__shifts-legend {
        position: fixed;
        bottom: 0;
        left: 0;
        width: $sidebar-width-desktop;
        z-index: 10;
    }

    &--sidebar-hidden .sidebar {

        &-side-container-container {

            @media only screen and (min-width: $break-mobile) {
                width: 1.25rem;
            }
        }

        &-page-sidebar-button-hide {
            left: .5rem;
        }

        &-page-container {
            width: calc(100% - 1.25rem);
        }

        &__inner {
            pointer-events: none;
        }
    }

    &--calendar-hidden {
        .calendar-shift, .calendar-absence {
            display: none;
        }
    }

    .pagination:not(:first-child) {
        margin-block: .5rem -.5rem;
    }
}

// Hack to fix rendering glitch
#app-root:has(.list-view-table),
#app-root:has(.concepts-list-view-table) {
    overflow-y: hidden;
}
