@import "src/@paco/styles";

$component: "user-detail-info-card";

.#{$component} {
    position: relative;
    border-radius: remCalc(.625);
    padding: remCalc(2);
    background-color: var(--c-white);
    box-shadow: var(--box-shadow);

    @include for-size(tablet-landscape-up) {
        top: 0;
        right: 0;
    }

    &__edit-button {
        position: absolute;
        top: remCalc(2);
        right: remCalc(2);
        color: var(--c-light-blue);
    }

    &__list {
        @extend %reset-list;

        @include for-size(tablet-landscape-up) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__list-item {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            width: 50%;
        }
    }
}
