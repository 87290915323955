@import "src/@paco/styles";

$component: draggable-district-division-district-planning;

.#{$component} {
    display: flex;
    align-items: center;
    position: relative;
    border: 2px solid transparent;
    padding: 0 remCalc(1);
    font-weight: var(--fw-medium);
    color: var(--c-primary);
    background: var(--c-lighter-grey);
    cursor: move;

    &:hover, &:active {
        opacity: 1;
        background: var(--c-border-grey);

        .#{$component}__draggable-icon {
            display: block;
        }
    }

    &--is-active {
        opacity: 1;
        background: var(--c-primary);

        .#{$component}__employee-name,
        .#{$component}__department,
        .#{$component}__shift-planning-time,
        .#{$component}__delete-button-icon,
        .#{$component}__comment-button-icon {
            color: var(--c-white);
        }
    }

    &--is-disabled {
        opacity: .5;
        pointer-events: none;
    }

    &__employee-name {
        @extend %text-ellipsis;

        justify-self: flex-start;
        margin-right: auto;
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
        pointer-events: none;
    }

    &__department {
        @extend %text-ellipsis;

        margin-left: remCalc(1);
        pointer-events: none;
    }

    &__shift-planning-time {
        @extend %text-ellipsis;

        margin-left: 5%;
        min-width: remCalc(5);
        pointer-events: none;
    }

    &__shift-planning {
        color: var(--c-primary);
    }

    &__comment-tooltip,
    &__comment-button,
    &__delete-button {
        color: var(--c-light-blue);

        &:not(:first-child) {
            margin-left: remCalc(.5);
        }
    }

    &__delete-button-icon {
        height: remCalc(1.25);
        max-width: remCalc(1.25);
        max-height: remCalc(1.25);
    }

    &__comment-button-icon {
        height: remCalc(1.5);
        max-width: remCalc(1.5);
        max-height: remCalc(1.5);
    }

    &__draggable-icon {
        display: none;
        position: absolute;
        bottom: remCalc(.125);
        left: calc(50% - #{remCalc(.5)});
        width: remCalc(.75);
        height: remCalc(.75);
    }
}
