@import "src/@paco/styles";

.paco-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1300;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity var(--modal-transition-duration) ease;
    opacity: 0;

    @include for-size(phone-only) {
        max-height: calc(100vh - var(--navbar-height));
        max-height: 100svh;
    }

    &--is-opened {
        opacity: 1;
    }

    &--is-wide &__card {
        max-width: remCalc(71.25);
    }

    &__card {
        position: relative;
        z-index: 100;
        width: 100%;
        max-width: remCalc(42);
        margin: remCalc(1.5) auto;
        border-radius: remCalc(0.3);
        background-color: var(--c-white);
    }
}
