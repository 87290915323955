@import "src/@paco/styles";

.checkbox-group-list {

    &__label {
        margin-bottom: remCalc(.625);
    }

    &__item {
        display: flex;

        &--is-sub-item {
            margin-left: remCalc(1);
        }

        .checkbox + &--is-closed {
            display: none;
        }

        & + & {
            margin-bottom: remCalc(.375);
        }
    }
}
