@import "../../style/theme";

.button-question-tooltip {
  @include clear-button();
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  color: $white;
  font-weight: 600;
  font-size: 12px;
  background: $light-blue;
  border-radius: 50%;
}
