@import '../../../../style/theme';

.edit-shift-form {
  display: flex;
  flex-direction: column;
  border: 1px solid $border-grey;

  &__footer {
    justify-self: flex-end;
    margin-top: auto;
  }

  &__delete-button {
    @include button-plain();
    color: $red;

    &:hover {
      color: darken($red, $button-hover-darken);
    }
  }
}
