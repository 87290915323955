@import "../../../../style/theme";

.button-reset {
  @include clear-button();

  display: flex;
  align-items: center;
  color: $orange;

  &:hover {
    filter: brightness(80%);
  }

  &__icon {
    margin-right: 0.5rem;
    width: 1rem;
  }
}
