@import "src/@paco/styles";

.user-shift-track-time {
    display: flex;
    justify-content: space-between;
    line-height: 1.1;
    font-weight: var(--fw-medium);

    &__track-time {
        margin-left: remCalc(.25);
        text-align: right;
    }

    &__text {
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    &__icon {
        display: inline;
        margin-top: -#{remCalc(.125)};
        margin-left: remCalc(.125);
        min-height: remCalc(1.125);
    }
}
