@import "src/@paco/styles";

.basic-select {
    width: remCalc(8.125);
    height: remCalc(2.5);
    color: var(--c-black);

    &__select {
        width: 100%;
        height: 100%;

        // This is the only way to style BasicSelect with scss, sorry!
        &__control {
            border: 1px solid var(--c-primary) !important;
            border-radius: 2px !important;
            height: 100% !important;
        }

        &__indicator-separator {
            display: none !important;
        }

        &__indicator {
            padding-right: remCalc(0.5);
            color: inherit !important;
        }

        &__single-value {
            color: inherit !important;
        }
    }
}
