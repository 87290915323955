@import "../../style/theme.scss";

.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;

  @media only screen and (max-width: $break-mobile) {
    margin-bottom: 3.75rem;
  }

  &:not(:first-child) {
    margin-top: $main-padding;
  }

  &-input__label {
    margin: 0;
    color: $primary;

    &:hover {
      color: $dark;
      background-color: rgba(17, 77, 97, 0.1);
    }

    &--active {
      color: $white !important;
      font-weight: 700;
      background-color: $primary;

      &:hover {
        text-decoration: underline;
        background-color: darken($primary, $button-hover-darken);
      }
    }
  }

  &-direction-button {
    padding: 0.625rem 1.125rem !important;
    opacity: 1 !important;

    svg {
      fill: $primary;
    }

    &__text {
      color: $primary;
      font-weight: 600;
      font-size: 1rem !important;
    }

    &:hover {
      color: $dark;
      text-decoration: underline;
      background-color: rgba(17, 77, 97, 0.1);
    }
  }
}
