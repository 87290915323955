@import "src/@paco/styles";

.user-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: remCalc(.625) remCalc(1.25);
    border-radius: 2px;
    box-shadow: var(--box-shadow);
    background: var(--c-white);

    &__version-number {
        margin-bottom: remCalc(.25);
        font-size: remCalc(.75);
        color: var(--c-grey);
    }
}
