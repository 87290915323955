@import "../../style/theme.scss";

.user-page-link {
  margin-right: 7px;
  font-size: 14px;
  font-weight: 600;
  color: $dark;

  &--with-id {
    @include clear-button();
  }

  &--is-company-medic {
    color: var(--c-red);
  }

  &--is-plain {
    font-size: 15px;
    font-weight: 500;
    color: $black;
  }

  &:hover {
    text-decoration: underline;
  }

  &--disabled {
    &:hover {
      text-decoration: none;
    }
  }
}
