@import "../../style/theme";

.filters {
    &__button-reset {
        @include clear-button();

        display: flex;
        align-items: center;
        width: calc(100% - #{remCalc(2.5)});
        color: var(--c-orange);
        margin: 0 remCalc(.25);
        font-weight: var(--fw-medium);

        &:hover {
            filter: brightness(80%);
        }

        &-icon {
            margin-right: 0.5rem;
            width: 1rem;
        }
    }
}
