@import "src/@paco/styles";

$component: 'sort-label';

.#{$component} {
    position: relative;
    height: auto;
    padding: remCalc(1) 0;
    color: var(--c-grey);

    &--is-active {

        .#{$component}__icon {
            opacity: 1;
        }
    }

    &--is-disabled {
        pointer-events: none;
    }

    &--align-right {

        .#{$component}__button {
            flex-direction: row-reverse;
            justify-content: flex-start;
        }

        .#{$component}__icon {
            margin-left: 0;
            margin-right: remCalc(.5);
        }
    }

    &__button {
        @extend %button;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        color: currentColor;
        white-space: nowrap;

        &:focus-visible {
            outline: none;
            text-decoration: underline;
            color: var(--c-black);
        }

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__icon {
        width: remCalc(1);
        margin-left: remCalc(.5);
        opacity: 0;
    }
}
