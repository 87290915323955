@import "src/@paco/styles";

.radio-list {
    display: block;

    &__label {
        margin-bottom: remCalc(.625);
    }

    &__option-wrapper {

        &--has-error {
            padding-left: remCalc(.5);
            border-left: 2px solid var(--c-negative);
        }
    }

    &__radio {
        padding: remCalc(.375) 0;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    &__error-label {
        margin-top: remCalc(.5);
    }
}
