@import "src/@paco/styles";

.export-leave-of-absences-form {
    @extend %form;

    &__row {
        @extend %form-row;
    }

    &__col {
        @extend %form-col;
    }

    &__date-input {
        width: 50%;
    }

    &__unknown-error,
    &__error-wrapper {
        margin: 0 remCalc(1);
        max-height: remCalc(20);
        overflow-y: auto;
    }

    &__sync-errors-intro {
        margin: remCalc(.5) 0 0;
    }

    &__sync-errors-list {
        @extend %reset-list;

        flex-direction: column;
    }

    &__sync-errors-list-item {
        margin-top: remCalc(1);
        width: 100%;
    }

    &__sync-error {
        width: 100%;
    }
}
