@import "../../style/theme";

.legacy-hours-warning {
  &__warning {
    margin-bottom: 1.25rem;
  }

  &__title {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
}
