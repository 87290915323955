@import "src/@paco/styles";

.counter-indicator {
    @extend %flex-align-center;

    @include circle(remCalc(1.25));

    border: 1px solid var(--c-orange);
    font-size: remCalc(.8125);
    font-weight: var(--fw-medium);
    background: var(--c-white);
    color: var(--c-orange);

    &--has-overflow {
        border-radius: remCalc(.625);
        min-width: remCalc(1.625);
        max-width: remCalc(1.625);
        font-size: remCalc(.75);
    }
}
