@import "src/@paco/styles";
@import "../../../../style/theme.scss";

.documents {
  position: relative;

  @media only screen and (max-width: $break-mobile) {
    margin-left: #{-$main-padding};
    width: calc(100% + #{$main-padding * 2});
  }

  &__top {
    display: flex;
    padding: 0 1.25rem 1.25rem;
  }

  &__year-end-statement {
    display: flex;
  }

  &__new-document {
    margin-left: auto;
  }

  &__download {
    @extend %button--is-primary;

    margin-left: remCalc(1);
  }
}

.documents-table {
  &-td-filename {
    width: 100%;
    max-width: 100px;
    overflow: hidden;

    @media only screen and (max-width: $break-mobile) {
      min-width: 200px;
    }
  }

  &-filename {
    @include clear-button();
    text-align: left;
    width: auto;
    max-width: 100%;
    text-overflow: ellipsis;
    color: $cyan-blue;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }
  }

  &-td-visible-to-employee {
    min-width: 235px;

    @media only screen and (max-width: 950px) {
      min-width: 200px;
    }
  }

  &-td-create-date {
    min-width: 200px;

    @media only screen and (max-width: 950px) {
      min-width: 150px;
    }
  }

  &-filter {
    min-width: 270px;

    @media only screen and (max-width: 900px) {
      min-width: 170px;
    }

    @media only screen and (max-width: $break-mobile) {
      min-width: 170px;
    }
  }
}
