@import "../../../../style/theme.scss";

.set-start-end-date-time-form {
    &__multiple-days-radio-list {
        margin-bottom: 1rem;
    }

    &__error-text {
        display: block;
        margin-top: -0.5rem;
        margin-bottom: 1rem;
    }
}
