@import "src/@paco/styles";

$component: shift-planning-draggable;

.#{$component} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    border-radius: 3px;
    padding: remCalc(.5) remCalc(.75);
    color: var(--c-primary);
    background: var(--c-lighter-grey);
    cursor: move;

    &--is-disabled {
        pointer-events: none;
    }

    &--is-used {
        color: var(--c-green);
        background: var(--c-light-grey);
    }

    &:hover, &:active {
        background: var(--c-border-grey);

        .#{$component}__draggable-icon {
            display: block;
        }
    }

    &__employee-name {
        @extend %text-ellipsis;

        justify-self: flex-start;
        margin-right: auto;
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
    }

    &__department {
        margin-left: remCalc(1);
        text-align: right;
    }

    &__shift-planning-time {
        margin-left: 5%;
        min-width: remCalc(5);
    }

    &__draggable-icon {
        display: none;
        position: absolute;
        bottom: remCalc(.125);
        left: calc(50% - #{remCalc(.5)});
        width: remCalc(.75);
        height: remCalc(.75);
    }
}
