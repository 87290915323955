@import "../../style/theme.scss";

.feedback {
    display: flex;
    flex-direction: column;

    &-mobile-padding {
        @media only screen and (max-width: $break-mobile) {
            padding: $main-padding $main-padding 0;
        }
    }

    &-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #666;
    }

    &-list {
        position: relative;
    }

    &__feedback-help-tooltip {
        position: absolute;
        top: remCalc(1);
        left: remCalc(16);

        @media only screen and (max-width: $break-mobile) {
            top: remCalc(4);
        }
    }

    &__filters-help-tooltip {
        position: absolute;
        top: remCalc(3);
        left: remCalc(-8);
    }

    &__dissatisfaction-help-tooltip {
        position: absolute;
        top: remCalc(19);
        left: remCalc(1);

        @media only screen and (max-width: $break-mobile) {
            top: remCalc(3);
            left: remCalc(-8);
        }
    }
}
