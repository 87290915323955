@import "../../style/theme.scss";

.form-date-picker {

  // desktop
  .react-datepicker-wrapper {
    @media only screen and (max-width: $break-mobile) {
      display: none;
    }
  }

  &-mobile {
    display: none;

    @media only screen and (max-width: $break-mobile) {
      display: block;
    }
  }

}