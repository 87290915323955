@import 'src/@paco/styles';

.users-select-option {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: remCalc(.5);

    &--is-company-medic &__name {
        color: var(--c-red);
    }

    &__name {
        justify-self: flex-start;
        margin-inline-end: auto;
    }

    &__hours {
        margin-inline-start: remCalc(.25);
        font-weight: var(--fw-medium);
        white-space: nowrap;
    }
}
