@import "src/@paco/styles";

.time-mode-select {
    display: flex;
    align-items: center;

    &__label {
        font-weight: var(--fw-medium);
    }

    &__select {
        margin-left: remCalc(1);
        width: remCalc(8.125);
    }
}
