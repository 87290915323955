@import "src/@paco/styles";

.manage-district-division-template-groups-order-form {
    @extend %form;

    padding-top: remCalc(1.5);

    &__row {
        @extend %form-row;
    }

    &__intro,
    &__group-draggables-list {
        @extend %form-col;
    }

    &__group-draggables-list {
        list-style: none;
        margin: remCalc(1) 0 0;
        width: 100%;
    }

    &__group-draggables {
        margin-top: remCalc(1);
    }
}
