@import "src/@paco/styles";

.user-profile-card {
    column-count: 1;
    padding: remCalc(.5) remCalc(2) remCalc(2);

    @include for-size(tablet-landscape-up) {
        column-count: 2;
    }

    @include for-size(laptop-up) {
        column-count: 3;
    }

    &__edit-button {
        position: absolute;
        top: remCalc(2);
        right: remCalc(2);
        color: var(--c-light-blue);
    }
}
