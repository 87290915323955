@import 'src/@paco/styles';

$component: 'icon-nav-link';

.#{$component} {
    @extend %button;

    display: flex;
    align-items: center;

    &:hover, &:focus {
        text-decoration: none;
    }

    &--align-right {
        flex-direction: row-reverse;

        .#{$component}__icon {
            margin-right: 0;
            margin-left: remCalc(.625);
        }
    }

    &__icon {
        display: block;
        max-width: 1em;
        max-height: 1em;
        margin-right: remCalc(.625);
    }

    &--hidden-label {

        .#{$component}__icon {
            margin: 0;
        }
    }

    &--is-primary {
        @extend %button--is-primary;
    }
}
