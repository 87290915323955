@import "src/@paco/styles";

.permissions-list {
    position: relative;
    padding: 0 remCalc(2.5) remCalc(2.5);

    &__toggle {
        display: flex;
        justify-content: flex-end;
        margin-bottom: remCalc(.5);
    }

    &__checkbox-group {
        border-bottom: 1px solid var(--c-border-grey);
        margin-bottom: remCalc(.925);
    }

    &__group-label {
        margin-bottom: remCalc(.925);
        font-weight: var(--fw-medium);
    }

    .checkbox-group-list__item + &__checkbox-label {
        margin-bottom: remCalc(.925);
    }

    &__empty-state {
        border: unset;
    }
}
