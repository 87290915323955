@import "../../../../style/theme";

.button-schedule {
  @include clear-button();

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
  width: 100%;
  height: 2rem;
  line-height: 1.5;
  font-weight: 600;
  font-size: 1rem;
  color: $orange;

  @media only screen and (max-width: $break-medium) {
    font-size: 0.875rem;
  }

  &:hover, &:focus {
    color: $orange-dark;
  }

  &__icon {
    margin-right: 0.625rem;
  }
}
