@import "src/@paco/styles";

.export-leave-of-absences-form-radio-list {

    &__option-wrapper {
        display: flex;
    }

    &__radio {
        position: relative;
        display: flex;
        justify-content: center;
        border: 1px solid var(--c-disabled-grey);
        border-left: none;
        border-radius: var(--input-border-radius);
        margin: 0;
        width: 100%;
        height: remCalc(2.5);
        padding: 0 remCalc(1.25);
        font-size: remCalc(.9375);
        font-weight: var(--fw-medium);
        color: var(--c-black);

        &--is-selected {

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                background: var(--c-light-blue);
            }
        }

        &:hover {
            background: var(--c-lighter-blue);
        }

        &:first-of-type {
            border-left: 1px solid var(--c-disabled-grey);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-of-type {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__radio-label {
        margin-left: 0;
    }

    &__radio-box {
        display: none;
    }
}
