@import "src/@paco/styles";

.contract-hours-input {
    padding-block-end: remCalc(1);

    &__summary {
        width: fit-content;
    }

    &__all-contract-hours {
        display: flex;
        flex-direction: column-reverse;
    }

    &__add-button-wrapper {
        @extend %form-row;
    }

    &__add-button {
        @extend %form-col;

        justify-content: flex-start;
        max-width: 50%;
    }
}
