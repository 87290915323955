@import "../../style/theme.scss";

.exc-request-card {
  height: fit-content;

  &__replies {
    margin-bottom: 1.25rem;

    @media only screen and (max-width: $break-mini) {
      margin-bottom: 0.625rem;
    }
  }

  &__title {
    margin-bottom: 0.75rem;
    font-weight: 600;
  }

  &__replies {
    margin-bottom: 0;
  }

  &__no-replies {
    margin-bottom: 0.625rem;
    color: $grey-disabled;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 600;
    text-align: right;
  }

  &__date, &__time {
    font-weight: 600;
    text-align: right;
  }

  &--has-button-history {
    .exc-request-card-top {
      margin-bottom: 0.625rem;
    }
  }
}
