@import "src/@paco/styles";

.district-division-comment {
    border: 1px solid var(--c-border-grey);
    border-radius: var(--table-border-radius);
    padding: remCalc(1);
    background: var(--c-white);

    &--is-loading &__loading-spinner {
        display: block;
    }

    &--is-successful &__check-icon {
        display: block;
    }

    &__title {
        font-size: remCalc(1.125);
        font-weight: var(--fw-medium);
        color: var(--c-dark-blue);
    }

    &__textarea-wrapper {
        position: relative;
    }

    &__textarea {
        margin-top: remCalc(1);
    }

    &__loading-spinner,
    &__check-icon {
        display: none;
        position: absolute;
        bottom: remCalc(1);
        right: remCalc(1);
        opacity: .5;
    }

    &__check-icon {
        color: var(--c-green-success);
    }
}
