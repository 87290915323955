@import "../../style/theme.scss";

$component: radio-buttons-list;
$borderStyle: 1px solid $grey-disabled;

.#{$component} {
  display: flex;
  justify-content: stretch;
  width: 100%;

  &__input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__label {
    @include clear-button();
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: $borderStyle;
    border-left: none;
    border-radius: 2px;
    margin: 0;
    padding: 10px 20px;
    font-weight: 600;
    text-align: center;
    color: $black;
    cursor: pointer;
    overflow: hidden;

    &--hidden {
      visibility: hidden;

      & + .#{$component}__label {
        border-left: $borderStyle;
      }
    }

    &:first-of-type {
      border-left: $borderStyle;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__label:hover {
    background: lighten($light-blue, 27%);
  }

  &__label--active {
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: $light-blue;
    }
  }

  &--length-2 .#{$component}__label {
    width: 50%;
  }

  &--length-3 .#{$component}__label {
    width: 33.33%;
  }

  &--length-4 .#{$component}__label {
    width: 25%;
  }

  &--length-5 .#{$component}__label {
    width: 20%;
  }
}
