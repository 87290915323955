@import "../../../../style/theme.scss";

$component: complete-period-step;

.#{$component} {

  &__title {
    margin-bottom: 0.625rem;
    font-weight: 600;
  }

  &__text {
    margin-bottom: 2rem;
  }

  &__link {
    display: flex;
    color: $orange;
    font-weight: 600;

    &:hover {
      text-decoration: none !important;
    }
  }

  &__buttons-container {
    display: flex;
  }

  &__button {
    @include clear-button();

    display: flex;
    align-items: center;
    font-weight: 600;
    color: $orange !important;
    background: none !important;

    &.disabled {
      color: $grey-disabled !important;
    }

    &:hover {
      color: $dark !important;
    }

    & + & {
      margin-left: 1.5rem;
    }
  }

  &__tooltip {
    margin: 0 1rem;

    .#{$component}__button {
      color: $orange;

      &:hover {
        color: $dark;
      }
    }
  }

  &__button-text {
    margin-left: 0.5rem;
  }

  &__button-lock-icon {
    margin-right: 0 !important;
    margin-left: 0.5rem;
  }

  &--is-locked {
    .#{$component}__link, .#{$component}__button {
      color: $grey !important;
      pointer-events: none;
    }
  }
}
