@import "src/@paco/styles";

.add-comment-form {
    @extend %form;

    &__row {
        display: flex;
    }

    &__col {
        @extend %form-col;
    }
}
