@import "../../../../../../style/theme.scss";

.satisfaction-table {
  &__td-satisfaction {
    &-text {
      display: inline-flex;
      margin: 0.375rem 0 0 0.375rem;
    }

    .info-circle {
      border: none;
    }
  }
}