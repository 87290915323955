@import "src/@paco/styles";

.widget-label {
    display: flex;
    padding-right: remCalc(.5);

    &__text {
        @extend %text-ellipsis;
    }

    &__pz-label {
        border: 1px solid var(--c-cyan);
        border-radius: remCalc(3.75);
        margin-left: remCalc(.5);
        padding: 0 remCalc(.5);
        color: var(--c-cyan);
    }
}
