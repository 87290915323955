@import "src/@paco/styles";

.employment-dates-input {

    &__row {
        @extend %form-row;

        flex-direction: column;
    }

    &__col,
    &__input-col {
        @extend %form-col;
    }

    @include for-size(tablet-portrait-up) {

        &__row {
            flex-direction: row;
        }
    }
}