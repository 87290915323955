@import "src/@paco/styles";

.group-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: remCalc(2.5);
    padding: remCalc(.75);
    background: var(--c-light-grey);
    font-size: remCalc(.75);

    &__label {
        margin-right: remCalc(.5);
    }
}
