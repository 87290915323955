@import "src/@paco/styles";

.shifts-widget-department {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    text-align: left;
    overflow: hidden;

    &__group {
        width: 100%;
        font-weight: var(--fw-medium);
    }

    &__department {
        width: 100%;
    }

    &__divider {
        margin: 0 remCalc(.25);
    }
}
