@import "src/@paco/styles";

$component: 'radio';

.#{$component} {
    display: flex;
    align-items: center;
    font-size: remCalc(1);
    font-weight: var(--fw-medium);
    color: var(--c-black);
    cursor: pointer;

    &--is-disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus:not(:focus-visible) ~ .#{$component}__label {
            text-decoration: none;
        }

        &:focus ~ .#{$component}__label {
            text-decoration: underline;
        }

        &:checked  ~ .#{$component}__box::after {
            transform: scale(1);
        }
    }

    &__box {
        @extend %flex-align-center;
        @include circle(remCalc(1.25));

        border: 1px solid var(--c-black);

        &::after {
            @include circle(remCalc(.875));

            content: "";
            background-color: var(--c-primary);
            transform: scale(0);
            transition: transform .1s ease;
        }
    }

    &__label {
        margin-left: remCalc(.75);
    }
}
