@import "../../style/theme.scss";

.user-profile {
  display: flex;

  &-info--no-department {
    display: flex;
    flex-direction: column;
    justify-content: center;

    div:last-child {
      margin-bottom: 0;
    }
  }

  &-name {
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 4px;

    &-link {
      margin-right: 7px;
      line-height: 1.267;
      font-size: 15px;
      font-weight: 800;
      color: $dark;
    }
  }

  &-department {
    line-height: 1.2;
    font-weight: 500;
  }

  &-group {
    display: inline;
    margin-right: 3px;
    font-weight: 600;
  }

  &__contract-hours {
    margin-left: 0.5rem;
  }
}