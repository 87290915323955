@import "src/@paco/styles";

.printable-district-division-district-planning {
    @extend %reset-list-item;

    border: 1px solid var(--c-border-grey);
    border-bottom: 0;
    padding: remCalc(.25);
    height: remCalc(1.875);
    color: var(--c-primary);

    &--has-comment {
        height: auto;
    }

    &:first-of-type {
        border-radius: 10px 10px 0 0;
    }

    &:last-of-type {
        border-radius: 0 0 10px 10px;
        border-bottom: 1px solid var(--c-border-grey);
    }

    &:only-of-type {
        border-radius: 10px;
    }

    &__header {
        display: flex;
        padding-right: remCalc(.25);
        width: 100%;
    }

    &__order {
        @extend %flex-align-center;

        border: 2px solid var(--c-primary);
        border-radius: 4px;
        line-height: 2;
        width: remCalc(1.25);
        min-width: remCalc(1.25);
        height: remCalc(1.25);
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__employee-name {
        @extend %text-ellipsis;

        margin: 0 remCalc(.5);
        line-height: 1.375;
        font-weight: var(--fw-medium);
    }

    &__time {
        float: right;
        margin-left: auto;
        width: remCalc(5);
        white-space: nowrap;
    }

    &__comment {
        margin: 0;
        padding-left: remCalc(1.75);
        font-style: italic;
    }
}
