@mixin clear-button {
  border: none;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

@mixin flex-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin button {
  min-width: 6rem;
  height: 39px;
  border-radius: 2px;
  font-size: 15px;
  font-weight: 600;

  &.disabled, &:disabled {
    background: $grey-disabled;
    color: $white;
    opacity: 1;
  }

  i {
    display: inline-block;
    margin-right: 0.4375rem;
  }
}

@mixin button-plain {
  @include clear-button();
  line-height: 1.2667;
  font-size: 15px;
  font-weight: 600;
  color: $orange;
  text-align: left;

  &-orange {
    color: $orange;

    &:hover {
      color: darken($orange, $button-hover-darken);
    }
  }
}

@mixin calendar-item {
  @include clear-button();
  margin-bottom: 4px;
  border-radius: 3px;
  text-align: left;
  width: 100%;
  min-height: 39px;
  padding: 4px 6px 5px;
  font-size: 14px;
  color: white;
  background-color: $placeholder;
  backface-visibility: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
