@import "src/@paco/styles";

$component: leave-of-absences-overview-table-block;

.#{$component} {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    font-size: remCalc(.75);
    color: var(--c-white);
    pointer-events: none;
    z-index: 1;

    & + & {
        margin-top: remCalc(.375);
    }

    &--is-open {
        color: var(--c-orange);

        .#{$component}__block {
            border: 1px solid var(--c-orange);
            background: var(--c-white);
        }
    }

    &--starts-in-calendar {

        .#{$component}__block-wrapper {
            padding-left: calc(var(--leave-of-absences-overview-table-cell-width) * .25);
        }

        .#{$component}__block {
            border-top-left-radius: remCalc(.125);
            border-bottom-left-radius: remCalc(.125);
        }
    }

    &--ends-in-calendar {

        .#{$component}__block-wrapper {
            padding-right: calc(var(--leave-of-absences-overview-table-cell-width) * .25);
        }

        .#{$component}__block {
            border-top-right-radius: remCalc(.125);
            border-bottom-right-radius: remCalc(.125);
        }
    }

    &--is-1-length {

        .#{$component}__block-wrapper {
            padding-left: calc(var(--leave-of-absences-overview-table-cell-width) * .125);
        }

        .#{$component}__block-wrapper {
            padding-right: calc(var(--leave-of-absences-overview-table-cell-width) * .125 + 1px);
        }
    }

    &--is-1-length:not(.#{$component}--starts-in-calendar) {

        .#{$component}__block-wrapper {
            padding-left: 0;
        }
    }

    &--is-1-length.#{$component}--starts-in-calendar:not(.#{$component}--ends-in-calendar) {

        .#{$component}__block-wrapper {
            padding-right: 0;
        }
    }

    &__block-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__block {
        @extend %text-ellipsis;

        border: 1px solid transparent;
        width: 100%;
        height: remCalc(1.25);
        padding-left: remCalc(.25);
        background: var(--c-orange);
        white-space: nowrap;
    }
}
