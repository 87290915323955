@import "../../style/theme.scss";

.special-day-tooltip {
  display: flex;
  justify-content: center;
  width: 1rem;
  height: 0.875rem;
  margin-right: 0.25rem;

  @media only screen and (max-width: $break-mobile) {
    width: 1.625rem;
    height: auto;
    margin-bottom: 0.125rem;

    img {
      width: 1.625rem !important;
      height: 1.25rem !important;
    }
  }
}
