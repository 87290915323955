@import "src/@paco/styles";

.pz-logs {
    width: 100%;
    padding: var(--page-padding);

    @include for-size(tablet-portrait-up) {
        width: calc(100% - #{var(--sidebar-width)});
    }

    &__empty-state,
    &__table {
        margin-top: remCalc(1);
    }

    &__empty-state {
        border-radius: var(--table-border-radius);
        overflow: hidden;
    }

    &__pagination--is-loading {
        opacity: .5;
        pointer-events: none;
    }
}
