@import '../helpers/functions';

%page-title {
    line-height: 1.25;
    font-size: remCalc(1.25);
    font-weight: var(--fw-medium);
    color: var(--c-dark-blue);
}

%modal-title {
    @extend %page-title;

    font-size: remCalc(1.5);
}
