@import "../../../../style/theme";

.table-action-buttons {
  display: flex;

  &__button {
    @include clear-button();

    & + & {
      margin-left: 0.25rem;
    }
  }
}
