@import "src/@paco/styles";

.input {
    @extend %input;

    &--has-error {
        border: 1px solid var(--c-warning);
    }

    &::placeholder {
        color: var(--c-grey);
    }
}
