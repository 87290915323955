@import "src/@paco/styles";

.error-label {
    font-size: remCalc(.875);
    font-weight: var(--fw-medium);
    color: var(--c-red);

    &::first-letter {
        text-transform: uppercase;
    }
}
