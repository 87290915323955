@import "../../../style/theme.scss";

$break-medium: 1320px;

.app .single-date-navigator {
  position: relative;
  margin-left: 0;
  width: auto;

  & ~ * {
    margin-left: 20px;

    @media only screen and (max-width: $break-medium) {
      margin-left: 10px;
    }
  }

  &__button-picker {
    display: flex;
  }

  &__title {
    margin-right: 12px;

    @media only screen and (max-width: $break-medium) {
      margin-right: 6px;
      width: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @media only screen and (max-width: remCalc(75)) {
      display: none;
    }
  }

  &__arrow-down {
    display: inline;
  }

  &__daypicker {
    position: absolute;
    top: 2.25rem;
    left: -3px;
  }
}
