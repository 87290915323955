@import "../../../../style/theme.scss";

.couplings {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding:  0 20px 20px;
  min-height: 100px;

  &-logo {
    margin: 37px 0;
    width: 198px;
    height: 83px;
  }

  &-description {
    margin: 0 30px;
    width: 100%;
    max-width: 600px;
  }

  &-title {
    margin-bottom: 10px;
    font-weight: 600;
  }

  @media only screen and (max-width: $break-mobile) {
    flex-direction: column;

    .couplings-description {
      margin: 0 0 30px;
    }
  }
}
