@import "src/@paco/styles";

.add-department-group-button {
    display: flex;
    align-items: center;
    font-size: remCalc(.8125);
    font-weight: var(--fw-medium);
    color: var(--c-cyan);

    &:hover {
        color: var(--c-dark-blue);
    }
}
