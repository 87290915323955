@import "src/@paco/styles";

.list-view-table-head-cell {
    width: remCalc(6.25);

    &__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
    }

    &__name {
        margin-right: remCalc(.625);
    }

    &__tooltip {
        display: inline-block;
        width: remCalc(1);

        &:not(:last-of-type) {
            margin-right: remCalc(.25);
        }
    }
}
