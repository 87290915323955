@import "../../style/theme.scss";

.management {
  &-list {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $break-mobile) {
      padding-top: $main-padding;
    }
  }

  &-table tbody tr {
    cursor: pointer;

    &.hover {
      td:nth-last-child(n+2) {
        background: $primary;
        color: $white;

        .icon {
          color: $white;
        }
      }
    }

    .td-edit {
      &:hover {
        background: $primary;

        i {
          color: $white;
        }
      }
    }
  }

  &-table-departments {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    min-width: 300px;
    max-width: 800px;
  }

  &-portrait-small {
    width: 60px;

    div {
      border-radius: 50%;
      width: 37px;
      height: 37px;
      background-size: cover;
    }
  }
}

.groups {
  &-table {
    min-width: 400px;
  }

  &-table.table {
    thead tr th {
      &:nth-child(1), &:nth-child(2) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    tbody tr td {
      &:nth-child(1), &:nth-child(2) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }

  &-form-department, &-form-edit-groups, &-form-viewer {
    max-width: 561px;
  }

  &-form-edit-groups {
    .form-control-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-bottom: 10px;
    }

    .groups-button-delete {
      @include clear-button();
      margin-left: 10px;
      height: 100%;
    }

    .groups-button-add-groups {
      font-weight: 300;
    }
  }

  &-button-manage-groups {
    @media only screen and (max-width: $break-medium) {
      font-size: 14px;
    }
  }
}

.employment {
  &-profile-photo {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 120px;
    width: 200px;
    max-width: 100%;
    background-color: $grey-disabled;
  }

  &-profile-text {
    color: $orange;
    font-weight: 400 !important;
  }
}
