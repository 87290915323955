@import "theme";

.form-check {
    line-height: 2;
    cursor: pointer;
    z-index: 0;

    .custom-control-input[type="checkbox"] + label {
        line-height: 1.25;
        padding-top: 6px;
        color: $dark;

        div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:after {
            top: 8px;
        }

        &:before {
            border-radius: 2px;
            border-color: #CACACA;
            top: 8px;
        }
    }

    .custom-control-input[type="checkbox"]:focus + label {
        &:before {
            border-color: $primary;
        }
    }

    .custom-control-input[type="checkbox"]:checked + label {
        &:before {
            border-color: $primary;
            background-color: $primary;
        }
    }

    input[type="radio"] {
        margin-top: 8px;
    }

    &-button-expand {
        @include clear-button();
        outline: 0;
        flex-grow: 99;
        text-align: right;

        i {
            transform-origin: calc(100% - 12px) 12px;
        }
    }

    &--expandable {
        label {
            display: flex;
            width: 100%;
        }

        & + .children {
            border-bottom: 1px solid $border-grey;
            margin-bottom: 5px;
            padding-bottom: 5px;
            height: 0;
            overflow: hidden;
        }
    }

    &--expandable.form-check--expanded {
        & + .children {
            height: auto;
        }

        .form-check-button-expand i {
            transform: rotate(180deg);
        }
    }
}

.custom-control-label:before {
    background: white;
    border: 1px solid $border-grey;
    z-index: 0;
}

.custom-checkbox.form-check {
    margin-bottom: 6px;
    padding-left: 22px;

    & + .children {
        padding-left: 20px;
    }
}

.custom-checkbox.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.custom-checkbox.form-check-inline {
    width: 100%;
    margin-right: 0;
}

.custom-checkbox .form-check-input {
    position: absolute;
}

.custom-checkbox .custom-control-label:before {
    border-radius: 0.125rem;
}

.custom-control-input:checked ~ .custom-control-label:before {
    background: $primary;
    border-color: $primary;
}

.custom-control-input:active~.custom-control-label::before {
    color: $white;
    background-color: $dark;
}

.custom-control-label:hover::before {
    border-color: $dark;
}

.custom-control-label:hover {
    cursor: pointer;
}

.form-check.disabled {
    pointer-events: none;
    opacity: 0.5;
}
