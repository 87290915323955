@import "../../../../style/theme.scss";
@import "src/@paco/styles";

.settings-top-bar {
    display: flex;
    justify-content: space-between;

    &-buttons {
        display: flex;
        flex-direction: row-reverse;
    }

    &-buttons button:nth-child(2) {
        margin-right: $main-padding;
    }
}

.settings-title {
    margin: 10px 0 $main-padding 10px;
    line-height: 1.25;
    font-size: 20px;
    font-weight: 600;
    color: $primary;
}

.settings-radios {
    &-form {
        display: flex;

        .form-check {
            margin-right: 20px;
        }
    }
}

.settings-breaks {
    position: relative;
    width: 100%;

    .form-control-group {
        display: flex;
        margin-bottom: 5px;
        width: 50%;
    }

    .form-check-label {
        width: 120px;
    }

    &-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    &-button-delete {
        @include clear-button();
    }

    &-add-option {
        margin-top: 10px;
        width: 100px;
    }
}

.settings-input-time {
    position: relative;

    .form-check {
        padding-left: 0;
    }

    &-form {
        display: flex;
    }

    &-option {
        margin-right: 7.5%;
    }
}
