@import "src/@paco/styles";

.table-cell {
    @include column-width(14rem);

    display: flex;
    align-items: center;
    justify-content: left;
    border-left: 1px solid var(--c-border-grey);
    height: 100%;
    padding: 0;

    &--align-left {
        justify-content: flex-start;
        text-align: left;
    }

    &--align-center {
        justify-content: center;
        text-align: center;
    }

    &--align-right {
        justify-content: flex-end;
        text-align: right;
    }

    &__content-wrapper {
        display: inline-flex;
        max-height: 100%;
        padding: remCalc(1);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
