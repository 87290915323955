@import "src/@paco/styles";

.checkbox-list {

    &__label {
        margin-top: remCalc(.375);
    }

    &__options-wrapper {
        display: flex;
        flex-direction: column;

        &--has-error {
            padding-left: remCalc(.75);
            border-left: 2px solid var(--c-negative);
        }
    }

    &__option {

        & + & {
            margin-bottom: remCalc(.375);
        }
    }

    &__error-label {
        margin-top: remCalc(.5);
    }
}
