@import "src/@paco/styles";

.district-division-templates-table {

    &__empty-state {
        border-radius: var(--table-border-radius);
    }

    &__cell {
        z-index: 5;

        &:nth-of-type(4) {
            @include column-width(6rem);

            margin-left: auto;
            margin-right: remCalc(.75);
        }
    }

    &__hidden-cell {
        @include column-width(0);

        border: none;
        z-index: 0;

        .table-cell__content-wrapper {
            padding: 0;
        }
    }

    &__edit-icon, &__delete-icon {
        color: var(--c-light-blue);
    }

    &__delete {
        margin-left: remCalc(1);
    }

    &__redirect-button {
        @extend %click-extend;
    }
}
