@import "src/@paco/styles";

.user-detail-list-item {
    @extend %reset-list-item;

    display: flex;
    position: relative;
    padding: remCalc(.25);
    padding-right: remCalc(.625);
    font-size: remCalc(.9375);

    &__value {

        &--is-string {
            word-break: break-all;
        }
    }

    &__title {
        margin-right: remCalc(.5);
        min-width: 50%;
        font-weight: var(--fw-medium);
        word-break: break-word;
    }
}
