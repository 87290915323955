@import "src/@paco/styles";

$component: calendar-day-content;

.#{$component} {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: remCalc(8.375);
    background-color: var(--c-white);
    border: 1px solid var(--c-border-grey);
    border-top: none;

    @include for-size(tablet-portrait-up) {
        border-right: 0;
        border-bottom: 0;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 remCalc(.25);
        height: remCalc(1.625);
        font-size: remCalc(.9375);
    }

    &__header-label {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: flex-start;
        margin-right: auto;
        border-radius: remCalc(1.25);
        padding: remCalc(.125) remCalc(.5);
        width: fit-content;
        height: remCalc(1.25);
        font-size: remCalc(.8125);
        background: var(--c-white);
        color: var(--c-primary);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__special-day-icon {
        display: flex;
        margin-right: remCalc(.25);
        height: remCalc(1);
    }

    &__children {
        width: 100%;
        padding: 0 remCalc(.25) remCalc(.25);
    }

    &__actions {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        flex-grow: 1;
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }

    &--is-today {
        background: var(--c-lighter-grey);

        .#{$component}__header-label {
            color: var(--c-white);
            background: var(--c-concept-dark-blue);
        }
    }

    &--is-dark {
        border-color: var(--c-concept-border-grey);
        background: var(--c-concept-dark-blue);

        &.#{$component}--is-today {
            background: var(--c-concept-darker-blue);
        }

        .#{$component}__header {
            background: none;
        }

        .#{$component}__header-label {
            background: var(--c-white);
            color: var(--c-primary);
        }

        .#{$component}__header-day {
            color: var(--c-white);
        }

        .#{$component}__children {
            margin-top: 0;
        }
    }
}
