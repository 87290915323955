@import "src/@paco/styles";

$component: shifts-widget-shift-skeleton;

.shifts-widget-shift-skeleton {
    display: grid;
    grid-template-columns: 3fr 1fr 1.5fr 1fr;
    gap: remCalc(.5);
    width: 100%;

    @include for-size(tablet-portrait-up) {
        grid-template-columns: auto remCalc(14) remCalc(5) remCalc(8);
        gap: remCalc(1.5);
    }

    &__skeleton {
        height: remCalc(.75);
        opacity: .5;
        background-color: var(--c-grey);
    }

    &__shift-time-skeleton-wrapper,
    &__track-time-skeleton-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__employee-skeleton,
    &__department-skeleton,
    &__shift-time-skeleton,
    &__track-time-skeleton {
        width: 100%;
    }

    &--has-index-1 {

        .#{$component}__employee-skeleton {
            width: 50%;

            @include for-size(tablet-portrait-up) {
                width: 25%;
            }
        }

        .#{$component}__department-skeleton {
            width: 75%;
        }

        .#{$component}__shift-time-skeleton {
            width: 85%;
        }

        .#{$component}__track-time-skeleton {
            width: 90%;
        }
    }

    &--has-index-2 {

        .#{$component}__employee-skeleton {
            width: 70%;

            @include for-size(tablet-portrait-up) {
                width: 35%;
            }
        }

        .#{$component}__department-skeleton {
            width: 95%;
        }

        .#{$component}__shift-time-skeleton {
            width: 90%;
        }

        .#{$component}__track-time-skeleton {
            width: 100%;
        }
    }

    &--has-index-3 {

        .#{$component}__employee-skeleton {
            width: 40%;

            @include for-size(tablet-portrait-up) {
                width: 20%;
            }
        }

        .#{$component}__department-skeleton {
            width: 50%;
        }

        .#{$component}__shift-time-skeleton {
            width: 100%;
        }

        .#{$component}__track-time-skeleton {
            width: 85%;
        }
    }
}
