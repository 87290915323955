@import "src/@paco/styles";

.leave-of-absences-overview-filter-container {

    &__legend {
        position: fixed;
        bottom: 0;
        width: remCalc(13.5);
        z-index: 10;
    }
}
