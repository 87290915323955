@import "src/@paco/styles";

.district-division-district-item {
    border: 1px solid var(--c-border-grey);
    border-radius: var(--table-border-radius);
    background: var(--c-white);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--c-border-grey);
        padding: remCalc(.5) remCalc(1);
        font-size: remCalc(1.125);
        font-weight: var(--fw-medium);
        color: var(--c-dark-blue);
    }

    &__plannings {
        padding: remCalc(.75) remCalc(1);
    }

    &__planning + &__planning {
        margin-top: remCalc(.375);
    }
}
