@import "src/@paco/styles";

.emergency-contact-input {
    &__row {
        display: inline;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__label {
        padding: remCalc(.375) remCalc(1) 0;
    }

    &__col {
        @extend %form-col;

        flex: 2;

        .input-label {
            font-weight: var(--fw-regular);
        }
    }
}
