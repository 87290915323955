@import "../../style/theme.scss";

.week-hours-inputs {

  &__checkbox {
    margin-bottom: 0.5rem;
    padding-left: 0;
  }

  &--error:not(:last-child) {
    margin-bottom: 1rem;
  }
}
