@import "theme";

.card {
  width: 100%;

  &-body {
    display: flex;
    flex-direction: column;
  }
}

.request-card {
  border-radius: 10px;
  padding: 25px 23px 30px;

  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media only screen and (max-width: $break-mini) {
      flex-direction: column-reverse;
      margin-bottom: 10px;
    }

    &__title {
      display: inline-flex;
      margin-top: 0.5rem;
      margin-bottom: 1.25rem;
      color: $cyan-blue;
      font-weight: 600;
    }
  }

  &-left {
    display: block;
    align-items: center;
  }

  &-name {
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 4px;

    span {
      margin-right: 7px;
      line-height: 1.267;
      font-size: 15px;
      font-weight: 800;
      color: $dark;
    }
  }

  &-pic {
    border-radius: 50%;
    margin-right: 10px;
    min-width: 37px;
    height: 37px;
    background: url('../images/no-profile-image.jpg');
    background-size: 100% 100%;
  }

  &-right {
    line-height: 1.267;
    text-align: right;
    font-size: 15px;

    @media only screen and (max-width: $break-mini) {
      margin-bottom: 10px;
    }
  }

  &__requested-at {
    display: flex;
    align-items: center;
    margin: 0.5rem 0 1.25rem 0;
    font-weight: normal;
    font-style: italic;
  }

  &__button-history {
    margin-left: 0.5rem;
  }

  &-date {
    margin-bottom: 5px;
  }

  &-title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 15px;
  }

  &-button-calendar {
    @include clear-button();
    display: flex;
    align-items: center;
    justify-self: flex-start;
    align-self: flex-end;
    margin-top: auto;
    margin-right: auto;
    height: 28px;
    color: $light-blue;
    text-decoration: none;

    i {
      display: inline-block;
      margin-bottom: -2px;
    }

    &:hover {
      color: darken($light-blue, $button-hover-darken);
    }
  }

  &-overlapping {
    color: $orange;

    &--length-0 {
      color: $black;
    }
  }
}