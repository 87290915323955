@import "src/@paco/styles";

.knowledge-base-container {
    position: relative;

    &__table {
        border-top: 1px solid var(--c-border-grey);
        padding: remCalc(.5) remCalc(1.75);
    }

    &__empty-state {
        border: 0;
        margin: remCalc(1);
        width: auto;
    }
}
