@import '../../../../style/theme';

$component: publish-shift-concepts-form;

.#{$component} {

  &__intro, &__notify-users-checkbox {
    margin-bottom: 1.25rem;
  }

  &__modal-header {
    z-index: 1;
  }

  &__row-dates {
    position: relative;
    margin-top: remCalc(1);
    z-index: 2;
  }

  &__row-notification-title, &__row-notification-body {
    display: none;
  }

  &--has-notify-users {
    .#{$component}__row-notification-title,
    .#{$component}__row-notification-body {
      display: block;
    }
  }

  &--select-departments-is-open .#{$component}__row-departments {
    position: relative;
    z-index: 3;
  }
}
