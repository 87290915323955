@import "theme";

// If you add className="react-select-container" and / or classNamePrefix="react-select" property
// to react-select then styling by classnames will become available

.react-select-container {}

.react-select, .basic-multi-select {
  &__menu {
    z-index: 2;
  }

  &__group {
    display: flex;
    justify-content: space-between;
    height: 2.625rem;
    padding: 0.75rem;
    background: $light-grey;
  }

  &__single-value {
    width: calc(100% - 1.25rem);
  }

  &__option--is-group {

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $green;
    }
  }

  &__option--has-full-group {
    display: none;
  }

  &__option-label {
    position: relative;
    z-index: 1;
  }

  &--is-invalid .select__control {
    border-color: $red;
  }

  &--is-invalid + .invalid-feedback {
    display: block;
  }
}

.select {

  &__multi-value {
    position: relative;
    flex-direction: row-reverse;
    border-radius: 0.3125rem;
    margin-right: 0.25rem;
    height: 1.4375rem;
    padding-right: 0.375rem;
    font-weight: 600;
    font-size: 0.875rem;
    background-color: $secondary;
    color: $white;
    overflow: hidden;

    &:active {
      border: none !important;
    }
  }

  &__multi-value__remove {
    z-index: 1;
    background: none !important;
    padding-right: 0.125rem !important;

    &:hover {
      border-radius: 0.3125rem !important;
      cursor: pointer;
      color: $white !important;
    }
  }

  &__multi-value__label {
    line-height: 1.3;
    padding-left: 0 !important;
    color: $white !important;
  }

  &__value-container--is-multi {
    padding-top: 4px;
  }

  &__group-heading {
    margin: 0;
    padding: 0;
  }

  &__menu {
    z-index: 2;
  }

  &__clear-indicator svg {
    fill: var(--c-primary);
  }
}
