@import "src/@paco/styles";

.lock-period-form {

    &__title,
    &__intro {
        margin: 0;
        font-size: remCalc(.9375);
    }

    &__title {
        font-weight: var(--fw-medium);

        &:not(:first-child) {
            margin-block-start: remCalc(2);
        }
    }

    &__intro {
        margin-block-start: remCalc(.75);
    }

    &__lock-period-button {
        margin-block-start: remCalc(1);
    }

    &__weeks-list {
        @extend %reset-list;

        margin-block-start: remCalc(1.5);
    }

    &__weeks-list-item {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    &__weeks-list-item + &__weeks-list-item {
        margin-block-start: remCalc(1);
    }
}
