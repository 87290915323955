@import "src/@paco/styles";

.help-tooltip-content {
    --px-offset: 1px;
    --content-opacity: 0;

    padding: remCalc(1.5);
    background: var(--c-white);
    box-shadow: var(--box-shadow);
    opacity: var(--content-opacity);

    transform: translateX(calc(var(--px-offset) * 1px));

    &__title-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__title-step-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: remCalc(.5);
        width: 100%;
    }

    &__title-step {
        margin: 0;
        font-size: remCalc(1.375);
        font-weight: var(--fw-medium);
        color: var(--c-concept-blue);
    }

    &__sub-title {
        margin: remCalc(2) 0 0;
        font-size: remCalc(1);
        font-weight: var(--fw-bold);
        color: var(--c-primary);

        @include for-size(phone-only) {
            margin: remCalc(1) 0 0;
        }
    }

    &__text {
        margin: remCalc(1.5) 0 0;
        line-height: 1.4;
        font-size: remCalc(1);

        @include for-size(phone-only) {
            margin: remCalc(1) 0 0;
        }
    }

    &__video {
        margin-top: remCalc(1.5);
        width: 100%;
    }

    &__buttons-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: remCalc(1.5);
    }

    &__next-button,
    &__prev-button {
        @extend %button--is-primary;

        height: remCalc(2.5);
        background: var(--c-concept-blue);

        &:focus, &:hover {
            background: var(--c-primary);
        }
    }

    &__stop-button-wrapper {
        position: relative;
        top: remCalc(-.625);
        right: remCalc(-.625);
        margin-left: remCalc(.5);
    }

    &__stop-button {
        color: var(--c-concept-blue);

        &-icon {
            @include icon-size(1.5);
        }
    }

    &__mobile-info-warning-wrapper {
        display: flex;
        padding: remCalc(.5);
        margin-top: remCalc(1);
        background-color: var(--c-border-grey);

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__mobile-info-warning-text {
        margin-bottom: remCalc(0);
        line-height: 1.4;
        font-size: remCalc(1);
    }

    &__mobile-info-warning-icon {
        align-self: center;
        min-width: remCalc(1.25);
        height: remCalc(1.25);
        margin-right: remCalc(.5);
        fill: var(--c-concept-blue);
    }
}
