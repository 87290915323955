@import "src/@paco/styles";

.entity-event-logs {
    position: relative;
    padding-block: remCalc(2);
    padding-inline: remCalc(1) remCalc(2);
    overflow-x: auto;

    &--is-loading {
        min-height: 20rem;
    }

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow: hidden;
    }

    &__list {
        @extend %reset-list;
    }

    &__list-item + &__list-item {
        margin-block-start: remCalc(1);
    }
}
