@import "src/@paco/styles";

.toasts-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0 remCalc(1.25) remCalc(1.25) 0;
    word-break: break-word;
    z-index: 1400;

    &__toast {

        & + & {
            margin-top: remCalc(.625);
        }
    }
}
