.main-navigation {
    display: flex;
    height: 100%;

    &__nav-link {

        &--is-active {
            background: var(--c-lighter-grey);
        }
    }
}
