@import "../../style/theme.scss";

.fixed-table-header {
  display: none;
  position: fixed;
  top: $navbar-height;
  z-index: 101;
  width: 100%;
  background: $table-bg;

  &--visible {
    display: flex;
  }
}
