@import './theme';

.day {
  display: flex;
  flex-direction: column;
  width: 14.2857%;
  background: $background;

  @media only screen and (max-width: $break-mobile) {
    width: 100%;
  }

  &--out-of-range {
    .day-header, .day-content-header, .shifts-list {
      opacity: 0.5;
    }

    .day-content {
      color: lighten($dark, 50%);
      background: rgba(255, 255, 255, 0.5);
    }
  }

  &:nth-child(7n) .day-content, &:nth-child(7n) .day-header, &:last-child .day-content, &:last-child .day-header {
    border-right: 1px solid $border-grey;
  }

  &:nth-last-child(-n+8) .day-content:after {
    position: absolute;
    bottom: -1px;
    left: -1px;
    display: block;
    width: calc(100% + 2px);
    height: 1px;
    background: $border-grey;
    content: '';
  }

  &-header {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    font-weight: 600;
    text-align: center;
    border: 1px solid $border-grey;
    border-right: 0;

    &:not(:last-child) {
      border-bottom: 0;
    }
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 99;
    min-height: 125px;
    background-color: $white;
    border: 1px solid $border-grey;
    border-right: 0;
    border-bottom: 0;

    @media only screen and (max-width: $break-mobile) {
      background-color: transparent;
      border-color: transparent;
    }

    &--today {
      background-color: #F3F3F3;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 6px;
        background: $primary;
        content: '';
      }
    }

    &:hover .day-button-add {
      opacity: 1;
    }
  }

  &-content-header {
    display: flex;
    align-items: center;
    padding: 0 8px;

    &-label {
      width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
      color: $white;
      font-size: 13px;

      @media only screen and (max-width: $break-mobile) {
        font-size: 15px;
      }
    }

    &--today {
      background-color: $primary;

      @media only screen and (max-width: $break-mobile) {
        border-radius: 2px;
      }
    }

    &-day {
      margin-left: auto;
      color: $dark;

      &--today {
        color: $white;
      }
    }

    @media only screen and (max-width: $break-mobile) {
      padding: 0.25rem;
      padding-left: 0.5rem;
      margin-top: 0;
    }
  }

  &-content-header-absence {
    margin-top: 0;
  }

  &-content-day {
    padding: 2px 0;
    color: $dark;
    line-height: 1;

    @media only screen and (max-width: $break-mobile) {
      display: none;
    }
  }
}

.is-touch .day-button-add {
  justify-content: flex-end;
  opacity: 1;
}

.col:not(:first-child) .day {

  &__content, &__header {
    border-left: 0;
  }

}
