@import "../../style/theme.scss";

.settings {

    &-sidebar {
        height: 100%;
    }

    &__set-by-department-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(-8);
    }

    &__abnormal-times-help-tooltip {
        position: absolute;
        top: remCalc(1);
        left: remCalc(18);
    }

    &__cao-notifications-help-tooltip {
        position: absolute;
        top: remCalc(1);
        left: remCalc(13);
    }

    @media only screen and (max-width: $break-mobile) {
        &__abnormal-times-help-tooltip {
            top: remCalc(5);
            left: remCalc(13);
        }
    }

    &-content {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $break-mobile) {
            padding: $main-padding;
        }
    }

    .sidebar-page-sidebar {
        height: 100%;

        @media only screen and (max-width: $break-mobile) {
            background: $white;
        }
    }

    &-period {
        .table-container {
            border: 0;
            border-radius: 0;
            margin-bottom: 0;

            &-responsive {
                padding: 0;
            }
        }

        .add-resource-button {
            margin-top: -6px;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;

            &:not(:hover) {
                border: 1px solid transparent;
                border-top-color: $border-grey;
            }
        }

        &--length-0,
        &--length-1 {
            .add-resource-button {
                border-top: 0 !important;
            }
        }

        .items-list-empty {
            position: relative;

            &+.add-resource-button {
                border-top: 1px solid $border-grey;
            }
        }

        &-table-td-year,
        &-table-td-number {
            width: 150px;

            @media only screen and (max-width: 1100px) {
                width: auto;
            }
        }

        &-table-td-edit-delete {
            display: flex;
            column-gap: remCalc(.5);
        }
    }

    &__manager-page-help-tooltip,
    &__timer-page-help-tooltip {
        position: absolute;
        left: remCalc(24);
    }

    &__employee-page-help-tooltip {
        position: absolute;
        left: remCalc(26);
    }

    @media only screen and (max-width: $break-mobile) {
        &__manager-page-help-tooltip,
        &__employee-page-help-tooltip,
        &__timer-page-help-tooltip {
            top: remCalc(4);
            left: unset;
            right: remCalc(9);
        }
    }
}

.form-settings-add-or-edit-period,
.form-settings-delete-period {
    max-width: 500px;
}
