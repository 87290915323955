@import "../../../../style/theme.scss";

$component: exchange-requests;

.#{$component} {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1.25rem;

    @media only screen and (max-width: $break-mobile) {
      padding: 0 $main-padding;
    }
  }

  .#{$component}__card {
    width: calc(50% - #{$main-padding / 2});
    margin-bottom: $main-padding;

    @media only screen and (max-width: $break-medium) {
      width: 100%;
    }

    &:nth-child(odd) {
      margin-right: $main-padding;

      @media only screen and (max-width: $break-medium) {
        margin-right: 0;
      }
    }
  }

  &__filters-help-tooltip {
    position: absolute;
    top: remCalc(3);
    left: remCalc(-8);
  }

  &__exchange-item-help-tooltip {
    position: absolute;
    top: remCalc(1);
    left: remCalc(2);
  }
}
