@import "../../../../style/theme.scss";

.sidebar-nav {
  background: $white;
  padding-bottom: remCalc(.5);

  &:first-child {
    border-bottom: solid $border-grey 1px;
  }

  @media only screen and (max-width: $break-mobile) {
    margin-bottom: 0;
    box-shadow: none;

    &:last-child {
      padding-bottom: remCalc(5);
    }
  }

  &-title {
    padding: remCalc(1.25);
    font-size: remCalc(1);
    line-height: 1.25;
    font-weight: var(--fw-medium);
    color: $dark;

    @media only screen and (max-width: $break-mobile) {
      margin-bottom: 0;
      width: 100%;
    }
  }

  &-button {
    @include clear-button();
    display: block;
    width: 100%;
    padding: remCalc(.625) remCalc(1.25);
    text-align:left;
    font-size: remCalc(1);
    cursor: pointer;
    color: $dark;

    &--active, &:hover {
      background: var(--c-primary);
      color: $white;
    }

    &--disabled {
      pointer-events: none;
    }
  }
}
