@import "src/@paco/styles";

.knowledge-base-page {
    width: 100%;

    &__sidebar {
        width: remCalc(20);
    }

    &__sidebar-content {
        width: remCalc(20);
    }

    &__main {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: calc(100% - #{remCalc(20)});
        }
    }
}
