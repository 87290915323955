@import "src/@paco/styles";

.paco-shift-employee-icon {
    display: flex;
    align-items: center;
    width: remCalc(1);
    height: remCalc(1);

    & + & {
        margin-left: 1px;
    }
}
