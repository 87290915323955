@import "../../../../style/theme.scss";

.filter-block {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:last-child) {
        border-bottom: solid #E1E1E1 1px;
    }

    .form-check-nested {
        padding-left: 1.4375rem;
    }

    &--last-item {
        border-bottom: none;
    }

    &--hide-mobile {
        @media only screen and (max-width: $break-mobile) {
            display: none;
        }
    }

    &__title {
        font-size: 0.9375rem;
        line-height: 1.1875rem;
        font-weight: 600;
    }

    &__collapse-button {
        justify-content: space-between;
        width: 100%;
        padding: remCalc(1.25);

        &:active {
            background-color: var(--c-light-grey);
        }
    }

    &__collapse-button-icon {
        transition: rotate .3s ease;
    }

    &__content {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease;
    }

    &__content-inner {
        padding-inline: 1.25rem;
        padding-block: 1.25rem;
    }

    &--expanded &__content {
        max-height: max-content;
    }

    &--expanded &__collapse-button-icon {
        rotate: 180deg;
    }
}

.filter-block .form-check label div {
    @media only screen and (max-width: $break-medium) {
        width: 5.625rem;
    }

    @media only screen and (max-width: $break-mobile) {
        width: auto;
    }
}

.filter-block .children .form-check label div {
    @media only screen and (max-width: $break-medium) {
        width: 4.375rem;
    }

    @media only screen and (max-width: $break-mobile) {
        width: auto;
    }
}
