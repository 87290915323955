@import "../../../style/theme.scss";

.exclamation-mark-badge {
  cursor: pointer;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    color: $white;
    font-weight: 600;
    background: $orange-light;
    border-radius: 50%;
  }
}