@import "../../../../style/theme";

.log-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: 25px 68px;

  &__loket {
    padding: 1.5rem 2rem 1.5rem 4rem;
  }

  &__mutation {
    margin-right: 10px;
  }

  &__circle {
    position: absolute;
    left: 25px;
    top: calc(50% - 7px);
    box-sizing: border-box;
    height: 17px;
    width: 17px;
    border: 1px solid $cyan-blue;
    border-radius: 50%;
    background: $white;

    &:after {
      position: absolute;
      content: '';
      top: 2px;
      left: 2px;
      width: 11px;
      height: 11px;
      background: $cyan-blue;
      border-radius: 50%;
    }
  }

  &__bold {
    font-weight: 600;
  }

  &__button-collapse {
    margin-left: 10px;
  }

  &__actor {
    display: flex;
    align-items: center;
  }

  &__actor-link {
    margin-left: 6px;
    font-size: 15px;
    font-weight: 500;
    color: $cyan-blue;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 68px;
    width: calc(100% - 136px);
    height: 1px;
    background: $border-grey;
  }

  &__loket:after {
    width: calc(100% - 6.875rem);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 33px;
    width: 1px;
    height: 100%;
    background: $cyan-blue;
  }

  &:first-child:before {
    top: 50%;
    height: 50%;
  }

  &:last-child {
    &:before {
      top: 0;
      height: 50%;
    }

    &:after {
      display: none;
    }

    &:first-child:before {
      display: none;
    }
  }
}
