@import "src/@paco/styles";

$component: leave-of-absences-overview-page;

:root {
    --leave-of-absences-overview-sidebar-width: #{remCalc(13.75)};
}

.#{$component} {

    &__sidebar--is-opened ~ .#{$component}__main {
        width: calc(100vw - var(--leave-of-absences-overview-sidebar-width) - var(--sidebar-width));
    }

    &__main {
        width: calc(100vw - #{remCalc(2)} - var(--sidebar-width));
    }

    &__table {
        width: 100%;
        max-width: fit-content;
        overflow-x: auto;
    }
}
