@import "src/@paco/styles";

$component: numpad-button;

.#{$component} {
    @extend %button;

    border: .125rem solid var(--c-border-grey);
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    font-size: 1.75rem;

    &:hover, &:focus-visible {
        border-color: var(--c-light);
    }

    &:active {
        background-color: var(--c-light);
        border-color: var(--c-primary);
    }

    &--is-backspace,
    &--is-submit {
        background: transparent;
        border: 0;

        &:hover, &:focus-visible {
            color: var(--c-light);
        }

        &:active {
            background: transparent;
            color: var(--c-primary);
        }
    }

    &--is-submit {
        font-size: 1.125rem;
    }

    .#{$component}__backspace-icon {
        width: 2.75rem;
        height: 2.75rem;
    }
}
