@import "mixins";
@import "functions";

// Main
$font-family: 'Source Sans Pro', sans-serif;
$break-mini: 400px;
$break-mobile: 768px;
$break-medium: 900px;
$site-min-width: 275px;
$main-padding: 20px;
$sidebar-width-desktop: remCalc(14);
$sidebar-width-tablet: 12.5rem;
$sidebar-transition-time: .25s;

// Colors
$dark: #0B2730;
$blue: #66B2FF;
$light-blue: #86C8DF;
$super-light-blue: #f5fcff;
$cyan-blue: #498FA7;
$primary: #114D61;
$secondary: #00ACF5;
$white: #fff;
$black: #000;
$light: #ADBECF;
$red: #FF1C25;
$red-dark: #b9191f;
$bordeaux: #DE1820;
$scarlet: #E1484E;
$success: #69C300;
$background: #f1f1f1;
$table-bg: #FBFBFB;
$orange: #FF8100;
$orange-dark: #E57400;
$orange-light: #FF9F19;
$cyan: $secondary;
$green: #26AE60;
$green-available: #E9F7EF;
$green-success: #4BB543;
$green-dark: green;
$green-light: rgba(38, 174, 96, 0.1);

$grey: #666;
$grey-disabled: #CACACA;
$light-grey: #f9f9f9;
$lighter-grey: #F3F3F3;
$border-grey: #E1E1E1;
$medium-grey: #979797;
$placeholder: #ACACAC;
$transparent-grey : rgba(255, 255, 255, 0.1);

$button-hover-darken: 20; // most buttons get darken on hover

// Planning concept colors
$concept-dark-blue: #3D4E5F;
$concept-darker-blue: #334252;
$concept-blue: #3498DB;
$concept-light-blue: #E7F0F5;
$concept-light-orange: #FFF7F0;
$concept-border-grey: #6A7581;

// Card
$card-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);

// Typography
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;
$headings-font-weight: bold;
$headings-color: $dark;

// Navbar
$navbar-padding-y: 0;
$navbar-height: 56px;
$sub-navbar-height: 56px;
$navbar-total-height: #{$navbar-height + $sub-navbar-height};
$nav-link-padding-y: 1.25rem;
$navbar-nav-link-padding-x: 1rem;
$navbar-brand-padding-y: 0;
$navbar-brand-height: 2rem;
$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;

$pagination-color: $dark;
$pagination-active-bg: $dark;
$pagination-active-color: $white;
$pagination-hover-color: $dark;

$modal-inner-padding: 2rem;
$modal-header-padding: 1rem 2rem;

$input-border-radius: 2px;
$custom-select-border-radius: 2px;

$tooltip-arrow-color: $dark;
$tooltip-bg: $dark;

$gray-600: $medium-grey;

// Table
$table-accent-bg: $light-grey;

// Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: $break-mobile + 1px,
        lg: 992px,
        xl: 1280px
) !default;
