@import "src/@paco/styles";

.dots {
    display: flex;
    align-items: center;
    height: 2.75rem;

    &__dot + &__dot {
        margin-left: 2rem;
    }
}
