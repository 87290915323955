@import "../../style/theme.scss";
@import "src/@paco/styles";

.leave-of-absences-calendar {
    width: 100%;
    padding: var(--page-padding);

    @include for-size(tablet-portrait-up) {
        padding: var(--page-padding);
    }

    @media only screen and (max-width: $break-mobile) {
        padding: 0;
    }

    &__legend {
        position: fixed;
        bottom: 0;
        width: $sidebar-width-desktop;
        z-index: 10;
    }


    &__list-top {
        display: flex;
        margin-bottom: var(--page-padding);

        @media only screen and (max-width: 1200px) {
            flex-direction: column;
            margin-bottom: $main-padding;
        }
    }

    &__list-top-right {
        display: flex;
        justify-content: flex-end;
        padding-left: var(--page-padding);

        @media only screen and (max-width: $break-mobile) {
            padding: $main-padding $main-padding 0 0;
        }
    }

    &__view-options-help-tooltip {
        position: absolute;
        top: remCalc(.3);
        right: remCalc(.3);

        @include for-size(tablet-portrait-up) {
            top: remCalc(4);
            right: remCalc(17);
        }
    }

    &__add-leave-of-absence-help-tooltip {
        position: absolute;
        z-index: 953;
        top: remCalc(.3);
        right: remCalc(4.5);

        @include for-size(tablet-portrait-up) {
            top: remCalc(1.5);
            left: remCalc(4);
        }
    }

    &__filters-help-tooltip {
        position: absolute;
        z-index: 952;
        top: remCalc(0);
        left: remCalc(-7);

        @include for-size(tablet-portrait-up) {
            top: remCalc(6);
            left: remCalc(10);
        }
    }

    &__leave-of-absence-item-help-tooltip {
        position: absolute;
        z-index: 951;
        top: remCalc(6);
        left: remCalc(1);

        @include for-size(tablet-portrait-up) {
            left: remCalc(15);
        }
    }
}