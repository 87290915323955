@import "src/@paco/styles";

.dashboard-small-widget-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title-wrapper {
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-right: remCalc(.75);
        max-width: remCalc(1.5);
        fill: var(--c-orange);

        &--is-lock-locked, &--is-lock-open {
            margin-left: remCalc(-.25);
        }
    }

    &__title {
        margin: 0;
        height: remCalc(1.625);
        line-height: 1.25;
        font-size: remCalc(1.25);
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__sub-title {
        display: flex;
        margin: remCalc(.625) 0 0;
    }

    &__sub-title-amount {
        margin: 0 remCalc(.25) 0 0;
        line-height: 1.25;
        font-size: remCalc(1);
        color: var(--c-orange);
        font-weight: var(--fw-bold);
    }

    &__sub-title-label {
        margin: 0;
        line-height: 1.25;
        font-size: remCalc(1);
        font-weight: var(--fw-regular);
        color: var(--c-grey);
        opacity: .75;
    }

    &__button {
        @extend %flex-align-center;
        @include circle(remCalc(2.375));

        border: 1px solid var(--c-border-grey);
        margin-left: remCalc(1);
    }

    &__button-icon {
        max-height: remCalc(.875);
    }

    &__title-skeleton,
    &__sub-title-skeleton {
        opacity: .5;
    }

    &__title-skeleton {
        width: remCalc(2);
        height: remCalc(1.625);
        background: var(--c-primary);
        background: rgba(var(--c-primary-rgb), .5);
    }
}
