@import "../../../../style/theme.scss";

.filter-block-user-types {
  display: flex;
  flex-direction: column;

  .user-type {
    &-establishment-manager {
      order: 1;
    }

    &-human-resources {
      order: 2;
    }

    &-head-manager {
      order: 3;
    }

    &-manager {
      order: 4;
    }

    &-junior-manager {
      order: 5;
    }

    &-viewer {
      order: 6;
    }

    &-employee {
      order: 7;
    }
  }
}
