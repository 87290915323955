@import "src/@paco/styles";

.departments-table {
    position: relative;

    &__loading-spinner {
        top: 0;
        height: 100%;
    }

    &__buttons-cell {
        color: var(--c-medium-grey);
    }

    &__cell:last-child,
    &__buttons-cell {
        width: remCalc(5.375);
        min-width: unset;
    }

    &__cell:nth-child(2) {
        flex-grow: 1;
        max-width: unset;
    }

    &__cell:last-child &__cell-content {
        padding-right: 0;
    }

    &__buttons-cell {
        justify-content: flex-end;
    }

    &__button {
        color: var(--c-light-blue);

        & + & {
            margin-left: remCalc(.5);
        }

        &:hover,
        &:focus {
            color: var(--c-primary);
        }
    }
}
