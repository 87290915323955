@import "../../../../style/theme.scss";

$component: 'assign-shift-to-user-form';

.#{$component} {
  &__user-profile-info, &__intro {
    margin-bottom: 15px;
  }

  &__close-button {
    z-index: 1050;
  }
}
