@import "../../../style/theme";

.complete-period-detail-header-container {

    &__back-button {
        display: inline-flex;
        color: $orange;
        font-weight: 600;

        i {
            margin-right: 0.75rem;
        }

        &:hover {
            text-decoration: none !important;
        }
    }
}
