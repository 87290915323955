@import "../../style/theme.scss";

$break-mini: 400px;

.app .loa-info {
  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    @media only screen and (max-width: $break-mini) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    @media only screen and (max-width: $break-mini) {
      align-self: flex-end;
    }
  }

  &-right-buttons {
    display: flex;
  }

  &-hours-overview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 25%;

    @media only screen and (max-width: $break-mobile) {
      padding-right: 15%;
    }

    @media only screen and (max-width: $break-mini) {
      align-items: center;
      flex-direction: column;
      padding-right: 0;
    }

    > div {
      @media only screen and (max-width: $break-mini) {
        display: flex;
        margin-bottom: 10px;
        text-align: center;

        > div {
          margin-right: 5px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .request-card-title {
      margin-bottom: 0;
    }
  }

  &--has-history-button {
    .loa-info-top {
      margin-bottom: 0;
    }
  }

  &--has-reviewed-by-user {

    .request-card {
      &-date {
        margin-bottom: 0;
      }

      &-reviewed-by-user {
        margin-bottom: 5px;
        font-style: italic;
      }
    }
  }

  &__button-edit {
    @include clear-button();
  }
}
