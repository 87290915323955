.complete-period-detail-open-tracks-container {

    &__table {
        margin-top: 1rem;
    }

    &__loader {
        position: relative;
        top: 0;
        height: 20vh;
        margin: 1rem 0 0 0;
    }
}
