@import "src/@paco/styles";

.calendar-day-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: remCalc(2.5);
    font-weight: 600;
    text-align: center;
    border: 1px solid var(--c-border-grey);
    background: var(--c-table-bg);

    @include for-size(tablet-portrait-up) {
        border-right: 0;
    }

    &:not(:last-child) {
        border-bottom: 0;
    }
}
