@import "../../../../style/theme.scss";

.settings-card-section {
  display: flex;
  padding: 20px 30px 30px;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }

  &--disabled {
    opacity: 0.33;
    pointer-events: none;
  }

  &-left {
    padding-right: $main-padding;
    width: 60%;

    @media only screen and (max-width: 1100px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &-right {
    width: 40%;

    @media only screen and (max-width: 1100px) {
      width: 100%;
      max-width: remCalc(30);
    }
  }

  &-title {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    color: $dark;
  }

  &-text {
    font-size: 13px;
    color: $grey;
  }
}
