@import "src/@paco/styles";

.district-division-template-detail-container {

    &__top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__top-bar-buttons {
        display: flex;
    }

    &__title {
        margin: 0;
        font-weight: var(--fw-medium);
    }

    &__add-group-button,
    &__manage-groups-button {
        @extend %button--is-primary;
    }

    &__back-button {
        @extend %button--is-primary-outline;
    }

    &__back-button-icon {
        width: remCalc(1);
        height: remCalc(1);
        min-width: remCalc(1);
    }

    &__manage-groups-button,
    &__add-group-button {
        margin-left: remCalc(1);
    }

    &__item {
        display: inline-flex;
        flex-direction: column;
        margin-top: remCalc(2);
        width: 100%;

        @include for-size(tablet-landscape-up) {
            width: calc(50% - #{remCalc(1)});

            &:nth-child(odd) {
                margin-left: remCalc(2);
            }
        }

        &-header {
            display: flex;
            border-top: 1px solid var(--c-disabled-grey);
            padding: remCalc(1) 0;
        }
    }

    &__group {
        margin: 0;
        font-weight: var(--fw-medium);
        font-size: remCalc(1.25);
        color: var(--c-primary);
    }

    &__action-buttons {
        display: flex;
        margin-left: auto;
    }

    &__delete {
        margin-left: remCalc(.5);
    }

    &__edit-icon, &__delete-icon {
        color: var(--c-light-blue);
    }

    &__overview-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(10);

        @include for-size(tablet-portrait-up) {
            top: remCalc(9);
            left: remCalc(17);
        }
    }

    &__edit-district-division-group-template-help-tooltip {
        position: absolute;
        top: remCalc(12);
        right: remCalc(7);

        @include for-size(tablet-portrait-up) {
            top: remCalc(16);
        }

        @include for-size(tablet-landscape-up) {
            right: unset;
            left: 40%;
        }
    }

    &__empty-state-help-tooltip {
        position: absolute;
        top: remCalc(9);
        right: remCalc(20);
    }
}
