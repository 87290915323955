@import "src/@paco/styles";

.pz-logs-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
        @extend %page-title;

        margin: 0;
    }

    &__search {
        margin-left: remCalc(1);
    }
}
