@import "src/@paco/styles";

.printable-district-division {
    margin-top: -999999px;
    page: printable-a4;

    @media print {
        margin-top: 0;
    }
}
