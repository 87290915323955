@import "../../../../style/theme.scss";

.employee-leave-and-tvt-saldo {

  &__saldo {
    display: flex;

    & + & {
      margin-top: 0.25rem;
    }
  }

  &__title {
    font-weight: 600;
    width: 7rem;
  }
}
