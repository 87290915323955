@import "src/@paco/styles";

$component: shifts-widget-shift;

.#{$component} {
    display: grid;
    align-items: center;
    grid-template-columns: calc(100% - #{remCalc(12)}) remCalc(6) remCalc(4);
    gap: remCalc(1);
    width: 100%;
    font-size: remCalc(1);
    color: var(--c-primary);

    @include for-size(tablet-landscape-up) {
        grid-template-columns: calc(100% - #{remCalc(26)}) remCalc(9) remCalc(6) remCalc(8);
    }

    @include for-size(desktop-up) {
        grid-template-columns: calc(100% - #{remCalc(30.5)}) remCalc(12) remCalc(6) remCalc(8);
        gap: remCalc(1.5);
    }

    &--not-yet-clocked-in {

        .#{$component}__track-time {
            color: var(--c-medium-grey);
        }
    }

    &--is-company-medic &__user-link {
        color: var(--c-red);
    }

    &__user-info-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        position: relative;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__user-info {
        @extend %flex-align-center;

        justify-content: flex-start;
        width: 100%;
    }

    &__user-link {
        @extend %text-ellipsis;

        min-width: remCalc(5);
        white-space: nowrap;
        font-size: remCalc(1);
        color: var(--c-primary);
    }

    &__comment {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-left: remCalc(1);
        overflow: hidden;

        @include for-size(phone-only) {
            display: none;
        }
    }

    &__experience {
        @extend %badge;

        margin-left: remCalc(.5);
        color: var(--c-primary);
        background-color: var(--c-border-grey);
    }

    &__shift-time,
    &__track-time {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
    }

    &__track-time {
        flex-direction: column-reverse;
        align-items: flex-end;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__department {
        display: none;

        @include for-size(tablet-landscape-up) {
            display: flex;
        }
    }

    &__department-mobile {
        display: flex;

        @include for-size(tablet-landscape-up) {
            display: none;
        }
    }

    &__skeleton {
        opacity: .5;
    }

    &__skeleton-item {
        width: 100%;
        max-width: remCalc(3);
        height: remCalc(.75);
        background-color: var(--c-grey);

        @include for-size(tablet-landscape-up) {
            max-width: inherit;
        }
    }

    .#{$component}__badge {
        display: block;
        width: remCalc(4);
        font-size: remCalc(.75);

        &--is-compact {
            margin-left: remCalc(.25);
        }

        @include for-size(tablet-landscape-up) {
            width: auto;
            font-size: remCalc(.875);
        }
    }
}
