@import "src/@paco/styles";

.legacy-table-row {
    position: relative;

    &--is-clickable:hover {
        cursor: pointer;
        background: var(--c-lighter-blue);
    }

    &__button {
        @extend %click-extend;
    }
}
