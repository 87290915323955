@import '../../../../../../style/theme';

.app .availability-day-content {
  &-header {
    margin-top: 0;
  }

  &-resources {
    padding: 4px;
  }
}
