@import "../../style/theme";

.card {
  border-radius: 0.625rem;
  overflow: hidden;
  background: $white;

  &__header {
    display: flex;
    border-bottom: 1px solid $border-grey;
    position: relative;
    padding: 0;
    background: none;
  }

  &__loader {
    position: absolute;
    justify-content: right;
    top: 0;
    margin: 0;
    height: 100%;
    background: none;

    .loading-spinner__spinner {
      margin-right: -2.5rem;
      width: 10rem;
    }
  }

  &__index, &__title {
    padding: 1.25rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: $dark;
  }

  &__title {
    &:first-child {
      padding-left: 1.875rem;
    }
  }

  &__index {
    border-top-left-radius: 0.625rem;
    border: 1px solid $cyan-blue;
    min-width: 4rem;
    text-align: center;
    overflow: hidden;
    color: $cyan-blue;
    background: $light-grey;
  }

  &__lock {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    margin-left: auto;
    width: 5rem;
    padding: 1.25rem;
  }

  &__body {
    padding: 1.25rem 1.875rem;
  }
}
