@import "../../../../style/theme.scss";

.notes {

  &__top {
    display: flex;
    justify-content: flex-end;
    padding: 0 1.25rem 1.25rem;
  }
}
