@import "src/@paco/styles";

.department-badge {
    @extend %department-badge;

    font-weight: var(--fw-regular);

    &__group {
        font-weight: var(--fw-medium);
    }

    &--is-grouped {
        opacity: .7;
    }

    &--is-main-department {
        background: var(--c-primary);
    }

    &--is-deleted {
        background: var(--c-disabled-grey);
    }
}
