@import "../../../style/theme.scss";

$component: radio-button;

.#{$component} {
  display: block;
  position: relative;

  &__label {
    display: flex;
    padding-left: 1.5rem;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 3px;
      left: 0;
      border: 1px solid $border-grey;
      border-radius: 50%;
      background: $white;
    }
  }

  &__text {
    line-height: 1.5;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &--checked .#{$component}__label:after, .#{$component}__label:hover:after {
    border-color: $primary;
    background: $primary;
  }
}
