@import "src/@paco/styles";

.leave-of-absence-badge {
    @extend %badge-open;
    @extend %text-ellipsis;

    display: block;
    border-color: currentColor;
    color: var(--c-dark-blue);

    &--is-request {
        color: var(--c-green);
    }
}
