@import "src/@paco/styles";

.printable-leave-of-absence-overview {
    margin-top: -999999px;
    page: printable-3d-landscape;
    --leave-of-absences-overview-table-cell-width: #{remCalc(1.625)};

    @media print {
        margin-top: 0;
        -webkit-print-color-adjust: exact;
    }

    &__header {
        position: absolute;
        padding-top: 20mm;
        padding-left: 20mm + remCalc(1);
    }

    &__table {
        display: flex;
    }

    &__title {
        margin: 0;
        line-height: 1;
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__users-amount-section,
    &__user-search-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__users-amount-section {
        height: calc(var(--leave-of-absences-overview-top-bar-height) - 2px);
    }

    &__users-amount {
        margin: remCalc(.125) 0 0;
        font-size: remCalc(.875);
        color: var(--c-grey);
    }
}
