@import "theme";

.badge {
    border-radius: 5px;
    padding: 4px 15px 4px 15px;
    font-size: 13px;
    color: $white;

    &-success {
        background-color: $success;
    }

    &-secondary {
        background-color: $secondary;
    }

    &-orange {
        background-color: $orange;
    }

    &-danger {
        background-color: $red;
    }

    &-light-blue {
        color: $white;
        background-color: $light-blue;
    }

    &-department {
        box-sizing: content-box;
        margin-right: 3px;
        margin-bottom: 5px;
        height: 13px;
        padding-left: 8px;
        padding-right: 8px;
        font-weight: 600;
        background-color: $secondary;
    }

    &-level {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1px;
        width: 20px;
        height: 20px;
        padding: 0;
        line-height: 1;
        font-size: 15px;
        font-weight: 500;
        background: $border-grey;
        color: $black;
    }
}

.badge-open {
    display: flex;
    border: 1px solid $black;
    border-radius: 13px;
    line-height: 1.28;
    padding: 4px 10px 4px 10px;
    font-size: 14px;

    &--bg-white {
        background: $white;
    }

    &-blue {
        border-color: $blue;
        color: $blue;
    }

    &-primary {
        border-color: $primary;
        color: $primary;
    }

    &-danger {
        border-color: $red;
        color: $red;
    }

    &-grey {
        border-color: $grey;
        color: $grey;
    }

    &-green {
        border-color: $success;
        color: $success;
    }

    &-orange {
        border-color: $orange;
        color: $orange;
    }

    &-orange-light {
        border-color: $orange-light;
        color: $orange-light;
    }
}
