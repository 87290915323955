@import '../../../../style/theme';

.shift-plannings-form {
  position: relative;
  padding-bottom: 2rem;
  border: 1px solid $border-grey;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__header h5 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
