@import "../../../../style/theme";

.shared-documents {
  display: flex;
  flex-direction: column;

  &-overview {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.25rem;

    @media only screen and (max-width: $break-mobile) {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 1rem;
      padding: 0 1.25rem;
    }
  }

  &__button-wrapper {
    display: flex;

    @media only screen and (max-width: $break-mobile) {
      flex-direction: column;
    }
  }

  &__button {

    & + & {
      margin-left: 1rem;

      @media only screen and (max-width: $break-mobile) {
        margin-top: 1rem;
        margin-left: 0;
      }
    }

    &--notify {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__search-bar {
    margin-left: 1rem;

    @media only screen and (max-width: $break-mobile) {
      margin-top: 1rem;
      margin-left: 0;
    }

    input {

      @media only screen and (max-width: $break-mobile) {
        width: 100%;
      }
    }
  }

  &__documents-help-tooltip {
    position: absolute;
    left: remCalc(3);
    top: remCalc(10);

    @media only screen and (max-width: $break-mobile) {
        top: remCalc(12);
    }
  }

  &__filters-help-tooltip {
    position: absolute;
    top: remCalc(3);
    left: remCalc(-8);
  }

  &__add-document-help-tooltip {
    position: absolute;
    transform: translate(remCalc(4), remCalc(2.5));

    @media only screen and (max-width: $break-mobile) {
        transform: none;
        top: remCalc(2);
        left: remCalc(7);
    }
  }

  &__notify-employees-help-tooltip {
    position: absolute;
    transform: translate(remCalc(16), remCalc(2.5));

    @media only screen and (max-width: $break-mobile) {
        transform: none;
        top: remCalc(5);
        left: unset;
        right: remCalc(5);
    }
  }
}
