@import "../../../../../../style/theme.scss";

$component: concept-shift;
$focus-scale: 1.075;
$focus-time: 4s;
$scale-time: 1s ease-out;

.app .#{$component} {
  position: relative;

  &__registered-users {
    color: $green;
  }

  &__inner {
    padding: 0.25rem;
  }

  &__edit-time-button,
  &__edit-time-form,
  &__employee-icon {
    position: relative;
    z-index: 10;
  }

  &__department {
    line-height: 1;

    &:last-child {
      margin-bottom: 0.25rem;
    }
  }

  &__department-group {
    font-weight: 600;
  }

  &__shift-planning {
    pointer-events: visible;
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &:hover .#{$component}__employment-type {
    color: $green;
  }

  &--is-full-status {
    color: $white;
    background: $green;
    border-color: transparent;

    .#{$component}__employment-type {
      color: $green;
    }

    &:enabled:hover {
      background: darken($green, $button-hover-darken);
    }
  }

  &--is-concept,
  &--is-base-schedule {
    color: $concept-blue;
    background: $concept-light-blue;
    border: 1px solid $concept-blue;

    &:not(:disabled):hover {
      color: $white;
      background: darken($concept-light-blue, $button-hover-darken);
      border-color: darken($concept-blue, $button-hover-darken);

      .#{$component}__employment-type {
        color: $concept-blue;
      }
    }
  }

  &--is-concept.#{$component}--is-full-status,
  &--is-base-schedule.#{$component}--is-full-status {
    color: $white;
    background: $concept-blue;

    .#{$component}__employment-type {
      color: $concept-blue;
    }

    &:not(:disabled):hover {
      background: darken($concept-blue, $button-hover-darken);
      border-color: darken($concept-blue, $button-hover-darken);
    }
  }

  &--is-concept:hover &__employment-type {
    color: $concept-blue;
  }

  &--is-concept .#{$component}__registered-users {
    color: $concept-blue;
    border: 1px solid $concept-blue;
  }

  &--is-selected,
  &--is-selected.#{$component}--is-full-status,
  &--is-preview,
  &--is-preview.#{$component}--is-full-status {
    color: $orange;
    background: $concept-light-orange;
    border: 1px solid $orange;

    &:not(:disabled):hover {
      color: $orange;
      background: $concept-light-orange;
      border-color: $orange;
    }

    .#{$component}__registered-users {
      border-color: $orange;
    }

    .#{$component}__employment-type,
    &:not(:disabled):hover .#{$component}__employment-type {
      color: $orange;
    }
  }

  &--is-preview {
    border-style: dashed;
    opacity: 0.85;
  }

  &--is-past-status, &--is-past-status:hover {

    .#{$component}__registered-users,
    .#{$component}__employment-type {
      color: $grey-disabled;
      border-color: transparent;
    }
  }

  &--hide-employees .#{$component}__employees-list {
    display: none;
  }

  &--is-concept.#{$component}--is-highlighted,
  &--is-base-schedule.#{$component}--is-highlighted {
    animation: focusAvailableConceptShiftColor $focus-time, focusScale $scale-time;
  }

  &--is-concept.#{$component}--is-full-status.#{$component}--is-highlighted,
  &--is-base-schedule.#{$component}--is-full-status.#{$component}--is-highlighted {
    animation: focusFullConceptShiftColor $focus-time, focusScale $scale-time;
  }
}

@keyframes focusScale {
  0% {
    transform: scale(1.0);
  }
  25% {
    transform: scale($focus-scale) translateZ(0);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes focusAvailableConceptShiftColor {
  30% {
    background-color: darken($concept-light-blue, $button-hover-darken);
  }
  100% {
    background-color: $concept-light-blue;
  }
}

@keyframes focusFullConceptShiftColor {
  30% {
    background-color: darken($concept-blue, $button-hover-darken);
  }
  100% {
    background-color: $concept-blue;
  }
}
