@import "../../../../../../style/theme.scss";

$component: 'shift-concept-buttons-list';

.#{$component} {
  display: flex;
  flex-direction: column;

  &__loading-spinner {
    top: 2rem;
  }

  .loading-spinner__background {
    background: none;
  }

  &--loading {
    min-height: 4.5rem;
  }
}
