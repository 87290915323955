@import "../../../../style/theme.scss";

.user-absences {
  display: flex;
  position: relative;
  padding-left: 1rem;

  &__title {
    margin-bottom: 1.875rem;
    line-height: 1.5;
    font-weight: 700;
  }

  &__leave-of-absence-saldi {
    margin-bottom: 2rem;
    min-width: 13rem;
  }

  &__absences-table {
    margin-left: 1rem;
    width: calc(100% - 13rem);
  }
}

.absences-table {
  border-left: 1px solid $border-grey;
  padding: 0;

  &-td-type, &-td-year {
    min-width: 180px;

    @media only screen and (max-width: 900px) {
      min-width: 110px;
    }

    @media only screen and (max-width: $break-mobile) {
      min-width: 200px;
    }
  }

  &-td-notes {
    @media only screen and (max-width: $break-mobile) {
      min-width: 250px;
    }
  }

  &-td-status {
    text-transform: capitalize;
  }

  td:first-child {
     padding-left: 25px;
     padding-right: 25px;
  }

  &-absence {
    position: relative;
    font-weight: 600;
    color: $primary;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -25px;
      top: -18px;
      width: 6px;
      height: 59px;
      background: $primary;
    }
  }
}