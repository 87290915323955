@import "../../style/theme";

$component: legend;

.#{$component} {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  padding: 4px 0 0 10px;

  &__color {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 15px;
    color: $primary;

    &-circle {
      display: block;
      margin-right: 5px;
      border-radius: 1px;
      width: 10px;
      height: 10px;
    }

    &--green-success .#{$component}__color-circle {
      background: $green-success;
    }

    &--green .#{$component}__color-circle {
      background: $green;
    }

    &--green-open .#{$component}__color-circle {
      background: $white;
      border: 1px solid $green;
    }

    &--green-light .#{$component}__color-circle {
      background: $green-light;
      border: 1px solid $green;
    }

    &--green-medium .#{$component}__color-circle {
      display: block;
      position: relative;
      border: 1px solid $green;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $green;
        opacity: .5;
      }
    }

    &--orange .#{$component}__color-circle {
      background: $orange;
    }

    &--orange-light .#{$component}__color-circle {
      background: $orange-light;
    }

    &--primary .#{$component}__color-circle {
      background: $primary;
    }

    &--secondary .#{$component}__color-circle {
      background: $secondary;
    }

    &--grey .#{$component}__color-circle {
      background: $white;
      border: 1px solid $placeholder;
    }

    &--light-grey .#{$component}__color-circle {
      background: $lighter-grey;
      border: 1px solid $primary;
    }

    &--concept-blue .#{$component}__color-circle {
      background: $concept-blue;
    }

    &--scarlet .#{$component}__color-circle {
      background: $scarlet;
    }
  }
}
