@import "theme";

.btn {
    @include button();

    &-orange {
        background-color: $orange;
        color: $white;
        box-shadow: none;
    }

    &-orange[disabled] {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    &-orange:hover:not([disabled]) {
        background-color: $orange-dark;
        animation: all 0.45s;
        color: $white;
    }

    &-red {
        background-color: $red;
        color: $white;
    }

    &-red:hover:not([disabled]) {
        background-color: $red-dark;
        animation: all 0.45s;
    }

    &-green {
        color: $white;
        background-color: $green-success;
        &:hover {
            background-color: darken($green-success, $button-hover-darken);
        }
    }

    &-block {
        font-size: 18px;
        height: 43px;
        background: $white;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    }

    &-outline-primary {
        &:hover {
            color: darken($primary, $button-hover-darken);
        }
    }

    &-link {
        box-shadow: none;
    }
}

.button-track-stop {
    @include clear-button();
    line-height: 1.2667;
    min-width: 125px;
    font-size: 15px;
    color: $red;

    > svg {
        margin-right: 5px;
    }

    .svg-button {
        fill: $red;
    }

    &--disabled {
        pointer-events: none;
        color: $grey-disabled;

        .svg-button {
            fill: $grey-disabled;
        }
    }

    &:hover {
        color: darken($red, $button-hover-darken);

        .svg-button {
            fill: darken($red, $button-hover-darken);
        }
    }
}

.button-plain {
    @include button-plain();
}

.button-open-in-calendar {
    @include clear-button();
    display: flex;
    align-items: center;
    justify-self: flex-start;
    margin-right: auto;
    height: 28px;
    color: $light-blue;

    i {
        display: inline-block;
        margin-bottom: -2px;
    }

    &:hover {
        color: darken($light-blue, $button-hover-darken);
    }
}

.button-export {
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.button-download {
    display: flex;
    align-items: center;
}

.clear-button {
    @include clear-button();
}

button.disabled {
    opacity: 0.5;
    pointer-events: none;

    i {
        color: $grey-disabled;
    }
}