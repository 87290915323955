@import "src/@paco/styles";

$component: district-division-container;

.district-division-container {
    --px-offset: 1px;
    --shift-planning-selector-top: 1px;

    &--hide-shift-plannings {
        .#{$component}__shift-planning-selector {
            display: none;
        }

        .#{$component}__planner {
            width: 100%;
        }
    }

    &--scrolled-past-element &__shift-planning-selector-wrapper {

        @include for-size(tablet-portrait-up) {
            position: sticky;
            top: var(--navbar-total-height);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: remCalc(1.5);
        width: 100%;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__shift-planning-selector-wrapper {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            height: calc(100vh - var(--px-offset) * 1px);
            width: calc(50% - #{remCalc(1)});
        }

        @include for-size(tablet-landscape-up) {
            width: calc(40% - #{remCalc(1)});
        }
    }

    &__shift-planning-selector {

        @include for-size(tablet-portrait-up) {
            width: 100%;
            max-height: calc(100vh - var(--shift-planning-selector-top) * 1px - #{remCalc(1.25)});
            overflow-y: auto;
        }
    }

    &__empty-state,
    &__planner {
        margin-top: remCalc(1);
        margin-left: auto;
        width: 100%;
        height: fit-content;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            width: calc(50% - #{remCalc(1)});
        }

        @include for-size(tablet-landscape-up) {
            width: calc(60% - #{remCalc(1)});
        }
    }

    &__empty-state {
        border-radius: var(--table-border-radius);
    }

    &__overview-help-tooltip {
        position: absolute;
        top: remCalc(4);
        right: remCalc(6);

        @include for-size(tablet-portrait-up) {
            top: remCalc(6);
            right: unset;
            left: remCalc(13);
        }

        @include for-size(tablet-landscape-up) {
            top: remCalc(4);
            left: unset;
            right: remCalc(8);
        }
    }

    &__date-help-tooltip {
        position: absolute;
        top: remCalc(6);
        left: remCalc(3);

        @include for-size(tablet-portrait-up) {
            top: remCalc(10);
            left: remCalc(6);
        }
    }

    &__groups-help-tooltip {
        position: absolute;
        top: remCalc(20);
        left: remCalc(11);

        @include for-size(tablet-portrait-up) {
            top: remCalc(16);
            left: unset;
            right: remCalc(18);
        }

        @include for-size(tablet-landscape-up) {
            top: remCalc(15);
            right: remCalc(24);
        }
    }
}
