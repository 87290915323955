@import "../../../../../../style/theme.scss";

$component: 'shift-button';

.#{$component} {
  @include clear-button();
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $border-grey;
  border-radius: 3px;
  height: 38px;
  padding: 8px;

  &:not(:last-child) {
    margin-bottom: 9px;
  }

  &:hover, &--active {
    border-color: $dark;
    background: $dark;

    .#{$component}__time, .#{$component}__planned-employees {
      color: $white;
    }
  }

  &__department {
    margin-right: 10px;
  }

  &__department-time-container {
    display: flex;
  }

  &__time, &__planned-employees {
    font-size: 14px;
    font-weight: 600;
  }
}
