@import "../../style/theme.scss";

.button-authenticate {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: auto !important;

  @media only screen and (max-width: $break-medium) {
    padding: 4px;
    font-size: 14px !important;
  }

  @media only screen and (max-width: $break-mobile) {
    display: none;
  }

  &--not-responsive {
    display: flex !important;
    font-size: 15px !important;

    .button-authenticate-plus-icon {
      margin-right: 8px !important;
    }
  }
}

.button-authenticate-plus-icon {
  margin-right: 8px;
  line-height: 0.5;
  font-weight: 300;
  font-size: 34px;

  @media only screen and (max-width: $break-medium) {
    margin-right: 5px;
  }

  @media only screen and (max-width: $break-mobile) {
    margin-right: 0;
    font-size: 40px;
  }
}

.button-authenticate-mobile {
  display: none;

  @media only screen and (max-width: $break-mobile) {
    @include clear-button();
    display: block;
    position: fixed;
    top: 1.125rem;
    right: 5.75rem;
    line-height: 0;
    z-index: 1023;
    color: $primary;
    background: none;
    overflow: hidden;
  }
}