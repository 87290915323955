@import 'src/@paco/styles';

.base-schedules-table {
    padding-top: var(--page-padding);

    @include for-size(tablet-portrait-up) {
        padding-top: 0;
    }

    &__row {

        &--detail {
            width: 0;
            min-width: 0;
            max-width: 0;
            padding: 0 !important;
        }

        &--departments {
            width: 50%;
        }

        &--buttons {
            width: 4rem;
            min-width: 4rem;
        }
    }

    &__button {
        width: calc(100% - 5.5rem);
    }
    
    &__table-row {
        
        &:hover {
            cursor: pointer;
        }
    }
}
