@import "../../../../../../style/theme.scss";

.concept-day-header {
  border-color: $concept-border-grey;
  background: $table-bg;

  &--has-EEEE-format {
    text-transform: capitalize;
  }
}
