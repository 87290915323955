@import "../../../style/theme.scss";
$component: checkboxes-with-text;

.#{$component} {
  &__labels {
    display: flex;
    margin-bottom: 10px;
    font-weight: 600;
    color: $dark;
  }

  &__label-checkbox {
    width: 12rem;
  }

  &__label-text {
    width: 8rem;
  }
}
