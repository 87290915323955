@import "../../style/theme.scss";

.track-duration-too-long-warning {
  display: flex;

  &__icon {
    margin-right: 0.75rem;
    min-width: 1rem;
    color: $cyan-blue;
  }

  &__look-out {
    display: inline;
    margin-right: 0.25rem;
    white-space: nowrap;
    font-weight: 600;
  }

  &__warning {
    display: inline;
  }
}