@import "src/@paco/styles";

.address-input {
    &__row {
        display: inline;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__col {
        flex: 2;
        padding: remCalc(.375) remCalc(1) remCalc(.375);

        &-small {
            flex: 1;
            padding: remCalc(.375) remCalc(1) remCalc(.375);
        }
    }

    &__required-label {
        color: var(--c-cyan);
    }
}
