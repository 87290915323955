@import "../../style/theme.scss";

.overlapping-shift {
  @include clear-button();
  display: inline-block;
  min-width: 158px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 3px 7px;
  color: $white;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  background: $scarlet;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: darken($scarlet, $button-hover-darken);
  }

  &-middle {
    display: flex;
    justify-content: space-between;
  }
}
