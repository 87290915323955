@import "src/@paco/styles";

.pz-log-list-item {
    @extend %reset-list-item;

    display: flex;

    & + & {
        margin-top: remCalc(1);
    }

    &__label {
        width: remCalc(10);
        font-weight: var(--fw-medium);
        color: var(--c-dark-blue);
    }
}
