@import "../../style/theme.scss";
@import "src/@paco/styles";

$break-medium: 1200px;

.absences {
    &-list {
        display: flex;
        flex-direction: column;

        .absences-search {
            align-self: flex-end;
            margin-bottom: $main-padding;

            @media only screen and (max-width: $break-mobile) {
                margin-right: $main-padding;
            }
        }
    }

    &-overlapping-shifts-col {
        display: flex;
        height: auto !important;
        min-height: 59px;
        padding-bottom: 11px !important;
        padding-right: 11px !important;
    }

    &-profile-pic-col {
        width: 68px;

        .absences-profile-pic {
            margin-right: 0;
        }
    }

    &-search {
        @media only screen and (max-width: $break-mobile) {
            margin-top: $main-padding;
        }
    }

    &__view-options-help-tooltip,
    &__edit-absence-help-tooltip,
    &__filters-help-tooltip {
        // For positioning the same as filters for mobile
        position: absolute;
        top: remCalc(8);
        left: remCalc(-8);
    }

    &__add-leave-of-absence-help-tooltip,
    &__add-absence-help-tooltip {
        position: fixed;
        top: remCalc(2.5);
        right: remCalc(4.5);
        z-index: 1100; // Navigation is 1000
    }

    &__leave-of-absence-request-card-help-tooltip {
        position: absolute;
        transform: translate(remCalc(10), remCalc(3));
    }

    &__see-leave-of-absences-in-calendar-help-tooltip {
        position: absolute;
        top: unset;
        left: unset;
        transform: translate(remCalc(9), remCalc(16));
    }

    &__leave-of-absence-item-help-tooltip {
        position: absolute;
        top: remCalc(4);
        left: remCalc(2);
    }

    &__absence-info-help-tooltip {
        position: absolute;
        top: remCalc(1);
        left: remCalc(2);
    }

    &__absences-overview-help-tooltip {
        position: absolute;
        top: remCalc(3);
        left: remCalc(2);
    }

    &__export-absences-help-tooltip {
        position: absolute;
        top: remCalc(6);
        right: remCalc(11);
    }

    @include for-size(phone-only) {
        &__see-leave-of-absences-in-calendar-help-tooltip {
            position: absolute;
            transform: none;
            top: remCalc(21);
            left: remCalc(12);
        }

        &__leave-of-absence-request-card-help-tooltip {
            position: absolute;
            transform: none;
            top: remCalc(8);
            left: remCalc(10);
        }
    }

    @include for-size(tablet-portrait-up) {
        &__add-leave-of-absence-help-tooltip,
        &__add-absence-help-tooltip {
            position: absolute;
            top: remCalc(3);
            left: remCalc(-8);
            right: unset;
            z-index: 980;
        }

        &__view-options-help-tooltip {
            top: unset;
            left: unset;
            transform: translate(remCalc(-5), remCalc(3));
        }

        &__edit-absence-help-tooltip {
            top: remCalc(13);
            right: remCalc(8);
            left: unset;
        }

        &__export-absences-help-tooltip {
            top: remCalc(3);
            right: remCalc(15);
        }

        @media only screen and (max-width: $break-medium) {
            &__view-options-help-tooltip {
                transform: translate(remCalc(-3));
            }
        }
    }
}

.exchange-requests,
.leave-of-absence-requests {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: $break-mobile) {
        padding: 0 $main-padding;
    }

    >.card {
        width: calc(50% - #{$main-padding * .5});
        margin-bottom: $main-padding;

        @media only screen and (max-width: $break-medium) {
            width: 100%;
        }

        &:nth-child(odd) {
            margin-right: $main-padding;

            @media only screen and (max-width: $break-medium) {
                margin-right: 0;
            }
        }
    }
}

.leave-of-absence-requests {
    justify-content: space-between;
}

.leave-of-absences-calendar-days-mobile {
    padding-top: 0;
}

.form-decline-leave-of-absence,
.form-approve-leave-of-absence,
.form-add-leave-of-absence,
.form-resolve-absence,
.form-edit-shift,
.form-add-absence,
.form-resolve-leave-of-absence {
    max-width: 567px;
}

.absences-table-button {
    @include clear-button();
    color: $light-blue;
    cursor: pointer;

    &:hover {
        color: darken($light-blue, $button-hover-darken);
    }
}

.absences-profile-pic {
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    min-width: 37px;
    height: 37px;
    background: url('../../images/no-profile-image.jpg');
    background-size: 100% 100%;
}

.absences-list-top {
    display: flex;
    margin-bottom: 15px;

    .calendar-toolbar {
        flex-grow: 99;
        margin-right: 20px;
    }

    .absences-button-loket {
        margin-right: 10px;
    }

    &-right {
        display: flex;

        @media only screen and (max-width: $break-mobile) {
            padding: $main-padding $main-padding 0 0;
        }

        @media only screen and (max-width: 400px) {
            flex-direction: column;
            align-items: flex-end;

            .absences-button-loket {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        margin-bottom: $main-padding;

        .calendar-toolbar {
            margin-right: 0;
        }

        .absences-list-top-right {
            align-self: flex-end;
        }
    }
}

.absences-overview-button-export {
    margin-right: 10px;

    @media only screen and (max-width: 400px) {
        margin-bottom: 10px;
        margin-right: 0;
    }
}

.form-synchronize-leave-of-absences {
    max-width: 500px;
}
