@import "src/@paco/styles";

.concept-days {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;

  &__edit-shift-help-tooltip {
    position: absolute;
    top: remCalc(5);
    left: calc(66% - #{remCalc(1)});

    @include for-size(tablet-landscape-up) {
      top: remCalc(5);
      left: calc(100% / (14 / 3) - #{remCalc(1.25)});
    }
  }
}
