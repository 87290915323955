@import 'src/@paco/styles';

$component: loading-spinner;

.#{$component} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    width: 100%;
    height: calc(100% - #{var(--navbar-height)});
    z-index: 100;

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--c-white);
        opacity: .5;
    }

    &__spinner {
        position: relative;
        width: remCalc(25);
    }

    &__spinner-rectangle1 {
        animation: spin 3.5s linear infinite;
    }

    &__spinner-clock {
        transform-origin: remCalc(-.8125) 0;
        animation: spin 1.5s linear infinite;
    }

    &--is-small {
        position: absolute;

        .#{$component}__spinner {
            width: remCalc(15.625);
        }
    }
}
