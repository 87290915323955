@import "../../../style/theme.scss";

$component: export-range-form;

.#{$component} {
  &__intro {
    display: flex;
    margin-bottom: 1rem;

    p {
      margin: 0;
    }
  }

  &__info-icon {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-right: 0.5rem;
    background-image: url('../../../images/info-light-blue.svg');
  }

  &__select-payroll-period-col {
    flex: 1;
    padding: remCalc(.375) remCalc(1);
  }

  &__select-users-row {
    margin-block-start: remCalc(.5);
  }

  &__label {
    font-weight: var(--fw-medium);
  }
}

.form-export-range {
  max-width: 550px;
}
