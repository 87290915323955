@import "../../../../style/theme.scss";
@import 'src/@paco/styles';

$component: calendar-concepts-toolbar;

.#{$component} {
  display: flex;
  justify-content: space-between;
  gap: remCalc(1);
  position: relative;
  width: 100%;
  background-color: var(--c-background);
  padding-bottom: remCalc(1);

  @include for-size(tablet-portrait-up) {
    margin-bottom: 0;
    padding-top: remCalc(1);
  }

  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 99;
    flex-wrap: wrap;

    @media only screen and (min-width: remCalc(66)) {
      justify-content: end;
      gap: remCalc(1);
    }

    @media only screen and (max-width: remCalc(48)) {
      flex-direction: column;
    }

    @include for-size(tablet-portrait-up) {
      flex-direction: row;
      gap: remCalc(1);
    }
  }

  &__calendar-toolbar {
    flex-grow: 99;
    margin-right: 0;

    @media only screen and (max-width: $break-mobile) {
      margin-left: 0;
      order: -1;
    }
  }

  &__nav-buttons-wrapper {
    display: flex;

    @media only screen and (max-width: $break-mobile) {
      margin: remCalc(1.25);
    }
  }

  &__nav-buttons {
    margin: 0;
  }

  &__mobile-today-button {
    @include button-plain();
    @include text-ellipsis();

    display: none;

    @media only screen and (max-width: $break-mobile) {
      display: block;
      margin-left: remCalc(1);
      color: var(--c-primary);
    }
  }

  &__notify-button {
    @include clear-button();

    display: flex;
    align-items: center;
    position: absolute;
    top: remCalc(-2.25);
    right: $main-padding;
    font-weight: 600;
    color: $orange;
    cursor: pointer;
    z-index: 102;

    i {
      display: none;
      width: remCalc(1.5);
    }

    &:hover {
      color: darken($orange, $button-hover-darken);
    }

    @media only screen and (max-width: 62.5rem) {
      position: relative;
      border-radius: 2px;
      top: 0;
      right: 0;
      height: remCalc(2.5);
      padding: 0 remCalc(1);
      color: $white;
      background: $orange;
      overflow: hidden;

      i {
        display: block;
      }

      span {
        display: none;
      }

      &:hover {
        color: $white;
        background: darken($orange, $button-hover-darken);
      }
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: remCalc(1);

    @media only screen and (max-width: remCalc(48)) {
      margin-left: remCalc(1.25);
    }

  }

  .#{$component}__export-button, .#{$component}__publish-button-wrapper {
    position: relative;
  }

  .#{$component}__publish-button-help-tooltip {
    position: absolute;
  }

  .#{$component}__export-button, .#{$component}__publish-button {
    display: flex;
    align-items: center;
    width: fit-content;

    i {
      width: remCalc(1.5);
    }

    @media only screen and (max-width: $break-mobile) {
      min-width: inherit;

      i {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }
  }

  .#{$component}__delete-button-tooltip-wrapper {
    position: relative;
  }

  .#{$component}__delete-button-help-tooltip,
  .#{$component}__publish-button-help-tooltip {
    position: absolute;
    top: remCalc(1.25);
    left: calc(50% - #{remCalc(1.25)});
  }

  .#{$component}__delete-button {
    @include clear-button();
    @include button();
    @include flex-align-center();

    min-width: remCalc(3);
    background: $orange;

    &:disabled {
      background: $placeholder;
    }
  }

  .#{$component}__delete-button-icon {
    pointer-events: none;
    color: var(--c-white);
  }

  &__top-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0 $main-padding;

    @include for-size(tablet-portrait-up) {
      padding: 0;
    }
  }

  @media only screen and (max-width: 87.5rem) {
    flex-direction: column;

    &__calendar-toolbar {
      margin-right: 0;
    }

    &__top-right {
      align-self: flex-end;
    }
  }
}
