@import "../../../style/theme.scss";

.form-add-document {
  &-visible-to-employee {
    display: flex;
    align-items: center;
  }

  &-text-grey {
    color: $medium-grey !important;
  }

  &-text-black {
    color: $black !important;
  }

  &-text-red {
    color: $red !important;
  }

  &-button-file {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 0.5rem;
      font-weight: 600;
    }

    label {
      display: inline-block;
      line-height: 1.75;
    }
  }

  &-input-file {
    display: none;
  }

  .form-check {
    padding-left: 0;

    > label {
      font-weight: 600;
    }
  }
}

.form-delete-document, .form-delete-change {
  width: 500px;
}

.form-edit-absence {
  max-width: 650px;

  &-button-delete {
    @include clear-button();

    position: absolute;
    left: 30px;
    line-height: 1.1875;
    font-size: 15px;
    color: $red;

    &:hover, &:focus {
      color: darken($red, $button-hover-darken);
    }
  }
}
