@import "src/@paco/styles";

.printable-district-division-district-block-item {

    &__group-title {
        margin: remCalc(1) 0 0;
        border-top: 2px solid var(--c-primary);
        padding-top: remCalc(.5);
        font-size: remCalc(1.125);
        font-weight: var(--fw-medium);
    }

    &__district-division-district {
        padding-top: remCalc(.5);
    }
}
