@import 'src/@paco/styles';

$component: calendar-controls;

.#{$component} {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    height: remCalc(2.5);

    &--is-mobile {
        border-bottom: 1px solid var(--c-border-grey);
        height: remCalc(3);
        padding: 0 remCalc(1.25);
        background: var(--c-white);

        .#{$component}__date-navigation {
            justify-content: space-between;
            width: 100%;
        }

        .#{$component}__caret-button {
            opacity: 1;
        }

        .#{$component}__date-range-input-label {
            font-size: remCalc(1.125);
            background: var(--c-white);
        }
    }

    @media (max-width: 36rem) {

        &:not(.#{$component}--is-mobile) {
            flex-direction: column;
            gap: remCalc(1);
            height: auto;

            .#{$component}__time-mode-select {
                justify-content: flex-end;
            }
        }
    }

    &__date-navigation {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__today-button {
        @extend %text-ellipsis;

        margin-right: remCalc(1);
    }

    &__caret-button {
        justify-content: center;
        width: remCalc(1.5);
        aspect-ratio: 1;
        padding: remCalc(.25);
        font-size: remCalc(.875);
        color: var(--c-primary);
        opacity: .5;

        &:hover,
        &:focus {
            border: none;
            outline: none;
            opacity: 1;
        }
    }

    &__date-range-input {
        margin: 0 remCalc(.25);
        height: 100%;
    }

    &__time-mode-select {
        margin-left: remCalc(1);
    }
}
