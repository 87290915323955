@import "src/@paco/styles";

.widgets-list {

    &__title {
        font-size: remCalc(1);
        font-weight: var(--fw-medium);
    }

    &__list {
        @extend %reset-list;
    }

    &__list-item {

        &:first-of-type {
            margin-top: remCalc(1);
        }

        & + & {
            margin-top: remCalc(.625);
        }
    }
}
