@import "../../../style/theme.scss";

$break-0: 1350px;
$break-1: 1250px;
$break-2: 1000px;

.ul-3-columns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;

  li {
    display: flex;
    position: relative;
    box-sizing: border-box;
    line-height: 2;
    width: 33.33%;
    padding-right: 10px;
    font-size: 15px;
    color: $dark;
    overflow: hidden;

    @media only screen and (max-width: $break-1) {
      width: 50%;
    }

    @media only screen and (max-width: $break-2) {
      width: 100%;
    }

    @media only screen and (max-width: $break-mobile) {
      padding-right: 0;
    }
  }

  .li-name {
    font-weight: 600;
    min-width: 170px;
    max-width: 180px;
    width: 50%;

    @media only screen and (max-width: $break-mobile) {
      padding-right: 10px;
      min-width: 50%;
      word-break: break-word;
    }
  }

  .li-name + div {
    max-height: 90px;
    overflow: hidden;
    word-break: break-word;
    width: 50%;
  }
}