@import "src/@paco/styles";

.table-cell-paco-skeleton {
    @extend %skeleton;

    &--small {
        width: remCalc(6);
    }

    &--medium {
        width: remCalc(8);
    }

    &--large {
        width: remCalc(10);
    }
}
