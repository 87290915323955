@import "../../style/theme.scss";

.week-navigator {
    position: relative;

    @media only screen and (max-width: $break-mobile) {
        display: flex;
        align-items: center;
        width: 100%;
        background: $white;
    }

    &__controls-desktop {
        @media only screen and (max-width: $break-mobile) {
            display: none !important;
        }
    }

    &__controls-mobile {
        display: none;

        @media only screen and (max-width: $break-mobile) {
            display: flex;
            width: 100%;
        }
    }

    &__daypicker-desktop {
        position: absolute;
        top: 2.5rem;
        left: -1rem;

        @media only screen and (max-width: $break-mobile) {
            display: none !important;
        }
    }

    &__daypicker-mobile {
        display: none;
        justify-content: center;
        position: fixed;
        width: 100%;
        top: $navbar-total-height;
        left: 0;
        z-index: 99;

        @media only screen and (max-width: $break-mobile) {
            display: flex;
        }
    }
}
