@import '../../../../style/theme';

$component: 'calendar-shift';
$focus-scale: 1.075;
$focus-time: 4s;
$scale-time: 1s ease-out;

.#{$component} {
    @include calendar-item();
    padding: 1px;

    &:disabled {
        cursor: default;
    }

    &:not(:disabled):hover {
        background-color: darken($placeholder, $button-hover-darken);

        .#{$component}__employment-type-past {
            color: darken($placeholder, $button-hover-darken);
        }
    }

    &--focus.calendar-shift--past, &--focus.calendar-shift--full {
        animation: focusPast $focus-time, focusScale $scale-time;
    }

    &--focus.calendar-shift--available {
        animation: focusAvailable $focus-time, focusScale $scale-time;
    }

    &--focus.calendar-shift--available {
        animation: focusAvailable $focus-time, focusScale $scale-time;
    }

    &--past {
        background-color: $grey-disabled;
    }

    &--full {
        background-color: $green;

        &:not(:disabled):hover {
            background-color: darken($green, $button-hover-darken);
        }
    }

    &--available {
        border: 1px solid $green;
        color: $green;
        background-color: $green-available;

        &:not(:disabled):hover {
            color: $white;
            background-color: darken($green-available, $button-hover-darken);

            .#{$component}__employment-type-available {
                color: darken($green-available, $button-hover-darken);
            }
        }
    }

    &--dragging {
        padding: 0 1px;
        color: $green !important;
        background: $white !important;
        border: 2px dashed $green;

        .#{$component}-registered-users {
            padding: 0 5px;
            color: $white;
            background: $green;
        }

        &:focus {
            outline: 0;
        }
    }

    &--clone {
        background: $light-blue;
    }

    &-inner {
        padding: 0.25rem;
    }

    &-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        line-height: 1.1;
        font-weight: 600;

        @media only screen and (max-width: $break-mobile) {
            margin-bottom: 4px;
        }
    }

    &-registered-users {
        border: 1px solid $green;
        margin: 0 auto 1px;
        width: calc(100% - 2px);
        background: #ffffff;
        border-radius: 0 0 2px 2px;
        font-size: 0.75rem !important;
        padding: 1px 5px;
        text-align: center;
        color: #484848;
    }

    &-department {
        line-height: 1;
    }

    &-department-group {
        margin-right: 0.25rem;
        font-weight: 600;
    }

    &-employees {
        margin: 0;
        list-style: none;
        padding: 0;
        line-height: 1.2;

        &:first-of-type {
            margin: 5px 0 0;
        }

        &--previously-planned {
            text-decoration: line-through;
        }
    }

    &-employee {
        display: flex;
        justify-content: flex-end;
        height: 17px;
        line-height: 1.3;
        font-size: 13px;
        overflow: hidden;
        pointer-events: none;
    }

    &-employee-name {
        justify-self: flex-start;
        margin-right: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        min-width: 40px;

        &--is-company-medic {
            color: var(--c-red);
        }
    }

    &-employee-time {
        margin-left: 5px;
        white-space: nowrap;
        text-align: right;
        text-overflow: ellipsis;
        overflow: hidden;

        &:not(:last-child) {
            margin-right: 2px;
        }
    }

    &-employee-loa-request, &-employee-check, &-employee-absence, &-employee-comment, &-employee-unavailable {
        margin-left: 2px;
        margin-right: -2px;
        min-width: 17px;
        width: 17px;
        height: 17px;
        pointer-events: visible;
    }

    &-employee-comment {
        margin-top: 1px;
        min-width: 17px;
        height: 16px;
    }

    &-employee-absence {
        pointer-events: visible;
    }

    &__user-color {
        margin-right: 4px !important;
    }

    &__employment-type {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        margin: 0.0625rem 0.25rem 0 0;
        padding: 0.25rem;
        font-weight: 800;
        line-height: 1;
        background: $white;
        border-radius: 0.0625rem;

        &-past {
            color: $grey-disabled;
        }

        &-full {
            color: $green;
        }

        &-available {
            color: $green;
        }
    }

    &-employee--has-time.#{$component}-employee--has-color {
        &.#{$component}-employee--has-icon {
            .#{$component}-employee-time {
                display: none;
            }
        }
    }

    @media only screen and (max-width: $break-mobile) {
        margin: 0 0 4px;
        font-size: 1.125rem;
    }
}

.shifts-list {
    position: relative;
    padding: 4px;

    @media only screen and (max-width: $break-mobile) {
        padding-left: 0;
        padding-right: 0;
    }
}

@keyframes focusScale {
    0% {
        transform: scale(1.0);
    }
    25% {
        transform: scale($focus-scale) translateZ(0);
    }
    100% {
        transform: scale(1.0);
    }
}

@keyframes focusPast {
    30% {
        background-color: $green-dark;
    }
    100% {
        background-color: $grey-disabled;
    }
}

@keyframes focusAvailable {
    30% {
        background-color: darken($green-success, $button-hover-darken);
    }
    100% {
        background-color: $green-light;
    }
}

@keyframes focusFull {
    30% {
        background-color: darken($green-success, $button-hover-darken);
    }
    100% {
        background-color: $green;
    }
}
