@import "src/@paco/styles";

.calendar-user-track {
    display: block;
    position: relative;
    border: 1px solid var(--c-green);
    border-radius: 3px;
    margin-bottom: remCalc(.25);
    height: remCalc(2.75);
    padding: remCalc(.1875) remCalc(.3125) remCalc(.25);
    font-size: remCalc(.875);
    color: var(--c-black);

    &__track-time {
        position: relative;
        z-index: 1;
    }

    &:hover {
        text-decoration: none;

        &::before {
            opacity: 1;
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--c-green);
        opacity: .5;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
