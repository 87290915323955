@import "src/@paco/styles";

.search-input {
    display: block;

    &__label {
        margin-bottom: remCalc(.625);
    }

    &__field-wrapper {
        position: relative;
        display: flex;
    }

    &__icon-wrapper {
        @extend %flex-align-center;

        position: absolute;
        height: calc(100% - #{remCalc(1)});
        width: remCalc(2);
        left: 0;
        margin: remCalc(.5) remCalc(.5);
        border-right: 1px solid var(--c-grey-light);
    }

    &__icon {
        color: var(--c-primary);
    }

    &__field {
        padding-left: remCalc(3);
        height: remCalc(2.5);
        border: 1px solid var(--c-primary);
        border-radius: var(--input-border-radius);


        &::placeholder {
            font-weight: var(--fw-medium);
            color: var(--c-primary);
        }
    }

    &__error-label {
        margin-top: remCalc(.5);
    }
}
