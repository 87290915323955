@import "../../style/theme.scss";
@import "src/@paco/styles";

.app .loa-request-card {
  height: 100%;

  &-container {
    margin-bottom: $main-padding;
    width: calc(50% - #{$main-padding / 2});

    @media only screen and (max-width: $break-medium) {
      width: 100%;
    }
  }

  &__overlapping-leave-of-absences {
    line-height: 1;
    padding: remCalc(.25);
    font-size: remCalc(.9375);
    font-weight: var(--fw-medium);
  }

  &__calendar-link-icon {
    @include icon-size(.875);

    margin-left: remCalc(.375);
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    justify-self: flex-end;
    margin-top: auto;
    width: 100%;

    @media only screen and (max-width: $break-mini) {
      flex-direction: column;
    }
  }

  &-bottom-left {
    .request-card-title {
      margin-bottom: 0;
    }
  }

  &-bottom-right {
    display: flex;

    @media only screen and (max-width: $break-mini) {
      margin-top: 10px;
    }
  }

  &-button-accept, &-button-decline {
    @include clear-button();

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    width: 39px;
    height: 39px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    background: $green-success;

    &:hover {
      background: darken($green-success, $button-hover-darken);
    }

    i {
      color: $white;
    }

    img {
      width: 15px;
      height: 15px;
    }

    &--disabled {
      background: $grey-disabled !important;
      pointer-events: none;
      opacity: 0.75;
    }
  }

  &-button-decline {
    margin-right: 10px;
    background: $red;

    &:hover {
      background: darken($red, $button-hover-darken);
    }
  }

  &-comments span {
    display: block;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__saldo {
    margin-bottom: 1.5rem;
  }
}
