@import "src/@paco/styles";

.add-shift-form {
    @extend %form;

    &__row {
        @extend %form-row;

        display: inline;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__col {
        @extend %form-col;
    }
}
