@import "src/@paco/styles";

.mobile-toggle-button {
    display: flex;
    margin-bottom: remCalc(.5);
    width: 100%;
    height: remCalc(3.5);
    line-height: 1;
    padding: remCalc(1);
    font-size: remCalc(1);
    background: var(--c-lighter-grey);

    &__text {
        @extend %text-ellipsis;
    }

    &__icon {
        transition: transform .2s ease-out;
        justify-self: flex-end;
        margin-left: auto;
        font-size: remCalc(.875);
    }

    &__counter {
        margin: 0 remCalc(.625);
    }

    &--is-user-initials &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--c-light-blue);
        border-radius: 50%;
        width: remCalc(1.75);
        height: remCalc(1.75);
        font-size: remCalc(.875);
        font-weight: var(--fw-bold);
    }

    &--is-active &__icon {
        transform: rotate(180deg);
    }
}
