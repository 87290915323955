@import "../../styles/index";

$component: navigation;

.#{$component} {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: var(--navbar-height);

    @include for-size(tablet-portrait-up) {
        padding-top: var(--navbar-height);
        height: auto;
    }

    &--show-mobile-menu &__mobile-navigation {
        transform: translateX(0);
    }

    &--show-user-menu &__toggle-user-menu-button {
        pointer-events: none;
    }

    &--hide-sub-navigation {

        .#{$component}__header {

            @include for-size(tablet-portrait-up) {
                border-bottom: 0;
                margin-bottom: 1px;
            }
        }

        .#{$component}__sub-navigation {

            @include for-size(tablet-portrait-up) {
                display: none;
            }
        }
    }

    &--is-dashboard {

        .#{$component}__header {

            @include for-size(tablet-portrait-up) {
                border-bottom: 1px solid var(--c-lighter-grey);
                margin-bottom: 0;
            }
        }

        .#{$component}__dashboard-button {
            background: var(--c-background);
        }
    }

    &__header {
        display: flex;
        align-items: flex-end;
        position: fixed;
        border-bottom: 1px solid var(--c-lighter-grey);
        width: calc(100% - #{remCalc(1)});
        height: var(--navbar-height);
        box-sizing: content-box;
        padding-left: remCalc(1);
        background: var(--c-white);
        z-index: 1000;

        @include for-size(tablet-portrait-up) {
            position: fixed;
            top: 0;
        }
    }

    &__scroll-container {
        @extend %horizontal-scrollbar;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: remCalc(.5);
        width: calc(100% - #{remCalc(4.5)});
        height: var(--navbar-height);

        @include for-size(tablet-portrait-up) {
            overflow-x: auto;
            justify-content: flex-start;
            padding-right: 0;
        }
    }

    &__main-navigation {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: flex;
            flex-grow: 1;
        }
    }

    &__sub-navigation {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: flex;
            position: relative;
            width: 100%;
            height: var(--sub-navbar-height);
        }
    }

    &__dashboard-button {
        @extend %flex-align-center;

        height: 100%;
        aspect-ratio: 1;
        color: var(--c-primary);
    }

    &__development-indicator {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
            margin-left: remCalc(1);
            font-weight: var(--fw-medium);
            color: var(--c-primary);
        }
    }

    &__toggle-user-menu-button {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
            border: 1px solid var(--c-light-blue);
            border-radius: 50%;
            margin: 0 remCalc(.5) 0 remCalc(.5);
            min-width: remCalc(1.75);
            height: remCalc(1.75);
            font-size: remCalc(.875);
            font-weight: var(--fw-bold);
            letter-spacing: -1px;
            white-space: nowrap;
            text-transform: uppercase;
            color: var(--c-primary);

            &:focus, &:hover {
                border-color: var(--c-dark-blue);
            }
        }
    }

    &__toggle-mobile-menu-button {
        display: flex;
        margin-right: remCalc(-.5);
        padding: remCalc(.5);
        font-size: remCalc(1.25);
        order: 2;

        @include for-size(tablet-portrait-up) {
            display: none;
            order: inherit;
        }
    }

    &__user-menu {
        position: absolute;
        right: remCalc(.5);
        top: remCalc(3);
        z-index: 1300;
    }

    &__help-button {
        order: 1;
        border: 1px solid var(--c-orange);
        border-radius: 50%;
        margin-left: remCalc(.5);
        width: remCalc(1.75);
        height: remCalc(1.75);
        padding: 0;
        background: var(--c-orange);

        &:focus, &:hover {
            background: var(--c-orange-dark);
        }

        @include for-size(tablet-portrait-up) {
            order: inherit;
            margin-left: 0;
        }
    }

    &__help-button-icon {
        width: remCalc(1.5);
        height: auto;
        max-width: remCalc(1.5);
        max-height: remCalc(1.5);
        color: var(--c-white);
    }

    &__stop-help-button {
        @extend %button--is-primary;

        display: flex;
        min-width: fit-content;
        height: remCalc(2.5);
        margin-left: remCalc(.5);
        margin-right: remCalc(1.75);

        @include for-size(tablet-portrait-up) {
            margin-right: 0;
        }
    }

    &__stop-help-button-icon {
        margin-right: remCalc(.5);
        max-width: remCalc(1.125);
        max-height: remCalc(1.125);
        width: remCalc(1.125);
        height: remCalc(1.125);
    }

    &__mobile-navigation {
        transform: translateX(-100%);
        transition: transform .3s ease-out;

        position: fixed;
        top: var(--navbar-height);
        height: calc(100% - var(--navbar-height));
        z-index: 1000;
        overflow-y: auto;

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__help-modal {
        top: var(--navbar-height);
        height: calc(100% - var(--navbar-height));
        opacity: 0.2;
        z-index: 950;
    }
}
