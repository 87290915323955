@import "src/@paco/styles";

$component: checkbox;

.#{$component} {
    display: flex;
    align-items: center;
    color: var(--c-grey-dark);
    cursor: pointer;

    &--is-disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus ~ .#{$component}__box {
            box-shadow: var(--focus-ring);
        }

        &:checked ~ .#{$component}__box {
            border-color: var(--primary);
            background-color: var(--primary);

            &::after {
                opacity: 1;
                transform: scale(1);
                background-color: var(--c-primary);
            }
        }
    }

    &__icon {
        position: relative;
        width: remCalc(.5);
        color: var(--c-white);
        z-index: 1;
    }

    &__box {
        @extend %flex-align-center;

        position: relative;
        width: remCalc(.9375);
        min-width: remCalc(.9375);
        height: remCalc(.9375);

        border-radius: var(--input-border-radius);
        border: 1px solid var(--c-disabled-grey);

        &--is-disabled {
            cursor: not-allowed;
            opacity: .5;
        }

        &::after {
            @extend %click-extend;

            content: "";
            border-radius: inherit;
            background-color: currentColor;
            opacity: 0;
            transform: scale(0);
            transition: opacity .1s ease, transform .1s ease;
        }
    }

    &__label {
        @extend %text-ellipsis;

        margin-left: remCalc(.5);
        user-select: none;
    }

    &__chevron-wrapper, &__chevron-wrapper:focus {
        flex-direction: row-reverse;
        margin-left: remCalc(.125);
        color: var(--c-black);
    }

    &__chevron-down {
        margin: 0 remCalc(.5);
        transform: scaleY(-1);

        &--is-opened {
            transform: scaleY(1);
        }
    }
}
