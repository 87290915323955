@import "src/@paco/styles";

.toast-paco {
    position: relative;
    display: none;
    flex-direction: row;
    align-items: stretch;
    border-radius: remCalc(.25);
    overflow: hidden;
    border: 1px solid var(--c-border-grey);
    background-color: var(--c-white);

    &--is-visible {
        display: flex;
        animation: showToast .35s;
    }

    &__icon-wrapper {
        @extend %flex-align-center;

        padding: remCalc(1) remCalc(1.5);
        background-color: var(--c-green-success);

        &--warning, &--fail {
            background-color: var(--c-warning);
        }

        &--pass {
            background-color: var(--c-success);
        }

        &--neutral, &--update-app {
            background-color: var(--c-blue);
        }
    }

    &__icon {
        width: remCalc(1.25);
        height: remCalc(1.25);
        fill: var(--c-white);
    }

    &__body {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: remCalc(17.5);
        padding: remCalc(1);
    }

    &__close-button {
        @extend %flex-align-center;

        display: block;
        padding: remCalc(1.25);

        &-icon {
            width: remCalc(.625);
            height: remCalc(.625);
            fill: var(--c-medium-grey);
        }
    }
}

@keyframes showToast {
    0% {
        transform: translateX(40%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
