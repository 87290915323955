@import "../../../../style/theme.scss";
@import "../list.scss";

$base-height: 59px;

.contract {
  border-radius: 10px;
  margin-bottom: $main-padding;
  width: 100%;
  height: 59px;
  background: $white;
  overflow: hidden;
  box-shadow: $card-box-shadow;

  &--active {
    height: auto;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    background: $table-bg;
    height: 59px;
  }

  &-name {
    line-height: 1.25;
    font-size: 20px;
    font-weight: 600;
    color: $dark;
  }

  &-button-toggle {
    @include clear-button();
    color: $cyan-blue;
  }

  &-detail {
    position: relative;
    padding: 45px;
    height: 305px;

    @media only screen and (max-width: $break-1) {
      height: 365px;
    }

    @media only screen and (max-width: $break-2) {
      height: auto;
    }
  }

  &-ul.ul-3-columns {
    .li-name {
      max-width: 210px;
      width: 60%;
    }
  }

  &-button-edit {
    @include clear-button();
    position: absolute;
    top: $main-padding;
    right: 3vw;

    @media only screen and (max-width: $break-mobile) {
      right: $main-padding;
    }
  }

  &-changes {
    border-top: 1px solid $border-grey;
    padding: 30px 26px;
  }

  &-changes-title {
    margin-bottom: 15px;
    font-weight: 600;
  }

  &-change {
    display: flex;
    align-items: center;
  }

  &-button-delete {
    @include clear-button();
    margin-left: 8px;
  }
}
