@import "src/@paco/styles";

.unavailable-to-work-time-slot-badge {
    @extend %badge-open;
    @extend %text-ellipsis;

    display: block;
    border-color: currentColor;
    color: var(--c-red);
}
