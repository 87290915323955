@import "src/@paco/styles";

.breadcrumbs {
    display: flex;
    align-items: center;
    height: remCalc(3.75);
    padding: 0 remCalc(1.5);
    background: var(--c-white);
    overflow-x: auto;

    &__house-icon {
        @include icon-size(1.125);
    }

    &__breadcrumbs {
        display: flex;
        margin-top: -1px;
        padding-left: remCalc(.375);
    }

    &__breadcrumb-button-group {
        display: flex;
        align-items: center;
    }

    &__chevron-icon,
    &__breadcrumb-button {
        margin-left: remCalc(.5);
        color: var(--c-medium-grey);

        &--is-active {
            color: var(--c-primary);
        }
    }

    &__chevron-icon {
        width: remCalc(.875);
    }

    &__breadcrumb-button {
        order: 1;
        font-weight: var(--fw-regular);
    }

    &__breadcrumb-button:hover + &__chevron-icon,
    &__breadcrumb-button--is-active,
    &__breadcrumb-button--is-active + &__chevron-icon {
        color: var(--c-primary);
    }
}
