@import '../../../../style/theme';

$component: 'concepts-calendar';

.app .#{$component} {
  padding: 0;

  @media only screen and (max-width: $break-mobile) {
    padding: 1.25rem;
  }

  &__day:nth-child(n+8) .#{$component}__day-header {
    display: none;
  }

  &--has-single-select-mode-active .#{$component}__concept-shift--is-concept {
    cursor: default;
  }

  &--has-dark-background .#{$component}__day-content {
    border-color: $concept-border-grey;
    background: $concept-dark-blue;
  }

  &--has-dark-background .#{$component}__day-content--is-today {
    background: $concept-darker-blue;
  }

  &--has-dark-background .#{$component}__day-content-children {
    margin-top: 0;
  }

  &--has-dark-background .#{$component}__day-content-header {
    background: none;
  }

  &--has-dark-background .#{$component}__day-content-header-label {
    background: $white;
    color: $primary;
  }

  &--has-dark-background .#{$component}__day-content-header-day {
    color: $white;
  }

  &--has-dark-background .#{$component}__concept-days__concept-day-content {
    border-color: $concept-border-grey;
  }

  &--is-dragging .#{$component}__day-buttons {
    pointer-events: none;
  }

  &__add-base-schedule-help-tooltip {
    position: absolute;
    top: remCalc(4.375);
    left: remCalc(-1.5);
  }

  &__add-concept-shift-help-tooltip {
    position: absolute;
    top: remCalc(-1);
    right: remCalc(5);
    z-index: 1100; // Navigation is 1000

    @media only screen and (min-width: $break-mobile) {
        top: remCalc(1.25);
        left: remCalc(-1.5);
        z-index: 980;
    }
  }

  &__filters-help-tooltip {
    position: absolute;
    top: remCalc(12.5);
    left: remCalc(-3.25);
  }
}
