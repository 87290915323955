@import "../../../../../../style/theme.scss";

$component: concept-day;

.#{$component} {

  &__buttons {
    display: flex;
    justify-content: center;
    flex-grow: 99;
    position: relative;
    margin-bottom: 1rem;
    padding-top: 1rem;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  &__button-add {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    border-radius: 50%;
    margin: 0 1rem;
    width: 1.5rem;
    height: 1.5rem;
    opacity: 1;
    color: $primary;
    overflow: hidden;

    @media only screen and (max-width: 62.5rem) {
      margin: 0 0.25rem;
    }
  }

  &--is-past .#{$component}__buttons {
    display: none;
  }

  &.#{$component}--is-out-of-range {
    opacity: .5;
  }

  &.#{$component}--is-mobile {
    width: 100%;
  }
}
