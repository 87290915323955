@import "../../../../style/theme.scss";

.user-calendar {
  padding: 0 40px 20px;

  @media only screen and (max-width: $break-mobile) {
    padding: 0 20px 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media only screen and (max-width: $break-mobile) {
      flex-direction: column;
    }
  }

  &__navigation {
    position: relative;
    z-index: 100;
  }

  .day {
    .calendar-absences-list, .shifts-list {
      padding-bottom: 4px;
    }

    .shifts-list {
      margin-bottom: 4px;
    }

    .calendar-absence-date, .calendar-shift-top {
      margin-bottom: 2px;
    }

    .calendar-absence-inner, .calendar-shift-inner {
      padding: 3px 5px 4px;
    }

    .calendar-absence-date, .calendar-shift-date {
      font-weight: 600;
    }

    .calendar-absence--denied .calendar-absence-inner {
      border-color: $placeholder;
      color: $placeholder;
      background: $white;

      &:before, &:after {
        background: $placeholder;
      }
    }

    .calendar-absence--denied.calendar-absence--active .calendar-absence-inner {
      border-color: darken($placeholder, $button-hover-darken);
      color: $white;
      background: darken($placeholder, $button-hover-darken);

      &:before, &:after {
        background: darken($placeholder, $button-hover-darken);
      }
    }

    .calendar-absence--absence .calendar-absence-inner {
      border-color: $scarlet;
      color: $white;
      background: $scarlet;

      &:before, &:after {
        background: $scarlet;
      }
    }

    .calendar-absence--absence.calendar-absence--active .calendar-absence-inner {
      border-color: darken($scarlet, $button-hover-darken);
      background: darken($scarlet, $button-hover-darken);

      &:before, &:after {
        background: darken($scarlet, $button-hover-darken);
      }
    }
  }
}
