@import '../helpers/functions';
@import './flex';

%button {
    @extend %flex-align-center;

    margin: 0;
    padding: remCalc(0.25);
    font: inherit;
    font-size: remCalc(0.9375);
    font-weight: var(--fw-medium);
    line-height: 1;
    color: var(--c-primary);
    cursor: pointer;
    border: none;
    background: none;

    &:hover,
    &:focus {
        color: var(--c-primary);
        outline: none;
    }

    &:disabled {
        color: var(--c-disabled-grey);
        cursor: not-allowed;
    }
}

%button--is-primary {
    border-radius: 2px;
    padding: remCalc(.625) remCalc(1);
    color: var(--c-white);
    background: var(--c-orange);
    overflow: hidden;

    &:hover,
    &:focus {
        color: var(--c-white);
        background: var(--c-orange-dark);
    }

    &:disabled {
        color: var(--c-white);
        background: var(--c-placeholder);
    }
}

%button--is-primary-outline {
    @extend %button--is-primary;

    border: 1px solid var(--c-orange);
    color: var(--c-orange);
    background: var(--c-white);
}

%button--is-tooltip {
    @extend %flex-align-center;

    border-radius: 50%;
    width: remCalc(.875);
    height: remCalc(.875);
    color: var(--c-white);
    font-weight: var(--fw-medium);
    font-size: remCalc(.75);
    background: var(--c-light-blue);

    &:hover,
    &:focus {
        color: var(--c-white);
        background: var(--c-blue);
    }
}

%button--reset {
    border: none;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

%widget-button {
    @extend %button--reset;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid var(--c-border-grey);
    width: 100%;
    height: remCalc(2.5);
    padding: 0 remCalc(.25) 0 remCalc(1.25);
    color: var(--c-primary);
    cursor: pointer;
    background: var(--c-white);

    &[draggable="true"] {
        cursor: move;
    }

    &:hover,
    &--is-active {
        border-color: var(--c-primary);
    }
}
