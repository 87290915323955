@import "src/@paco/styles";

.valk-logo {
    position: relative;
    box-sizing: content-box;

    &__img {
        width: 100%;
    }
}
