@import "../../style/theme";

.logs-page {
  position: relative;
  padding: 20px;
  min-height: 100px;

  &__loading-spinner {
    position: relative;
    top: 0;
    height: 100%;
  }
}


.modal .logs-page {
  padding: 20px 0 20px 0;

  .log-date-block {
    border: 0;

    &__date {
      margin: 0 0 0 20px;
    }
  }

  .log-block {
    padding-right: 35px;

    &:after {
      width: calc(100% - 110px);
    }
  }
}
