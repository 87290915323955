@import "src/@paco/styles";

$component: leave-of-absences-overview-table-body;

.#{$component} {
    position: relative;

    &__row {
        height: remCalc(1.875);

        & + & {

            .#{$component}__cell {
                border-top: 1px solid var(--c-lighter-grey);

                &--is-active {
                    border-top-color: var(--c-border-grey);
                }
            }
        }
    }
}
