@import "../../style/theme.scss";

.feedback-scores {
  display: flex;
  border: 1px solid $border-grey;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  overflow: hidden;
}

.feedback-score {
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid $border-grey;
  width: 33.33%;
  height: 142px;
  padding: 21px 26px;
  background: $white;
  color: $dark;

  @media only screen and (max-width: $break-mobile) {
    align-items: center;
    height: auto;
    padding: 10px;
  }

  &:last-child {
    border-right: 0;
  }

  &-title {
    font-size: 15px;
    font-weight: 600;

    @media only screen and (max-width: $break-mobile) {
      margin-bottom: 5px;
    }
  }

  &-emoticon {
    position: absolute;
    top: 21px;
    right: 26px;
    width: 40px;
    height: 40px;

    @media only screen and (max-width: $break-mobile) {
      position: relative;
      top: 0;
      right: 0;
      margin-bottom: 5px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-percentage {
    margin-top: auto;
    justify-self: flex-end;
    line-height: 1;
    font-size: 45px;

    span {
      margin-left: 8px;
      font-size: 20px;

      @media only screen and (max-width: $break-mobile) {
        margin-left: 3px;
      }
    }
  }


}