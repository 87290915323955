@import 'src/@paco/styles';

.deactivated-user-bar {
    display: flex;
    justify-content: space-between;
    min-height: remCalc(1.375);
    padding: remCalc(.625) remCalc(1);
    color: var(--c-white);
    background: var(--c-bordeaux);

    @include for-size(phone-only) {
        flex-direction: column;
    }

    &__buttons {
        display: flex;

        @include for-size(phone-only) {
            justify-content: flex-end;
            margin-top: remCalc(.375);
        }
    }

    &__delete-button,
    &__reactivate-button {
        @extend %button--reset;

        color: var(--c-white);

        &:hover {
            text-decoration: underline;
            color: var(--c-white);
        }
    }

    &__delete-button {
        margin-right: remCalc(1);
    }
}
