@import "../../../../style/theme.scss";

$component: 'assign-shift-concept-to-user-form';

.#{$component} {

  &__user-profile-info, &__intro {
    margin-bottom: 1rem;

    .user-profile-name-link {
      color: $white;
    }
  }
}
