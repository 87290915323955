@import "../../style/theme.scss";

.skeleton {
  display: flex;
  flex-wrap: wrap;
  margin-top: -.3125rem;

  &__item {
    border-radius: .375rem;
    margin-top: .25rem;
    margin-right: .25rem;
    height: 1.3125rem;
    background: $placeholder;

    animation: fadeInOut 3s ease-out infinite;
    animation-fill-mode: forwards;
  }
}
