@import "../../../../style/theme.scss";

.exc-shift-divider {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  height: 0.75rem;

  &__line {
    width: 100%;
    height: 1px;
    background: $border-grey;
  }

  &__arrow {
    margin-left: 1.375rem;
    min-width: 0.75rem;
    height: 1.375rem;
    fill: $light-blue;
  }
}
