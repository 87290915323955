@import "src/@paco/styles";

.groups-departments-table-skeleton {

    &__buttons-cell {
        z-index: 5;

        &:nth-of-type(3) {
            @include column-width(6rem);

            margin-left: auto;
            margin-right: remCalc(.75);
        }
    }
}
