@import "src/@paco/styles";

.number-input {
    display: block;

    &__label {
        margin-bottom: remCalc(.5);
    }

    &__error-label {
        margin-top: remCalc(.5);
    }
}
