@import "src/@paco/styles";

$component: employment-date-hours-input;

.#{$component} {

    &__label {
        margin: 0;
        width: 100%;
    }

    &__input-label-required-label {
        margin-right: .5rem;
    }

    .#{$component}__input-label-tooltip {
        margin-left: auto;
    }
}