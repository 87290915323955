@import "src/@paco/styles";

.entity-event-log-row {

    &__button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: remCalc(.125);

        &:hover {
            color: var(--c-white);
            background: var(--c-primary);
        }
    }

    &__button-icon {

        &:hover, &:focus {
            color: inherit;
        }
    }
}
