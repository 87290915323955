@import "src/@paco/styles";

.help-tooltip {
    --help-tooltip-button-width: #{remCalc(2.5)};
    --help-tooltip-content-width: #{remCalc(27.5)};
    --body-width: 0;

    width: var(--help-tooltip-button-width);
    z-index: 975; // Navigation is 1000 and HelpModal is 950

    &__wrapper {
        position: relative;
    }

    &__tooltip-content-wrapper {
        position: absolute;
        margin-top: remCalc(.5);
        left: calc(#{var(--help-tooltip-content-width)} / -2 + #{var(--help-tooltip-button-width)} / 2);
        width: var(--help-tooltip-content-width);

        @include for-size(phone-only) {
            position: fixed;
            bottom: 0;
            width: var(--body-width);
        }
    }

    &--is-active {
        z-index: 980;

        @include for-size(phone-only) {
            z-index: 1010;
        }
    }
}
