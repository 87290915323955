@import "src/@paco/styles";

$component: department-group-buttons;

.#{$component} {

    &__group-button-wrapper {
        display: inline-block;
        width: fit-content;
    }

    &__group-button {
        @extend %department-badge;

        &--has-main-department {
            background: var(--c-primary);
        }

        &:hover,
        &:active,
        &:focus {
            color: var(--c-white);
            background: var(--c-concept-blue);
        }
    }

    .#{$component}__group-button,
    .#{$component}__department-badge {
        margin-right: remCalc(.1875);
        margin-bottom: remCalc(.3125);
        padding-left: remCalc(.5);
        padding-right: remCalc(.5);
    }

    .#{$component}__group-button-icon {
        @include icon-size(.675);

        display: inline;
        float: right;
        margin-top: remCalc(.125);
        margin-left: remCalc(.25);
    }

    .#{$component}__department-badge {
        display: none;
    }

    &__group-button:focus {

        .#{$component}__group-button-icon {
            transform: scaleY(-1);
            transform-origin: 50% 45%;
        }

        & ~ .#{$component}__department-badge {
            display: inline-block;
        }
    }
}
