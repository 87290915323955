@import "../../style/theme.scss";

.legacy-user-detail-page {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: calc(100vh - #{$navbar-total-height});

    &__loading-spinner {
        position: absolute;
        top: remCalc(4.5);
        left: remCalc(1.375);
        border-radius: remCalc(1);
        width: calc(100% - #{remCalc(2.625)});
    }

    &-inactive-info {
        display: inline;
        margin-left: 10px;
        text-align: left;
        font-weight: 500;
        color: $white;
    }

    &-reactivate,
    &-delete {
        @include clear-button();
        float: right;
        margin-right: 10px;
        text-align: right;
        font-weight: 500;
        text-decoration: underline;
        color: $white;

        &:hover {
            text-decoration: none;
        }

        &-block {
            background-color: $bordeaux;
            padding: 10px;
            margin: -20px -20px 10px -20px;
        }
    }

    &-link {
        @include clear-button();
        display: block;
        position: relative;
        margin-bottom: 8px;
        line-height: 2;
        color: $medium-grey;

        @media only screen and (max-width: $break-mobile) {
            margin-right: 10px;
        }

        &--active {
            font-weight: 600;
            color: $dark;
            z-index: 3;
        }

        &:hover {
            color: $dark;
        }
    }

    &-bottom {
        display: flex;
        flex-direction: column;
    }

    &-navigation {
        min-width: 200px;
        padding-right: $main-padding;

        @media only screen and (max-width: $break-mobile) {
            display: flex;
            width: max-content;
        }

        &-link-absence-title {
            margin-left: 25px;
            margin-bottom: 20px;
            font-weight: 600;
            font-size: $h5-font-size;
            color: $primary;
        }
    }

    &-navigation-container {
        margin-left: -$main-padding;
        width: calc(100% + #{$main-padding * 2});
        padding-top: $main-padding;
        padding-left: $main-padding;
        overflow-x: scroll;
    }

    &-absence {
        position: relative;
        font-weight: 600;
        color: $primary;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -25px;
            top: -18px;
            width: 6px;
            height: 59px;
            background: $primary;
        }
    }

    &-content {
        position: relative;
        border: 1px solid $border-grey;
        border-radius: 5px;
        padding-top: 25px;
        margin-top: -3px;
        flex-grow: 99;
        z-index: 1;
        background: $white;
        overflow: hidden;
        box-shadow: $card-box-shadow;

        @media only screen and (max-width: $break-mobile) {
            margin-top: 0;
        }

        .items-list-empty {
            position: relative;
        }

        .table-container {
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
            border-radius: 0;
            margin-bottom: 0;
        }
    }

    &__user-extra-info-help-tooltip {
        position: absolute;
        top: remCalc(25);
        left: remCalc(7);
    }

    &__user-additional-permissions-help-tooltip {
        position: absolute;
        transform: translate(remCalc(40), remCalc(0));
    }

    @media only screen and (max-width: $break-mobile) {
        &__user-extra-info-help-tooltip {
            top: remCalc(20);
        }

        &__user-additional-permissions-help-tooltip {
            transform: none;
            top: 52%;
            left: remCalc(5);
        }
    }
}
