@import 'src/@paco/styles';

.shifts-calendar {
    position: relative;

    &__loader {
        pointer-events: none;
    }

    &__action-bar-wrapper--is-sticky {

        @include for-size(tablet-landscape-up) {
            position: sticky;
            top: var(--navbar-height);
            z-index: 20;
        }
    }

    &__calendar-header {
        display: none;

        @include for-size(tablet-landscape-up) {
            display: flex;
        }
    }

    &__calendar-days {
        display: none;

        @include for-size(tablet-landscape-up) {
            display: grid;
        }
    }

    &__calendar-days-mobile {
        display: flex;

        @include for-size(tablet-landscape-up) {
            display: none;
        }
    }

    &__add-shift-help-tooltip {
        position: absolute;
        top: remCalc(-2);
        right: remCalc(4);
        z-index: 1100; // Navigation is 1000

        @include for-size(tablet-portrait-up) {
            top: remCalc(0);
            left: remCalc(-9);
            z-index: 980;
        }
    }

    &__filters-help-tooltip {
        position: absolute;
        top: remCalc(8);
        left: remCalc(-4.25);
    }
}
