@import "src/@paco/styles";

:root {
    --leave-of-absences-overview-top-bar-height: #{remCalc(4.5)};
}

.leave-of-absences-overview-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: var(--leave-of-absences-overview-top-bar-height);
    padding: remCalc(1);

    &__export-button {
        @extend %button--is-primary;

        margin-left: remCalc(1);
        min-width: fit-content;
    }

    &__export-button-icon {
        @include icon-size(1.125);
    }
}
