@import "../../styles/index";

.district-division-district-template {
    display: flex;
    width: 100%;

    &__col {
        @extend %form-col;

        flex: inherit;

        &:nth-child(1) {
            width: calc(60% - #{remCalc(2)});
        }

        &:nth-child(2) {
            width: calc(40% - #{remCalc(2)});
        }

        &:nth-child(3) {
            width: remCalc(3);
        }
    }

    &__text-input, &__number-input, &__delete-button {
        margin-bottom: 0;
        height: remCalc(2);
    }

    &__delete-button {
        width: remCalc(2);
        border: var(--c-border-grey) solid 1px;
        border-radius: var(--input-border-radius);
        color: var(--c-light-blue);
    }
}
