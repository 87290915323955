@import "../../style/theme.scss";

.special-days {
  &-navigator {
    margin-bottom: 20px;
  }

  &-table {
    min-width: 475px;
  }

  &-td-category {
    width: 150px;

    @media only screen and (max-width: $break-mobile) {
      width: 80px;
    }
  }

  &-td-date {
    @media only screen and (max-width: $break-mobile) {
      width: 120px;
    }
  }

  &-td-holiday-allowance {
    width: 100px;
  }

  &-td-is-visible-in-employee-app {
    width: 150px;
  }

  &-td-edit-delete {
    width: auto !important;
  }

  &-icon-tooltip {
    display: block;
    position: relative;
    width: 20px;
  }

  &__add-special-day-help-tooltip {
    position: absolute;
    top: remCalc(3);
    left: remCalc(-8);
  }

  &__overview-help-tooltip {
    position: absolute;
    right: remCalc(15);

    @media only screen and (max-width: $break-mobile) {
        top: remCalc(3);
        right: remCalc(8);
    }
  }

  &__year-navigation-help-tooltip {
    position: absolute;
    left: remCalc(5);
  }
}

.form-add-special-day, .form-delete-special-day {
  max-width: 500px;

  .react-datepicker__input-container .form-control {
    height: 38px;
  }
}
