:root {
    interpolate-size: allow-keywords;
}

@keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(1turn) }
}

@keyframes quarterSpin {
    from { transform: rotate(0deg) }
    to { transform: rotate(.25turn) }
}

@keyframes scaleIn {
    from { transform: scale(0) }
    to { transform: scale(1) }
}
