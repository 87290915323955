@import "src/@paco/styles";

.dashboard-small-widgets {
    display: grid;
    grid-template-columns: 1fr;
    gap: remCalc(1);
    position: relative;
    width: 100%;

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(2, 1fr);
        gap: remCalc(1.875);
    }

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(3, 1fr);
        gap: remCalc(1.875);
    }

    @include for-size(laptop-up) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include for-size(big-desktop-up) {
        grid-template-columns: repeat(5, 1fr);
    }

    &__help-tooltip {
        position: absolute;
        top: remCalc(13);
        left: calc(50% - #{remCalc(1.25)});

        @include for-size(tablet-portrait-up) {
            top: remCalc(6);
            left: calc(75% - #{remCalc(1.25)});
        }

        @include for-size(tablet-landscape-up) {
            left: calc(50% - #{remCalc(1.25)});
        }

        @include for-size(laptop-up) {
            left: calc(37.5% - #{remCalc(1.25)});
        }

        @include for-size(big-desktop-up) {
            left: calc(30% - #{remCalc(1.25)});
        }
    }
}
