@import "src/@paco/styles";

.shifts-widget-empty-message {
    display: flex;
    flex-direction: column;

    @include for-size(tablet-portrait-up) {
        display: block;
    }

    &__title {
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__text {
        margin-top: remCalc(.5);
        color: var(--c-medium-grey);

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: remCalc(.5);
        }
    }
}
