@import '../helpers/functions';

%badge {
    @extend %flex-align-center;

    border-radius: 1px;
    min-width: remCalc(1.25);
    height: remCalc(1.25);
    padding: 0 remCalc(.25);
    line-height: 1;
    font-size: remCalc(.875);
    font-weight: var(--fw-medium);
    background-color: var(--c-border-grey);
    color: var(--c-black);
}

%badge-open {
    @extend %flex-align-center;

    border: 1px solid var(--c-black);
    border-radius: remCalc(.75);
    line-height: 1.25;
    padding: remCalc(.25) remCalc(.625);
    font-size: remCalc(.875);
}

%department-badge {
    display: inline-block;
    border-radius: remCalc(.3125);
    box-sizing: content-box;
    line-height: 1;
    white-space: nowrap;
    vertical-align: baseline;
    height: remCalc(.8125);
    padding: remCalc(.25) remCalc(.75);
    font-size: remCalc(.8125);
    font-weight: var(--fw-medium);
    color: var(--c-white);
    background-color: var(--c-secondary);
}

