@import "../../../../../style/theme.scss";

$component: export-range-form;

.add-department-group-button-legacy {
  @include clear-button();

  display: flex;
  align-items: center;
  font-size: 0.925rem;
  font-weight: 600;
  color: $cyan-blue;

  &:hover {
    color: $dark;
  }

  &__plus {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
}
