@import "src/@paco/styles";

.user-detail-cards {
    display: flex;
    flex-direction: column;
    position: relative;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__basic-info-card {
        position: relative;

        @include for-size(tablet-portrait-up) {
            width: 40%;
            z-index: 1;
        }
    }

    &__basic-info-card__inner-wrapper,
    &__basic-info-card__loket-wrapper {

        @include for-size(tablet-portrait-up) {
            width: 110%;
        }
    }

    &__detail-info-card {
        margin-top: remCalc(1);

        @include for-size(tablet-portrait-up) {
            width: 60%;
            padding-left: 7.5%;
        }
    }
}
