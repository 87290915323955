@import "../../../../../../style/theme.scss";

.user-calendar-day {
  .day-content {
    .calendar-absences-list, .shifts-list {
      padding-top: 0;

      &:not(:last-child) {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.user-calendar-days {

  &__loading-spinner {
    position: absolute;
    top: 5rem;
  }
}