@import "src/@paco/styles";

.user-detail {

    &__main {
        padding: remCalc(1) var(--page-padding) var(--page-padding);
    }

    &__main-top-bar {
        display: flex;
        justify-content: flex-end;
    }

    &__back-button {
        @extend %button--is-primary-outline;
    }

    &__back-button-icon {
        width: remCalc(1);
        height: remCalc(1);
        min-width: remCalc(1);
    }

    &__edit-button {
        @extend %button--is-primary;

        margin-left: remCalc(1);
    }

    &__cards {
        margin-top: remCalc(1);

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
        }
    }

    &__legacy-user-detail {
        margin-top: remCalc(1);
    }
}
