@import "src/@paco/styles";

$component: leave-of-absences-overview-users-list;

.#{$component} {
    @extend %reset-list;

    width: remCalc(13.75);

    &__item {
        @extend %reset-list-item;

        display: flex;
        align-items: center;
        height: remCalc(2.125);
        padding: 0 remCalc(1);

        & + & {
            border-top: 1px solid var(--c-lighter-grey);
        }
    }

    .#{$component}__user-link {
        @extend %text-ellipsis;

        margin-right: 0;
        width: 100%;
        font-size: remCalc(.9375);
        font-weight: var(--fw-regular);
    }
}
