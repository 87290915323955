@import "src/@paco/styles";

.sub-navigation {
    @extend %horizontal-scrollbar;

    display: flex;
    width: 100%;
    height: var(--sub-navbar-height);
    padding-left: remCalc(.75);
    background: var(--c-white);
    overflow-x: auto;

    &__nav-link {
        color: var(--c-medium-grey);

        &:focus, &:hover {
            color: var(--c-primary);
        }

        &--is-active {
            color: var(--c-primary);
            background: none;
        }
    }
}
