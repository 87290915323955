@import "src/@paco/styles";

.shift-detail-employees-form {

    &__body {
        @extend %modal-body;
    }

    &__workweek-users-select {
        position: relative;
        z-index: 20;
    }

    &__planned-shift-plannings,
    &__unplanned-shift-plannings {
        position: relative;
        margin-block-start: remCalc(1.5);
    }

    &__add-temporary-worker-button {
        @extend %button--is-primary-outline;

        justify-content: flex-start;
        width: 100%;
        height: remCalc(3.75);
        border: 1px solid var(--c-border-grey);
        margin-top: -1px;

        &:hover,
        &:focus {
            position: relative;
            z-index: 10;
            border-color: var(--c-orange);
            color: var(--c-orange);
            background: var(--c-white);
        }
    }

    &__unplanned-shift-plannings-title {
        margin-bottom: remCalc(1.25);
        font-size: remCalc(.9375);
        font-weight: var(--fw-medium);
    }

    &__previously-planned-user-card {
        opacity: .5;
    }

    &__colors-tooltip {
        position: absolute;
        inset-block-start: remCalc(-1);
        inset-inline-start: remCalc(-1);
    }
}
