.special-days-list {
    margin-block-end: 1rem;
    padding: 1rem;
    background: var(--c-lighter-blue);
    border: 1px solid var(--c-light-blue);

    &__list {
        padding-inline-start: 0;
        list-style: none;
    }

    &__content {
        display: flex;
        align-items: center;
    }

    > :first-child {
        margin-block-start: 0;
    }

    > :last-child {
        margin-block-end: 0;
    }
}
