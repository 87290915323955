@import "src/@paco/styles";

$component: track-card;

.#{$component} {
  display: flex;
  margin-bottom: var(--page-padding);
  width: 100%;

  &__name {
    margin: 0;
    font-size: remCalc(.9375);
    font-weight: var(--fw-bold);
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: remCalc(.625);
    margin-top: auto;
  }
}
