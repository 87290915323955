@import "../../../../../style/theme.scss";

$component: checkbox-with-text;

.#{$component} {
  display: flex;
  align-items: center;
  height: 39px;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__input-checkbox {
    width: 12rem;
  }

  &__input-number {
    border: 1px solid $border-grey;
    border-radius: 2px;
    padding-left: 1rem;
    width: 6rem;
    height: 100%;
  }

  &--unchecked .#{$component}__input-number {
    background: #f3f3f3;
    color: rgba(0, 0, 0, 0.35);
  }
}
