@import "../../../../../../style/theme";

.calendar-unavailable {
  @include calendar-item();
  background: $orange;

  &:hover, &:focus {
    background: darken($orange, $button-hover-darken);
    outline: 0;
  }

  &-time, &-comment {
    line-height: 1;
  }

  &-time {
    margin-bottom: 4px;
    font-weight: 600;
  }
}
