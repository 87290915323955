@import "src/@paco/styles";

.user-profile-card-list-item {
    @extend %reset-list-item;

    display: flex;
    position: relative;
    padding: remCalc(.25);
    padding-right: remCalc(.625);
    break-inside: avoid-column;
    font-size: remCalc(.9375);

    &__title,
    &__value {
        word-break: break-word;
    }

    &__title {
        margin-right: remCalc(.5);
        width: 37%;
        font-weight: var(--fw-medium);
    }

    &__value {
        width: 63%;
    }
}
