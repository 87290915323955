@import "../../../../../../style/theme.scss";

$component: 'shift-concept-button';

.#{$component} {
  @include clear-button();
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.375rem;
  padding: 0.5rem;
  color: $white;
  border: 1px solid $concept-border-grey;
  border-radius: 0.25rem;

  &:not(:last-child) {
    margin-bottom: 0.5625rem;
  }

  &:hover, &--active {
    background: $concept-border-grey;
    border-color: $concept-border-grey;

    .#{$component}__time, .#{$component}__planned-employees {
      color: $white;
    }
  }

  &__department {
    margin-right: 1.25rem;
  }

  &__department-time-container {
    display: flex;
  }

  &__time, &__planned-employees {
    font-weight: 600;
    font-size: 0.875rem;
  }
}
