@import 'src/@paco/styles';

.calendar-days {
    position: relative;
    width: 100%;

    &__fixed-calendar-header-day {
        // FixedCalenderHeader has legacy scss so important is needed.
        background: var(--c-table-bg) !important;
    }

    @include for-size(tablet-portrait-up) {
        display: grid;
        grid-template-columns: repeat(7, calc(100% / 7));
    }

    &__icons-help-tooltip {
        position: absolute;
        top: remCalc(5);
        left: calc(33% - #{remCalc(1)});

        @include for-size(tablet-landscape-up) {
            top: remCalc(5);
            left: calc(100% / 14 - #{remCalc(1.25)});
        }
    }

    &__edit-shift-help-tooltip {
        position: absolute;
        top: remCalc(5);
        left: calc(66% - #{remCalc(1)});

        @include for-size(tablet-landscape-up) {
            top: remCalc(5);
            left: calc(100% / (14 / 3) - #{remCalc(1.25)});
        }
    }

    &__drag-shift-help-tooltip {
        position: absolute;
        top: remCalc(-6);
        left: remCalc(-8);

        @include for-size(tablet-portrait-up) {
            top: remCalc(-6);
            left: calc(66% + #{remCalc(1)});
        }

        @include for-size(tablet-landscape-up) {
            top: remCalc(5);
            left: calc(100% / (14 / 3) + #{remCalc(8)});
        }
    }
}
