@import "src/@paco/styles";

.legend-color {
    display: flex;
    align-items: center;
    line-height: 1.25;
    font-weight: var(--fw-medium);
    font-size: remCalc(1);
    color: var(--c-primary);

    &__color-block {
        border: 1px solid transparent;
        border-radius: 1px;
        margin-right: remCalc(.625);
        width: remCalc(.5);
        height: remCalc(.5);
    }

    &--is-approved &__color-block {
        border-color: var(--c-orange);
        background: var(--c-orange);
    }

    &--is-approved-calender &__color-block {
        border-color: var(--c-blue);
        background: var(--c-dark-blue);
    }

    &--is-open &__color-block {
        border-color: var(--c-orange);
        background: var(--c-white);
    }

    &--is-open-calender &__color-block {
        background: var(--blue);
    }

    &--is-rejected-calender &__color-block {
        border-color: var(--c-medium-grey);
    }

    &--is-planned-full &__color-block {
        border-color: var(--c-green);
        background: var(--c-green);
    }

    &--is-planned-open &__color-block {
        border-color: var(--c-green);
        background: var(--c-green-light);
    }

    &--is-concept-full &__color-block {
        border-color: var(--c-concept-blue);
        background: var(--c-concept-blue);
    }

    &--is-concept-open &__color-block {
        border-color: var(--c-concept-blue);
        background: var(--c-concept-light-blue);
    }

    &--is-selected &__color-block {
        border-color: var(--c-orange);
        background: var(--c-concept-light-orange);
    }

    &--is-company-medic &__color-block {
        border-color: var(--c-red);
        background: var(--c-red);
    }

    &--is-past-shift &__color-block {
        border-color: var(--c-medium-grey);
        background: var(--c-medium-grey);
    }
}
