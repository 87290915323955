@import "src/@paco/styles";

.toggle {
    @extend %button--reset;

    display: flex;
    align-items: center;

    &__switch {
        margin-right: remCalc(.5);
        width: remCalc(2);
        height: remCalc(1);
        border-radius: remCalc(.5);
        background-color: var(--c-disabled-grey);
        transition: background-color .3s;

        &--is-active {
            background-color: var(--c-primary);
        }
    }

    &__handle {
        position: relative;
        top: 2px;
        left: 4px;
        width: remCalc(.75);
        height: remCalc(.75);
        background-color: var(--c-white);
        border-radius: remCalc(.375);
        transition: transform .3s, background-color .3s;
    }

    &__switch--is-active &__handle {
        transform: translateX(remCalc(.75));
    }
}
