@import "src/@paco/styles";

.lock-button {
    @extend %button--is-primary;

    &--is-locked {
        background: var(--c-primary);

        &:hover,
        &:focus {
            background: var(--c-dark-blue);
        }
    }

    &__icon {
        @include icon-size(1.325);
    }
}
