@import "flex";

%table {
    border: 1px solid var(--c-border-grey);
    border-radius: var(--table-border-radius);
    background-color: var(--c-white);
    overflow: hidden;

    td:first-child {
        border-left: 0;
    }
}

%cell-content-button {
    @extend %flex-align-center;

    width: 100%;
    height: 100%;
    padding: 0;
}

%cell-button {
    position: relative;
    width: 100%;
    height: 100%;
    color: var(--c-light-blue);

    &:hover {
        color: var(--c-light-blue);
    }

    &:focus {
        outline: 1px auto -webkit-focus-ring-color;
        z-index: 1;
    }
}
