@import "../../../../style/theme.scss";

.settings-card {
  margin-bottom: 20px;
  border-radius: 15px;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-section {
    border-top: 1px solid $border-grey;

    &:first-of-type {
      border-top: 0;
    }
  }
}
