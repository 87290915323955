@import "../../style/theme.scss";

$custom-break-mobile: 500px;

.add-resource-button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 59px;
  padding: 15px;
  border: 1px solid $border-grey;
  border-top: 0;
  border-radius: 2px;
  overflow: hidden;

  background: $white;

  @media only screen and (max-width: $custom-break-mobile) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  &-text {
    font-size: 14px;
    font-weight: 600;
    color: $orange;
  }

  &:hover {
    border-color: $orange;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: $orange;
    }
  }

  &:first-child {
    border-top: 1px solid $border-grey;

    &:hover {
      border-top-color: $orange;
    }

    &:after {
      display: none;
    }
  }
}
