@import "../../../../style/theme.scss";

$component: show-comments-button;

.#{$component} {
  @include clear-button();

  display: flex !important;
  align-items: center;
  color: $primary;
  background: none;
  opacity: 1;

  &__text {
    margin-left: 0.5rem;
  }

  &__icon {
    transform: scaleX(-1);
    transform-origin: 50% 50%;
  }

  &:hover {
    .#{$component}__text {
      text-decoration: underline;
    }
  }
}
